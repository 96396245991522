import {address_server} from "../../config";
import {error} from "../api";

export async function getListRules(token){
    const res = await (await fetch(address_server+'/list/rules?token='+token)).json()
    if(res.success){
        return res.result.groups;
    }
    error('getListRules',res);
    return []
}

export async function getListReports(token){
    const res = await (await fetch(address_server+'/report/reports?mode=list&limit=1000&token='+token)).json()
    if(res.success){
        return res.result;
    }
    error('getListReports',res);
    return []
}

export async function getSections(token){
    let res = await (await fetch(address_server+'/list/sections?token='+token)).json()
    if(res.success){
        return res.result;
    }
    error('getSections',res);
    return []
}

export async function getListTypeStorages(token){
    let res = await (await fetch(address_server+'/list/type/storage?token='+token)).json()
    if(res.success){
        return res.result;
    }
    error('getListTypeStorages',res);
    return []
}

export async function getListTypeFields(token) {
    const res = await fetch(address_server + '/list/type/fields?token=' + token)
    const data = await res.json()
    if(data.success){
        return data.result;
    }
    error('getListTypeFields',data);
    return []
}

export async function getListUnits() {
    const res = await fetch(address_server + '/list/units')
    const data = await res.json()
    if(data.success){
        return data.result;
    }
    error('getListUnits',data);
    return []
}

export async function getTypeSamples(token){
    let res = await (await fetch(address_server+'/list/type/samples?token='+token)).json()
    if(res.success){
        return res.result;
    }
    error('getTypeSamples',res);
    return []
}

export async function getListIcon(token){
    let res = await (await fetch(address_server+'/list/icons?token='+token)).json()
    if(res.success){
        return res.result;
    }
    error('getListIcon',res);
    return []
}

export async function getListPacks(token, id){
    if (!id) return [];
    let res = await (await fetch(address_server+'/storage/packs?token='+token+"&storage="+id)).json()
    if(res.success){
        return res.result;
    }
    error('getListPacks',res);
    return []
}

export async function getPack(token, id){
    if (!id) return [];
    let res = await (await fetch(address_server+'/storage/pack?token='+token+"&id="+id)).json()
    if(res.success){
        return res.result;
    }
    error('getListPacks',res);
    return []
}


export async function getListBarcodes(token){
    let res = await (await fetch(address_server+'/list/type/barcodes?token='+token)).json()
    if(res.success){
        return res.result;
    }
    error('getListPacks',res);
    return []
}
