import React, {useRef, useState, useEffect} from "react";
import close from "../../../svg/close-01.svg";

import Icon from "../../../helpers/components/Icon";
import styles from "./modal.module.css";
import dropStyles
    from "../../WorkRight/WorkRightStorageDir/WorkRightStorageAddMenu/workRightStorageAddMenuDropdownFirst.module.css";
import arrowSVG from "../../../svg/arrow-01.svg";
import Tree from "../../WorkRight/WorkRightStorageDir/WorkRightStorageAddMenu/Tree/tree";
import {getStoragePacks} from "../../../utils/API/api_storage";
import DropdownComponent from "../Dropdown/DropdownComponent";
import {toJS} from "mobx";
import {getListPacks} from "../../../utils/API/api_list";
import {getFreePacks} from "../../../utils/API/api_pucks";


const ModeMove = 0;
const ModeCopy = 1;

const ModalMoveComponent = ({
    openModal,
    mode,
    sampleData,
    treeLocation,
    action,
    onClose,
    token,
}) => {

    const selfRef = useRef(null);
    const modalBGRef = useRef(null);
    const [data, setData] = useState(JSON.parse(JSON.stringify(sampleData)));
    const [selectedPack, setSelectedPack] = useState({});
    const [availablePacks, setAvailablePacks] = useState([]);

    const [isVisiblePositionDropdown, setIsVisiblePositionDropdown] = useState(false)

    const [packList, setPackList]= useState([]);

    const [lineSource, setLineSource] = useState([]);
    const [columnSource, setColumnSource] = useState([]);

    const [value, setValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        setData(JSON.parse(JSON.stringify(sampleData)));
        asyncInitialData();

        if (mode === ModeCopy)  {
            setValue(sampleData['amount']);
            setMaxValue(sampleData['amount']);
        }
    }, [openModal, sampleData]);

    const asyncInitialData = async () => {
        const res = await getListPacks(token);
        setPackList(res);

        if (sampleData?.locations?.pack?.id) {

            let tmpData = data;
            tmpData['pack'] = sampleData.locations.pack.id;
            setData(tmpData);
            setSelectedPack(sampleData.locations.pack.id);
            changesPack(sampleData.locations.pack.id);
            await getAvailablePacks(sampleData.locations.pack.id);
        }

        if (sampleData?.locations)
        {
           await getAvailablePacks(sampleData.locations.id);
        }
    }

    const changeSampleField = async (name, value) => {
        let tmpData = data;
        if (name === 'location') {
            tmpData['path'] = value.path;
            tmpData['storage'] = value.id ?? 0;
            tmpData['column'] = '';
            tmpData['line'] = '';

            await getAvailablePacks(value.id);
            setSelectedPack({})
        }
        tmpData[name] = value
        setData(tmpData);
    }

    const getAvailablePacks = async (storageId) => {

        if (storageId === undefined || storageId === null) {
            const array = await getFreePacks(token);
            setAvailablePacks(array);
        } else {

            const array = await getStoragePacks(token, storageId);
            setAvailablePacks(array);
        }


    }

    const closeModalTappedOnBg = (ev) => {
        if (!selfRef.current.contains(ev.target) && selfRef.current !== ev.target) {
            onClose();
        }
    }

    const changesPack = (id) => {
        let tmpData = data;
        tmpData['pack'] = id;

        const pack = availablePacks.find(item => item.id === id);

        if (pack) {
            setLineSource(pack.structura.lines.reduce((acc, item) => {
                acc.push({
                    id: item,
                    name: item,
                });
                return acc;
            }, []));
            setColumnSource(pack.structura.columns.reduce((acc, item) => {
                acc.push({
                    id: item,
                    name: item,
                });
                return acc;
            }, []));
        }

        setData(tmpData);
    }

    const changeDataField = (field, value) => {
        let tmpData = data;
        tmpData[field] = value;
        setData(tmpData);
    }


    const modal = <>
        <div ref={modalBGRef}
             onClick={(ev) => closeModalTappedOnBg(ev)}
             className={styles.modalBG}>
            <div className={styles.model_copy}
                 ref={selfRef} >
                <div className={styles.header}>

                    <div className={styles.closeButton}
                         onClick={() => onClose()}>
                        <Icon color={'#1d6030'}
                              width={'15px'}
                              height={'15px'}
                              src={close}/>
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.title}>
                        { mode === ModeCopy ? 'Копирование образца' : 'Перемещение образца'}
                    </div>
                    <div className={dropStyles.infoItem} style={{marginBottom: "0px"}}>
                        <p className={dropStyles.infoItemName} style={{width: '160px'}}>Расположение</p>
                        <div className={dropStyles.infoItemInput}>
                            <input className={dropStyles.infoItemValue}
                                   value={ data?.path ?? data?.locations?.path  ?? 'Глобальное хранилище'}
                                   readOnly={true}
                                   onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)} />
                            <button className={dropStyles.infoItemArrow} onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}><img src={arrowSVG} alt="" /></button>
                            {isVisiblePositionDropdown &&
                                <>
                                    <div className={dropStyles.DropdownBlockAll} onClick={() => setIsVisiblePositionDropdown(false)}></div>
                                    <div className={dropStyles.TreeBlock}>
                                        <Tree storages={treeLocation}
                                              setVisibleTreeLocation={setIsVisiblePositionDropdown}
                                              setLocation={(val) => {changeSampleField('location', val, mode.token)}} />
                                    </div>
                                </>}
                        </div>
                    </div>

                    <div className={dropStyles.infoItem} style={{marginBottom: "0px"}}>
                        <p className={dropStyles.infoItemName} style={{width: '150px'}}>Штатив</p>
                        <div style={{width: '68%'}}>
                            <DropdownComponent
                              key={'packs_1'}
                              items={availablePacks}
                              selectedKey={selectedPack}
                              onChange={(val) => changesPack(val)}
                            />
                        </div>

                    </div>

                    <div className={dropStyles.infoItem} style={{marginBottom: "0px"}}>
                        <p className={dropStyles.infoItemName} style={{width: '150px'}}>Место хранения</p>
                        <div style={{width: '200px', display: 'flex', alignItems: 'center', gap: '10px'}}>
                            <DropdownComponent
                              key={'line_1'}
                              items={lineSource}
                              selectedKey={data['line'] ?? null}
                              disabled={!data['pack']}
                              onChange={(val) => changeDataField('line', val)}
                            />
                            <DropdownComponent
                              key={'column_1'}
                              items={columnSource}
                              selectedKey={data['column'] ?? null}
                              disabled={!data['pack']}
                              onChange={(val) => changeDataField('column', val)}
                            />
                        </div>
                    </div>

                    { mode === ModeCopy && <div className={dropStyles.infoItem}  style={{marginBottom: "0px"}}>
                        <p className={dropStyles.infoItemName} style={{width: '150px'}}>Объем</p>
                        <div>
                            <input className={dropStyles.infoItemValue}
                                   value={value}
                                   type={'number'}
                                   style={{width: '100px'}}
                                   onChange={(e) =>
                                   {
                                       const newValue = e.target.value;

                                       if (newValue <= maxValue) {
                                           let tmpData = data;
                                           tmpData['amount'] = newValue;

                                           setValue(newValue);
                                           setData(tmpData);
                                       }
                                   }}/>
                            {data && data['amount_name']}
                        </div>

                    </div> }

                </div>
                <div className={styles.footer}>
                    <button className={`button_default save_style`} onClick={() => {
                        action(data);
                    }}>Сохранить</button>
                    <button className={`button_default cancel_style`}>Отмена</button>
                </div>
            </div>
        </div>
    </>

    return openModal ? modal : <></>;
};

export default ModalMoveComponent;

export {ModeCopy};
export {ModeMove};
