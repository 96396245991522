import React, {createRef, useEffect, useState} from 'react';
import classes from "../../WorkRightManualDir/WorkRightManual/workRightManual.module.css";
import WorkRightHeader from "../../WorkRightCommon/WorkRightHeader/workRightHeader";
import WorkRightSelectPage from "../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage";
import WorkRightTable from "../../WorkRightCommon/WorkRightTable/workRightTable";
import WorkRightUserAddMenu from "../WorkRightUserAddMenu/workRightUserAddMenu";
import {getUsers} from "../../../../utils/API/api_user";

const WorkRightUsers = ({setWidget, sizeX, mode}) => {
    const tableRef = createRef();
    const [countInPage, setCountInPage] = useState(1)
    const [page,setPage] = useState(1)
    const [pageAll, setPageAll] = useState(0)
    const [countAll, setCountAll] = useState(0)
    const [filter, setFilter] = useState('')
    const [sortField, setSortField] = useState({type:'',field:''})
    const [structHeader, setStructHeader] = useState({
        name: true,
        search: true,
        add: true,
        filter: false,
        print: false,
        wrap: true
    })
    const [rows, setUsers] = useState([])

    const [struct, setStruct] = useState([])

    const [selectItem, setSelectItem] = useState({id:0, fields:[], group:mode.type.item.id})
    const [rowsButton, setRowsButton] = useState({
        print: false,
        copy: true,
        edit: true,
        delete: true
    })
    //const [visibleAdd, setVisibleAdd] = useState(false)

    useEffect(()=>{
        const getListUsers = async () =>{
            const res = await getUsers(mode.token,mode.type.item.id,page,countInPage,filter,sortField);
            setStruct(res.table);
            if(res.pagination.pages > 1 && res.pagination.active === 1){
                setCountInPage(res.groups[0].length)
            }
            setPageAll(res.pagination.pages);
            setCountAll(res.pagination.total);
            if(res.groups.length > 0){
                let res_users = (res.groups[0]).users;
                setUsers(res_users);
                //setSelectItem({id:0, fields:[], group:mode.type.item.id})

            }
            else
                setUsers([])
        }
        getListUsers();
    },[mode, filter, sortField, page])

    useEffect(()=>{
        setSortField({type:'',field:''});
    },[mode])

    // useEffect(()=>{
    //    setCountInPage(Math.trunc((tableRef.current.getBoundingClientRect().height-45)/60))
    //},[tableRef])

    return (
        <div className={classes.workRightManual}>
            <WorkRightHeader setVisibleAdd={mode.setVisibleAdd} activeData={mode.type.item} struct={structHeader} filter={filter} setFilter={setFilter} setSelectItem={setSelectItem}/>
            <div className={classes.workRightManual_countRows}>Всего найдено: {countAll}</div>
            <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage} />
            <WorkRightTable tableRef={tableRef} mode={mode}
                            struct={struct}
                            rows={rows}
                            rowsButton={rowsButton}
                            setVisibleAdd={mode.setVisibleAdd}
                            setSelectItem={setSelectItem}
                            setWidget={setWidget}
                            sortField={sortField}
                            setSortField={setSortField}/>
            {sizeX === 0 && <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage} />}
            {mode.visibleAdd && <WorkRightUserAddMenu mode={mode} selectItem={selectItem} sizeX={sizeX} setVisibleAdd={mode.setVisibleAdd} setSelectItem={setSelectItem} setWidget={setWidget}/>}
        </div>
    );
};

export default WorkRightUsers;
