import React, { useState } from "react"
import styles from "./FloatParametersStyles.module.css"
import DropdownComponent from "../../../../../Widgets/Dropdown/DropdownComponent";


const Component = ({parameters, output, setOutput}) => {

    const [ rounding, setRounding ] = useState(output ? output.rounding : null)

    const changed = (rounding) => {
        setOutput({
            rounding,
        })
        setRounding(rounding)
    }

    return <>
        <div
            className={styles.row}
        >
            <span>Округление</span>
            <div className={styles.dropdown}>
                <DropdownComponent
                    keyItem={'value'}
                    valueItem={'name'}
                    selectedKey={rounding}
                    items={parameters.rounding}
                    onChange={(val) => changed(val)}
                />
            </div>
        </div>
    </>
}

export default Component;