import React, {useContext, useEffect, useState} from 'react';
import classes from "./workRightHeader.module.css";
import classes2 from "../../../WorkLeft/WorkLeftHeader/workLeftHeader.module.css"
import searchSVG from '../../../../svg/serch-01.svg'
import wrapSVG from '../../../../svg/wrap-01.svg'
import unwrapSVG from '../../../../svg/unwrap-01.svg'
import {modeContext, wrapContext} from "../../../Contexts/contexts";
import {getUserReports} from "../../../../utils/API/api_user";
import {getIdSection} from "../../../../utils/section";
import {observer} from "mobx-react-lite";
import {SamplesStore} from "../../../../store/SamplesStore";
import {getReportConstructor} from "../../../../utils/API/api_report";
import {address_server_short} from "../../../../config";

const WorkRightHeader = observer(({setVisibleAdd, activeData, struct, filter, setFilter, setSelectItem}) => {
    const {mode} = useContext(modeContext)
    const sampleStorage = useContext(SamplesStore)
    const {wrap, setWrap} = useContext(wrapContext)
    const [searchFieldFull, setSearchFieldFull] = useState(false)
    const styleFieldFull = [
        {maxWidth:'100%', margin:'0 40px'},
        {}
    ]
    const [visiblePrintMenu, setVisiblePrintMenu] = useState(false)
    const [menuPrint, setMenuPrint] = useState([]);

    const [sharedFilter, setSharedFilter] = useState(mode.sharedFilter);

    useEffect(()=>{
        setSearchFieldFull(mode.path==='Хранилище');
        const getUserReportsT = async () =>{
            const res = await getUserReports(mode.token, getIdSection(mode.path))
            setMenuPrint(res)
        }
        getUserReportsT()
    },[mode]);

    useEffect(() => {
        mode.setSharedFilter(sharedFilter);
    }, [sharedFilter]);

    const [url, setUrl] = React.useState('');

    React.useEffect(() => {
        getReportConstructor().then(res => {
            let index = res.indexOf("src");
            let src = res.substring(index, 91);
            let newSrc = src.replace('src="', `src="${address_server_short}`);

            let u = newSrc.replace('src="', '');
            u = u.slice(0, u.length-2);
            //openInNewTab(u);
            setUrl(u)

        })
    }, []);

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <div className={classes.workRightHeader}>
            {struct.name &&
                <div className={classes.workRightHeader_name}>{activeData?.name}</div>
            }
            <div className={classes.workRightHeader_block}>
            {struct.search &&
                <div className={classes2.WorkLeftHeader_search} style={styleFieldFull[searchFieldFull?0:1]}>
                    <img className={classes2.WorkLeftHeader_search_svg} src={searchSVG} alt={'search'}/>
                    <input className={classes2.WorkLeftHeader_search_input} placeholder={'Отобрать'} value={filter} onChange={e=>setFilter(e.target.value)}/>
                </div>
            }

            {struct.add &&
                <div className={classes.workRightHeader_add_block} onClick={() => {
                    setVisibleAdd(true);
                    if (mode.path === "Хранилище" || mode.path === "Образцы") {
                        sampleStorage.setEmptySample();
                    }

                    if (mode.path === 'Пользователи') {
                        setSelectItem({id:0, fields:[], group:activeData.id})
                    }
                }}>
                    <div className={classes.workRightHeader_add}></div>
                </div>
            } {
              mode.path === 'Отчеты' &&
              <button className={`button_default save_style`} style={{marginLeft: '10px'}} onClick={() => { openInNewTab(url)}}>Отчет</button>
            }
            {(struct.filter && mode.path !== "Справочники" && false) &&
                <img className={classes.WorkRightHeader_filter} alt={'filter'} src={sharedFilter ? '/svg/icon_filter-fill.svg': '/svg/icon_filter-01.svg'} onClick={() => setSharedFilter(prevState => !prevState)}/>
            }
            {struct.print &&
                <>
                    <img className={classes.WorkRightHeader_filter} alt={'print'} src={'/svg/print-01.svg'} onClick={()=>setVisiblePrintMenu(!visiblePrintMenu)}/>
                    {(visiblePrintMenu && menuPrint.length > 0) &&<>
                        <div className={classes.WorkRightHeader_filter_printBlock} onClick={()=>setVisiblePrintMenu(false)}></div>
                        
                        <div className={classes.PrintBlock}>{menuPrint.map(item=>
                            <div>
                                <div className={classes.PrintBlockHeader}>{item.name}</div>
                                {item.reports.map(item2 =>
                                    <div className={classes.PrintBlockItem}>{item2.name}</div>)}
                            </div>
                        )}</div>
                    </>}
                </>
            }
            {struct.wrap &&
                (wrap?<img className={classes.WorkRightHeader_filter} alt={'wrap'} src={wrapSVG} onClick={()=>setWrap(false)} />:
                    <img className={classes.WorkRightHeader_filter} alt={'unwrap'} src={unwrapSVG} onClick={()=>setWrap(true)} />)
            }
            </div>
        </div>
    );
});

export default WorkRightHeader;
