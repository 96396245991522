import React, {useContext, useEffect, useState} from 'react';
import classes2 from "../WorkRight/workRight.module.css";
import classes from "./workRightTable.module.css"
import WorkRightTableRowItem from "./workRightTableRowItem";
import {getMasItem} from "../../../../utils/struct";
import {deleteReport} from "../../../../utils/API/api_report";
import {deleteUser} from "../../../../utils/API/api_user";
import {deleteSample, moveSample} from "../../../../utils/API/api_sample";
import {deleteDataCatalog} from "../../../../utils/API/api_catalog";
import {observer} from "mobx-react-lite";
import {SamplesStore} from "../../../../store/SamplesStore";
import ImagePopupComponent from "../../../Widgets/ImagePopup/ImagePopupComponent";
import {StorageStore} from "../../../../store/StorageStore";
import {ModeCopy} from "../../../Widgets/Modal/ModalMoveComponent";


const WorkRightTableRow = observer(({
    mode,
    struct,
    rowsItem,
    rowsButton,
    setVisibleAdd,
    setSelectItem,
    setWidget,
    fromOther = false,
    handleSetImages,showColumnSelector = false
}) => {
    const sampleStorage = useContext(SamplesStore);
    const storageStore = useContext(StorageStore);
    const [massItem, setMassItem] = useState([]);

    useEffect(() => {
        if (showColumnSelector)
            setMassItem(getMasItem(struct.filter(i => i.showColumn), rowsItem.value))
        else
            setMassItem(getMasItem(struct, rowsItem.value))
    }, [struct, rowsItem])


    const startCopy = () => {
        if (mode.path === 'Образцы') {
            storageStore.setSelectedSample(mode.token, rowsItem.id, 'view');
            mode.setCopyMode(true);
            mode.setCopyType(ModeCopy);
        }
    }

    const handleDeleteButton = () => {
        if (mode.path === 'Пользователи') {
            setWidget({
                status: 'Sure',
                text: 'Вы действительно хотите удалить пользователя?',
                fun: async () => {
                    await deleteUser(mode.token, rowsItem.id);
                    const m = {id: mode.type.item.id, name: mode.type.item.name}
                    mode.setType({mode: 'view', item: m})
                }
            })
        }
        if (mode.path === 'Отчеты') {
            setWidget({
                status: 'Sure',
                text: 'Вы действительно хотите удалить отчет?',
                fun: async () => {
                    await deleteReport(mode.token, rowsItem.id);
                    const m = {id: mode.type.item.id, name: mode.type.item.name}
                    mode.setType({mode: 'view', item: m})
                }
            })
        }
        if (mode.path === 'Образцы') {
            setWidget({
                status: 'Sure',
                text: 'Вы действительно хотите удалить образец?',
                fun: async () => {
                    await deleteSample(mode.token, rowsItem.id);
                    mode.setType({mode: 'view', item: ''})
                }
            })
        }
        if (mode.path === 'Справочники') {
            setWidget({
                status: 'Sure',
                text: 'Вы действительно хотите удалить запись?',
                fun: async () => {
                    await deleteDataCatalog(mode.token, rowsItem.id);
                    mode.setType({mode: 'view', item: mode.type?.item})
                }
            })
        }
    }

    const Table = () => {
        return massItem.map((item) => {
            let it = item;
            // try {it.value = JSON.parse(item.value)} catch (error) {}
            return it ?
                <WorkRightTableRowItem
                    mode={mode}
                    rowsItem={rowsItem}
                    item={it}
                    key={it.id}
                    fromOther={fromOther}
                    params={rowsItem.setting ? rowsItem.setting[it.name]
                          : {}}
                    imagePopup={() => {
                        if (it.type === 'image') {
                            if (it.setting)
                                handleSetImages(rowsItem.setting);
                        }
                    }}
                    /> : it.type
        })
    }

    return (
        <tr className={classes.workRightTableRow}>
            <Table/>
            <td className={classes.workRightTableItem + ' ' + classes.workRightTableItem_last}>
                <div style={{display: 'flex'}}>
                    {rowsButton.print && <div
                        className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_print}>
                    </div>}
                    { (rowsButton.copy && mode.path !== 'Пользователи') && <div onClick={startCopy} className={classes.workRightTableItem_Button + ' '
                        + classes2.workRightTableItem_button_copy}></div>}
                    {rowsButton.edit && <div onClick={async () => {

                        if (mode.path === 'Справочники') {
                            let values = rowsItem.value

                            let settingsValues = Object.values(rowsItem.setting)
                            let settingsKeys = Object.keys(rowsItem.setting)

                            const fields = settingsKeys.reduce((acc, key, index) => {
                                acc[key] = {
                                    value: values[key],
                                    setting: settingsValues[index],
                                }
                                return acc;
                            }, {})
                            const row = {id: rowsItem.id, fields}
                            setSelectItem(row);
                            setVisibleAdd(true);
                        } else {
                            const row = {id: rowsItem.id, fields: rowsItem.value}
                            setSelectItem(row);
                            setVisibleAdd(true);
                        }
                        if (mode.path === 'Образцы') {
                            await sampleStorage.setSelectedSample(mode.token, rowsItem.id);
                            await sampleStorage.setSelectedPack(struct.id)
                        }
                    }}
                                             className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_edit}>
                    </div>}
                    {rowsButton.delete && <div onClick={handleDeleteButton}
                                               className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_delete}></div>}
                </div>
            </td>
        </tr>

    );
});

export default WorkRightTableRow;
