import React, {useContext, useEffect, useMemo, useState} from 'react';
import classes from "./workRightStorageTable.module.css";
import {modeContext} from "../../../Contexts/contexts";
import {deleteSample} from "../../../../utils/API/api_sample";
import {observer} from "mobx-react-lite";
import {StorageStore} from "../../../../store/StorageStore";
import { Droppable, Draggable } from "react-beautiful-dnd"
import {SamplesStore} from "../../../../store/SamplesStore";
import {getStoragePacks} from "../../../../utils/API/api_storage";
import {toJS} from "mobx";
import {ExtendedSampleContext} from "../WorkRightStorage/workRightStorage";

const parseTable = (struct, storage, samples, pack) => {

    let temp = [];
    let res = [];
    temp.push({type:'header',value:''});
    struct.columns.forEach(i=>temp.push({type:'header',value:i}));
    res.push(temp);

    struct.lines.forEach(i=>{
        temp = [{type:'header',value:i}];
        struct.columns.forEach(j=>temp.push({
            type:'item',
            value:'',
            column:j,
            line:i,
            coords:`${j}${i}`,
            pack,
            storage,
        }));
        res.push(temp)
    })

    const findItemIndexesByCoordinats = (coords) => {
        let item = null;

        for(let i=0; i<res.length; i++) {
            for(let j=0; j < res[i].length; j++) {
                if (res[i][j].coords === coords) return {column: j, line: i};
            }
        }
        return item;
    }

    Array.isArray(samples) &&  samples?.forEach(i=> {
        let item = findItemIndexesByCoordinats(`${i.column}${i.line}`);
        if (item) {
            res[item.line][item.column] = {
                type:'item',
                value:i,
                coords:`${i.column}${i.line}`,
                line:i.line,
                column:i.column,
                pack,
                storage,
            }
        }
    })

    return res;
}

const WorkRightStorageTablePack = observer(({mode, storage, pack, setViewMode, path, samples}) => {

    const storageStore = useContext(StorageStore)
    const sampleStorage = useContext(SamplesStore)
    const {widget} = useContext(modeContext)
    const struct = pack.structura;
    const table = parseTable(struct, storage.id, samples ?? [], struct.id);
    const {showEmptyDetail,  setShowEmptyDetail} = useContext(ExtendedSampleContext);

    const [preload, setPreload] = useState(false)

    async function startDelete(item){
        widget.setWidget({status:'Sure',
            text:'Вы действительно хотите удалить образец?',
            fun: async () => {
                if (storage.id !== undefined) {
                    const res = await deleteSample(mode.token, item.value.id);
                    let temp = table;
                    temp[item.value.line.charCodeAt(0)-65+1][item.value.column.charCodeAt(0)-65+1] = {type:'item',value:'',column:item.value.column,line:item.value.line};
                    await storageStore.refreshSamples(mode.token, 'list');
                } else {
                    const res = await deleteSample(mode.token, item.value.id);
                    await storageStore.refreshSamples(mode.token, 'list', pack.id);
                }
            }})
    }

    const editSelectedCell = (cell) => {
        if (storage.id !== undefined) {
            mode.editSelectedCell({packId: pack.id, storageId: storage.id, column: cell.column, line: cell.line});
        }
        else {
            mode.editSelectedCell({packId: pack.id, storageId: 0, column: cell.column, line: cell.line});
        }
    }

    const checkCell = (cell) => {
        if (storage.id !== undefined)
            return mode.selectedCell.find(
              i => i.packId === pack.id
                && i.column === cell.column
                && i.line === cell.line
                && i.storageId === storage.id);
        return mode.selectedCell.find(
          i => i.packId === pack.id
            && i.column === cell.column
            && i.line === cell.line);
    }

    const renderTable = useMemo(() => {
        return table.map((item, index) => {
            return item.map((cell, cell_index) => {
                if (cell.type === 'header') {
                    return <div key={cell_index}>
                        <div>{cell.value}</div>
                    </div>;
                } else {
                    return <>
                        <Droppable
                            droppableId={`${cell.coords}:${cell.value.id === undefined ? pack.id : cell.value.id}:${cell.storage}`}
                            isDropDisabled={!!cell.value.type}
                        >
                            {(provided) => (<>
                                {cell.value.type ? <>
                                    <div
                                        {...provided.droppableProps} ref={provided.innerRef}
                                        key={cell_index}
                                        className={classes.table_item_block_body}
                                    >
                                        <Draggable

                                            key={`${cell.coords}:${cell.pack}:${cell.value.id}:${cell.storage}`}
                                            draggableId={`${cell.coords}:${cell.pack}:${cell.value.id}:${cell.storage}`}
                                            index={cell_index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{...provided.draggableProps.style}}
                                                    ref={provided.innerRef}
                                                    className={classes.table_item_block}
                                                >
                                                    <div
                                                        className={classes.table_item}
                                                        style={{
                                                            background: cell.value.color ?? ''
                                                        }}
                                                        onClick={() => {
                                                            storageStore.setSelectedSample(mode.token, cell.value.id, 'view')
                                                            setViewMode(cell.value.id)
                                                        }}
                                                    >
                                                        {cell.value.type}
                                                        {!cell.value.type &&
                                                            <div className={classes.table_item_add_block}>
                                                                <div className={classes.table_item_add}></div>
                                                            </div>
                                                        }
                                                    </div>
                                                    {cell.value.type && <div className={classes.table_item_delete}
                                                                              onClick={() => startDelete(cell)}>
                                                        <div className={classes.table_item_delete_svg}></div>
                                                    </div>}
                                                    {cell.value.type && <div
                                                        className={classes.table_item_edit}
                                                        onClick={() => {
                                                            new Promise(async (resolve) => {
                                                                await sampleStorage.setEmptySample();
                                                                await sampleStorage.setSelectedSample(mode.token, cell.value.id);
                                                                // await sampleStorage.changeSampleField('storage', cell.storage, mode.token)
                                                                // await sampleStorage.setSelectedPack(struct.id)
                                                                // await sampleStorage.setAvailablePacks(await getStoragePacks(mode.token, cell.value.id))
                                                                resolve(true)
                                                            }).then((res) => {
                                                                mode.setVisibleAdd(res)
                                                            })
                                                        }}
                                                    >
                                                        <div className={classes.table_item_edit_svg}></div>
                                                    </div>}
                                                </div>
                                            )}
                                        </Draggable>
                                        <div className={`${classes.table_item} ${classes.table_item_ghost} `}></div>
                                    </div>
                                </> : <>
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className={classes.table_item_block}
                                        key={cell_index}
                                    >
                                        <div className={classes.table_item +' '+ (checkCell(cell) ? classes.table_border_green : '')}
                                            onClick={async (e) => {
                                                switch (e.detail) {
                                                    case 1: {

                                                        setShowEmptyDetail(true);


                                                            new Promise(async (resolve) => {
                                                                setPreload(`${cell.column}${cell.line}${cell.pack}`)
                                                                sampleStorage.setEmptySample();
                                                                await sampleStorage.changeSampleField('storage', cell.storage, mode.token)
                                                                await sampleStorage.setAvailablePacks(await getStoragePacks(mode.token, cell.storage))
                                                                await sampleStorage.setSelectedPack(struct.id)
                                                                await sampleStorage.changeSampleField('pack', pack.id)
                                                                await sampleStorage.changeSampleField('column', cell.column)
                                                                await sampleStorage.changeSampleField('line', cell.line)

                                                                resolve(true);
                                                            }).then();
                                                        break;
                                                    }
                                                    case 2: {
                                                        setShowEmptyDetail(false);
                                                        new Promise(async (resolve) => {
                                                            setPreload(`${cell.column}${cell.line}${cell.pack}`)
                                                            sampleStorage.setEmptySample();
                                                            await sampleStorage.changeSampleField('storage', cell.storage, mode.token)
                                                            await sampleStorage.setAvailablePacks(await getStoragePacks(mode.token, cell.storage))
                                                            await sampleStorage.setSelectedPack(struct.id)
                                                            await sampleStorage.changeSampleField('pack', pack.id)
                                                            await sampleStorage.changeSampleField('column', cell.column)
                                                            await sampleStorage.changeSampleField('line', cell.line)

                                                            resolve(true);
                                                        }).then((r) => {
                                                            setPreload(false)
                                                            mode.setVisibleAdd(r);
                                                        });
                                                        break;
                                                    }
                                                }


                                            }}
                                        >
                                            <div className={classes.table_item_add_block}>
                                                { (preload && preload === `${cell.column}${cell.line}${cell.pack}` )
                                                    ? <div className={classes.table_item_preload}><span></span></div> :
                                                    <div className={classes.table_item_add}></div> }

                                            </div>
                                        </div>
                                        <div className={classes.table_item_delete  +' '+ (checkCell(cell) ? classes.table_item_green : '')}
                                             onClick={() => {
                                                 editSelectedCell(cell)
                                             }}>
                                            <div className={classes.table_item_select_svg}></div>
                                        </div>
                                    </div>
                                </>}
                            </>)}
                    </Droppable>
                    </>
                }
            })}
        );
    }, [table])

    return <>
        {pack.name}
            <div className={classes.table_body}
                style={{
                    gridTemplateColumns: `repeat(${table[0]?.length}, 50px)`,
                    gridTemplateRows: "20px",
                    alignItems: 'center',
                }}
            >
                { renderTable }
            </div>
    </>;
});

export default WorkRightStorageTablePack;
