import React, {useContext, useState} from 'react';
import WorkLeftManualItem from "./workLeftManualItem";
import ModalInfoComponent, {StatusError} from "../../Widgets/ModalInfo/ModalInfoComponent";
import {observer} from "mobx-react-lite";
import {deleteCatalog} from "../../../utils/API/api_catalog";
import {FieldsStore} from "../../../store/FieldsStore";
import {StructureStore} from "../../../store/StructureStore";
import classes from "./workLeftManual.module.css";
import {v4 as uuid} from 'uuid';

const rowsButton = {
    print: false,
    copy: true,
    edit: true,
    delete: true
}

const WorkLeftManual = observer(({setWidget, mode}) => {

    const store = useContext(FieldsStore);
    const structureStore = useContext(StructureStore);


    const [modalOpened, setModalOpened] = useState(false);

    const modalActionOnAccept = () => {
        deleteCatalog(mode.token, store.deleteStructureId)
        if (store.deleteStructureId === store.activeStructureId) {
            store.clearStructure()
        }
        setModalOpened(false)
        mode.setType({ mode: 'view', item: ''})
    }
    const renderCatalogList =  structureStore.catalog.map((rowsItem) => <>
            <WorkLeftManualItem
                key={uuid(rowsItem.id)}
                item={rowsItem}
                rowsButton={rowsButton}
                setWidget={setWidget}
                mode={mode}
                modalOpen={() => setModalOpened(true)}
            />
        </>);


    return <>
        <div className={classes.WorkLeftTableBlock} >
            <table className={classes.WorkLeftTable}>
                <tbody
                    key={uuid()}
                >
                    { renderCatalogList }
                </tbody>
            </table>
            <ModalInfoComponent
                key={uuid()}
                openModal={modalOpened}
                onClose={() => setModalOpened(false)}
                onAccept={modalActionOnAccept}
                title={"Вы уверены?"}
                text={"После удаления структуры её нельзя будет восстановить!"}
                acceptButtonText={"Да"}
                status={StatusError}
            />
        </div>


    </>;
});

export default WorkLeftManual;