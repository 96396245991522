import React from 'react';
import WidgetSwitchVariants from "../../Widgets/WidgetSwitchVariants";

import classes from './index.module.css';
import ExportModule from "./Export/Export";
import ImportModule from "./Import/Import";
import {getReportConstructor} from "../../../utils/API/api_report";
import {address_server_short} from "../../../config";

const menuItems = [
  {id: 1, name: 'Импорт'},
  {id: 2, name: 'Экспорт'}
]

const DataTransferPage = ({
  setWidget,
  mode
}) => {

  const [selectedMenuItem, setSelectedMenuItem] = React.useState({id: 1, name: 'Импорт'});
  // const [url, setUrl] = React.useState('');
  //
  // React.useEffect(() => {
  //   getReportConstructor().then(res => {
  //     let index = res.indexOf("src");
  //     let src = res.substring(index, 91);
  //     let newSrc = src.replace('src="', `src="${address_server_short}`);
  //
  //     let u = newSrc.replace('src="', '');
  //     u = u.slice(0, u.length-2);
  //     //openInNewTab(u);
  //      setUrl(u)
  //
  //   })
  // }, []);
  //
  // const openInNewTab = (url) => {
  //   window.open(url, "_blank", "noreferrer");
  // };

  return (
    <div className={classes.container}>

      {/*<button className={`button_default save_style`} onClick={() => { openInNewTab(url)}}>Отчет</button>*/}
        {/*<WidgetSwitchVariants item={{setting: menuItems}}*/}
        {/*                         current={selectedMenuItem}*/}
        {/*                         itemKey={'id'}*/}
        {/*                         itemValue={'name'}*/}
        {/*                         onChange={(data) => { setSelectedMenuItem(data)}}/>*/}


      <div className={classes.body}>
        <ImportModule setWidget={setWidget} mode={mode}/>
        {/*{*/}
        {/*  selectedMenuItem?.id === 1 && <ImportModule setWidget={setWidget} mode={mode}/>*/}
        {/*}*/}
        {/*{*/}
        {/*  selectedMenuItem?.id === 2 && <ExportModule setWidget={setWidget} mode={mode}/>*/}
        {/*}*/}
      </div>
    </div>
  );
}

export default DataTransferPage;
