import React, {useContext, useEffect, useState} from 'react';
import WorkRightHeader from "../../WorkRightCommon/WorkRightHeader/workRightHeader";
import WorkRightSelectPage from "../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage";
import WorkRightTable from "../../WorkRightCommon/WorkRightTable/workRightTable";
import WorkRightAddMenu from "../WorkRightAddMenu/workRightAddMenu";
import {getMappingCatalog, getMappingData} from '../../../../utils/API/api_catalog';
import classes from "./workRightManual.module.css";
import {observer} from "mobx-react-lite";
import {StructureStore} from "../../../../store/StructureStore";
import Preloader from "../../../Widgets/Preloader/Preloader"

const structHeader = {
    name: true,
    search: false,
    add: true,
    filter: true,
    print: false,
    wrap: true
}

const rowsButton = {
    print: false,
    copy: true,
    edit: true,
    delete: true
}

const WorkRightManual = observer(({sizeX, setWidget, mode}) => {
    const structureStore = useContext(StructureStore);

    const [sortField, setSortField] = useState({type: '', field: ''})
    const [page, setPage] = useState(1)
    const [pageAll, setPageAll] = useState(0)
    const [itemsCount, setItemsCount] = useState(0)
    const [rows, setRows] = useState([])
    const [allItems, setAllItems] = useState([])
    const [selectItem, setSelectItem] = useState({id: 0, fields: {}})
    const [struct, setStruct] = useState([])
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setLoad(true)
        const request = async () => {
            const catalogFields = await getMappingCatalog(mode.token, mode.type?.item?.id);
            if (structureStore.selectedStructure === catalogFields?.catalog) {
                setStruct(catalogFields?.fields)
            }

            const mappingData = await getMappingData(mode.token, mode.type?.item?.id, 20);

            if (structureStore.selectedStructure === mappingData?.catalog) {
                setPageAll(mappingData.pageAll);
                setItemsCount(mappingData.total);
                setAllItems(mappingData.itemsByPages)
                setRows(mappingData.itemsByPages[0])
                setPage(1);
            }
            setLoad(false);
        }

        request();
    }, [mode.type, mode.activeTabId])

    const handlerSearch = async (textSearch) => {
        const mappingData = await getMappingData(mode.token, mode.type?.item?.id, textSearch);
        setPageAll(mappingData.pageAll);
        setItemsCount(mappingData.total);
        setRows([...mappingData.itemsByPages[page - 1]])
    }
    const changePage = async (page) => {
        setPage(page)
        setRows(allItems[page - 1])
    }

    return (
        <div className={classes.workRightManual}>
            {load ? <Preloader /> :""}
            <WorkRightHeader
                setVisibleAdd={mode.setVisibleAdd}
                activeData={mode.type.item}
                struct={structHeader}
                setFilter={handlerSearch}
            />
            <div className={classes.workRightManual_countRows}>Всего найдено: {itemsCount}</div>
            <WorkRightSelectPage allCount={allItems.length} selectItem={page} setSelectItem={changePage}/>
            <WorkRightTable
                mode={mode}
                struct={struct ?? []}
                rows={rows}
                rowsButton={rowsButton}
                setVisibleAdd={mode.setVisibleAdd}
                setSelectItem={setSelectItem}
                setWidget={setWidget}
                sortField={sortField}
                setSortField={setSortField}
            />
            {sizeX === 0 && <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage}/>}
            {mode.visibleAdd && <WorkRightAddMenu
                struct={struct}
                sizeX={640}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                setVisibleAdd={mode.setVisibleAdd}
                setWidget={setWidget} mode={mode}/>}
        </div>
    );
});

export default WorkRightManual;
