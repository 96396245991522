const Icon = ({src, className, color, alt = "icon", width = '', height = '', onClick}) => {
    return <>
        <i
            onClick={onClick}
            className={className}
            style={{
                 display: 'block',
                 WebkitMaskSize: 'contain',
                 maskSize: 'contain',
                 WebkitMaskImage: `url(${src})`,
                 WebkitMaskRepeat: 'no-repeat',
                 WebkitMaskPosition: 'center',
                 maskImage: `url(${src}) center no-repeat`,
                 backgroundSize: 'contain',
                 backgroundColor: color ?? 'black',
                 width: width,
                 height: height,
             }}
        />
    </>
}
export default Icon