import React, {useContext} from 'react';
import classes from "./leftMenu.module.css";
import {modeContext} from "../Contexts/contexts";
import {getReportsGroups} from "../../utils/API/api_report";
import {getCatalogs} from "../../utils/API/api_catalog";
import {StructureStore} from "../../store/StructureStore";

const LeftMenuItem =  ({fullMenu, menuItem}) => {
  const {mode} = useContext(modeContext);
  const modeMain = useContext(modeContext);

  const structureStore = useContext(StructureStore);
    const rootClasses = [classes.LeftMenu_Menu_Item];
    if(!fullMenu){
        rootClasses.push(classes.LeftMenu_Menu_Item_small);
    }
    if(mode.path === menuItem.text)
        rootClasses.push(classes.LeftMenu_Menu_Item_Active);
    return (
        <div className={rootClasses.join(' ')}
             onClick={async () => {
               if (menuItem.text === 'Отчеты') {
                 await new Promise(async (resolve) => {
                   const response = await getReportsGroups(mode.token)
                   resolve(response[0])
                 }).then((responseItem) => {
                   mode.setType({mode: 'view', item: responseItem})
                 })
               } else if (menuItem.text === 'Справочники') {
                 structureStore.setSelectedStructure(mode.type.item.id);
                 await new Promise(async (resolve) => {
                   const response = await getCatalogs(mode.token)
                   resolve(response[0])
                 }).then((responseItem) => {
                   mode.setType({mode: 'view', item: responseItem})
                 })
               } else {
                 mode.setType({mode: 'view', item: ''})
               }
               mode.setPath(menuItem.text);

               const createNewTab = () => {

                 const newTab = {
                   name: menuItem.text,
                   path: menuItem.text,
                   mode: JSON.parse(JSON.stringify(modeMain)),
                   id: new Date().getTime()
                 };

                 //localStorage.setItem('active_tabs', newTab.id);
                 mode.setTabs([...mode.tabs, newTab]);
                 mode.setActiveTabId(newTab.id);
                 mode.setVisibleAdd(false)
               }

               if (mode.activeTabId && mode.activeTabId !== 0) {
                 const tabs = [...mode.tabs];
                 const tab = tabs.find(i => i.id === mode.activeTabId);
                 if (tab) {
                   tab.path = menuItem.text;
                   tab.name = menuItem.text;

                   tab.mode = JSON.parse(JSON.stringify(modeMain));
                   mode.setTabs(tabs);
                 } else {
                   createNewTab();
                 }

               } else {
                 createNewTab();
               }


             }}>
            <div style={{ WebkitMaskImage: 'url("/svg/'+menuItem.src+'")', maskImage: 'url("/svg/'+menuItem.src+'")'}}  className={classes.LeftMenu_Menu_Item_img}></div>
            {fullMenu && <div className={classes.LeftMenu_Menu_Item_text}>
                {menuItem.text}
            </div>}
        </div>
    );
};

export default LeftMenuItem;
