import React, { useContext, useEffect, useState } from 'react';
import classes from "./workLeftHeader.module.css";
import searchSVG from '../../../svg/serch-01.svg'
import { getListTypeStorages } from "../../../utils/API/api_list";
import { getUserReports } from "../../../utils/API/api_user";
import { getIdSection } from "../../../utils/section";
import { observer } from "mobx-react-lite";
import { FieldsStore } from "../../../store/FieldsStore";
import { StructureStore } from "../../../store/StructureStore";
import {StorageStore} from "../../../store/StorageStore";
import {address_server_short} from "../../../config";
import {toJS} from "mobx";

const WorkLeftHeader = observer(({ mode, filter, setFilter, favorite, setFavorite }) => {
    const store = useContext(FieldsStore)
    const structureStore = useContext(StructureStore)
    const storageStore = useContext(StorageStore)

    const [struct, setStruct] = useState({
        favorite: true,
        search: true,
        add: true,
        add_text: "Добавить",
        add_plus: true,
        copy: true,
        print: false
    })
    const [listAdd, setListAdd] = useState([])
    const [openDropdown, setOpenDropdown] = useState(false)
    const [visiblePrintMenu, setVisiblePrintMenu] = useState(false)
    const [menuPrint, setMenuPrint] = useState([])


    useEffect(() => {
        if (mode.path === 'Отчеты')
            setStruct({
                favorite: false,
                search: true,
                add: true,
                add_text: "Добавить группу",
                add_plus: false,
                copy: false,
                print: false
            })
        if (mode.path === 'Справочники')
            setStruct({
                favorite: false,
                search: true,
                add: true,
                add_text: "Добавить",
                add_plus: false,
                copy: false,
                print: false
            })
        if (mode.path === 'Пользователи')
            setStruct({
                favorite: false,
                search: true,
                add: true,
                add_text: "Добавить группу",
                add_plus: false,
                copy: false,
                print: false
            })
        if (mode.path === 'Хранилище')
            setStruct({
                favorite: true,
                search: true,
                add: true,
                add_text: "Добавить",
                add_plus: true,
                copy: false,
                print: false
            })

        const getUserReportsT = async () => {
            const res = await getUserReports(mode.token, getIdSection(mode.path))
            setMenuPrint(res)
        }

        getUserReportsT()
    }, [mode])

    useEffect(() => {
        const getStorageTypeT = async () => {
            const res = await getListTypeStorages(mode.token);
            setListAdd(res);
        }
        if (mode.path === 'Хранилище') {
            getStorageTypeT()
        }
    }, [mode])

    const setSearch = (event) => {
        if (mode.path === 'Справочники') {
            setFilter(event.target.value)
            structureStore.setSearchStructureName(mode.token, event.target.value)
        } else {
            setFilter(event.target.value)
        }
    }
    function add_button() {
        if (mode.path === 'Пользователи') {
            mode.setType({ mode: 'edit', item: { name: '', id: 0 } })
        }
        if (mode.path === 'Справочники') {
            store.setFields([])
            store.changeActiveStructureName('')
            store.setEditingStructure(false)
            store.setActiveStructureId(0)
            mode.setType({ mode: 'edit', item: { name: '', id: 0 } })
        }
        if (mode.path === 'Отчеты') {
            mode.setType({ mode: 'edit', item: { name: '', id: 0 } })
        }
        if (mode.path === 'Хранилище') {
            setOpenDropdown(!openDropdown)
        }
    }

    function copy() {
        if (mode.path === 'Хранилище') {
            storageStore.setCopyStorage(mode.token).then()
            mode.setType({ mode: 'edit', item: storageStore.selectedStorage })
        }
    }
    return (
        <div className={classes.WorkLeftHeader}>
            {(struct.favorite && favorite) &&
                <img className={classes.WorkLeftHeader_favorite} alt={'favorite'} src={'/svg/star-01.svg'} onClick={() => setFavorite(false)} />
            }
            {(struct.favorite && !favorite) &&
                <img className={classes.WorkLeftHeader_favorite} alt={'favorite'} src={'/svg/star2-01.svg'} onClick={() => setFavorite(true)} />
            }
            {struct.search &&
                <div className={classes.WorkLeftHeader_search}>
                    <img className={classes.WorkLeftHeader_search_svg} src={searchSVG} alt={'search'} />
                    <input className={classes.WorkLeftHeader_search_input}
                        placeholder={'Отобрать'}
                        value={filter}
                        onChange={setSearch} />
                </div>
            }
            {struct.add &&
                <div className={classes.WorkLeftHeader_add} onClick={add_button}>{struct.add_text}
                    {struct.add_plus && <div className={classes.WorkLeftHeader_add_plus}></div>}
                    {(struct.add_plus && openDropdown) &&
                        <>
                            <div className={classes.DropdownBlock} onClick={() => setOpenDropdown(false)}></div>
                            <div className={classes.WorkLeftHeader_add_plus_Dropdown}>
                                { listAdd.map((item, index) => {
                                    if  (item.name !== 'Свободный элемент') {
                                        if (item.name === 'Штатив') {
                                            return <div key={item.id} onClick={() => {
                                                mode.setPuckSelectModal(true);
                                            }} className={classes.WorkLeftHeader_add_plus_Dropdown_item}>
                                                <img src={`${address_server_short}${item.icon}`}
                                                     alt={item.name}/><span>{item.name} </span>
                                            </div>
                                        }

                                        if (!storageStore.selectedStorageId) {
                                            return <>
                                                <div key={item.id} className={classes.WorkLeftHeader_add_plus_Dropdown_item}
                                                     onClick={async (e) => {
                                                         e.stopPropagation();
                                                         await new Promise(async (resolve) => {
                                                             storageStore.clearSelectedStorage()
                                                             const storage = await storageStore.setNewStorage(mode.token, item.id)
                                                             resolve(storage)
                                                         }).then((res) => {
                                                             mode.setType({mode: 'edit', item: res});
                                                         })
                                                         setOpenDropdown(!openDropdown)
                                                     }}
                                                >
                                                    <img src={`${address_server_short}${item.icon}`}
                                                         alt={item.name}/><span>{item.name} </span>
                                                </div>
                                            </>
                                        } else if (storageStore.selectedType.id === item.parent || item.name === 'Свободный элемент') {
                                            return <>
                                                <div key={item.id} className={classes.WorkLeftHeader_add_plus_Dropdown_item}
                                                     onClick={async (e) => {
                                                         e.stopPropagation();
                                                         await new Promise(async (resolve) => {
                                                             storageStore.clearSelectedStorage()
                                                             const storage = await storageStore.setNewStorage(mode.token, item.id)
                                                             resolve(storage)
                                                         }).then((res) => {
                                                             mode.setType({mode: 'edit', item: res});
                                                         })
                                                         setOpenDropdown(!openDropdown)
                                                     }}
                                                >
                                                    <img src={`${address_server_short}${item.icon}`}
                                                         alt={item.name}/><span>{item.name} </span>
                                                </div>
                                            </>
                                        }
                                    }

                                })}
                            </div>
                        </>
                    }
                </div>
            }
            {struct.copy &&
                <img className={classes.WorkLeftHeader_copy} alt={'copy'} src={'/svg/copy-01.svg'} onClick={copy} />
            }
            {struct.print &&
                <>
                    <img className={classes.WorkLeftHeader_copy} alt={'print'} src={'/svg/print-01.svg'} onClick={() => setVisiblePrintMenu(!visiblePrintMenu)} />
                    {(visiblePrintMenu && menuPrint.length > 0) && <>
                        <div className={classes.WorkRightHeader_filter_printBlock} onClick={() => setVisiblePrintMenu(false)}/>

                        <div className={classes.PrintBlock}>{menuPrint.map(item =>
                            <div>
                                <div className={classes.PrintBlockHeader}>{item.name}</div>
                                {item.reports.map(item2 =>
                                    <div className={classes.PrintBlockItem}>{item2.name}</div>)}
                            </div>
                        )}</div>
                    </>}
                </>
            }
        </div>
    );
});

export default WorkLeftHeader;
