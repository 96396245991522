import React from 'react';
import classes from './systemSettings.module.css';
import DropdownComponent from "../../Widgets/Dropdown/DropdownComponent";
import {COLORS} from "../../../config";
import Clipboard from "clipboard";
import Icon from "../../../helpers/components/Icon";
import close from "../../../svg/copy-01.svg";

const languageSource = [{id: 1, name: 'Русский'}, {id: 2, name: 'English'}];
const countInPageSource = [{id: 1, name: '10'}, {id: 2, name: '20'}];
const typeAuthSource = [{id: 1, name: 'Список пользователей'}, {id: 2, name: 'Пароль и логин'}];

const SystemSettings = ({mode, setWidget}) => {
  const [url, setUrl] = React.useState('https://smartbio.siterepository.ru');
  const [sessionTime, setSessionTime] = React.useState(30);
  const [typeAuth, setTypeAuth] = React.useState(typeAuthSource[0].id);
  const [language, setLanguage] = React.useState(languageSource[0].id);
  const [countInPage, setCountInPage] = React.useState(countInPageSource[1].id);
  const [message, setMessage] = React.useState('');

  const [codeActive, setCodeActive] = React.useState('34365380787E45558CDBC0917A1FCEA4');
  const [codeLicense, setCodeLicense] = React.useState('');

  const updateMessageAndClose = ((msg, timeout = 3) => {
    setMessage(msg);
    setTimeout(() => setMessage(''), timeout * 1000)
  })

  const handleCopyToClipboard = () => {
    const clipboard = new Clipboard('.copy-button');
    clipboard.on('success', (e) => {
      updateMessageAndClose('Скопировано!');
      clipboard.destroy();
    });

  };

  return (
    <div className={classes.container}>
      {/*<div className={classes.body}>*/}
      {/*  <p className={classes.title}>Общие настройки</p>*/}

      {/*  <div className={classes.control_body}>*/}
      {/*    <div className={classes.field}>*/}
      {/*      <p>Адрес сервера</p>*/}
      {/*      <div>*/}
      {/*        <input className={classes.infoItemValue}*/}
      {/*               type="text"*/}
      {/*               value={url}*/}
      {/*               onChange={ e => {setUrl(e.target.value)}} />*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className={classes.field}>*/}
      {/*      <p>Язык интерфейса по умолчанию</p>*/}
      {/*      <div>*/}
      {/*        <DropdownComponent key={'errors_1'}*/}
      {/*                           keyItem={'id'}*/}
      {/*                           valueItem={'name'}*/}
      {/*                           arrowColor={COLORS.color_hover}*/}
      {/*                           items={languageSource}*/}
      {/*                           selectedKey={language}*/}
      {/*                           onChange={(val) => {setLanguage(val)} }/>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className={classes.field}>*/}
      {/*      <p>Время жизни сессии, мин</p>*/}
      {/*      <div>*/}
      {/*        <input className={classes.infoItemValue}*/}
      {/*               type="text"*/}
      {/*               value={sessionTime}*/}
      {/*               onChange={ e => {setSessionTime(e.target.value)}} />*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className={classes.field}>*/}
      {/*      <p>Тип авторизации</p>*/}
      {/*      <div>*/}
      {/*        <DropdownComponent key={'errors_1'}*/}
      {/*                           keyItem={'id'}*/}
      {/*                           valueItem={'name'}*/}
      {/*                           arrowColor={COLORS.color_hover}*/}
      {/*                           items={typeAuthSource}*/}
      {/*                           selectedKey={typeAuth}*/}
      {/*                           onChange={(val) => {setTypeAuth(val)} }/>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*  </div>*/}

      {/*</div>*/}

      <div className={classes.body}>
        <p className={classes.title}>Отображение</p>

        <div className={classes.control_body}>
          <div className={classes.field}>
            <p>Показывать записей на странице</p>
            <div>
              <DropdownComponent key={'errors_1'}
                                 keyItem={'id'}
                                 valueItem={'name'}
                                 arrowColor={COLORS.color_hover}
                                 items={countInPageSource}
                                 selectedKey={countInPage}
                                 onChange={(val) => {setCountInPage(val)} }/>
            </div>
          </div>
        </div>

      </div>


      <div className={classes.body}>
        <p className={classes.title}>Лицензия</p>

        <div className={classes.control_body}>
          <div className={classes.field}>
            <p>Код активации</p>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <input className={classes.infoItemValue}
                     type="text"
                     id={'code-license'}
                     style={{marginRight: '10px'}}
                     value={codeActive}
                     onChange={ e => {setCodeActive(e.target.value)}} />

              <div  onClick={handleCopyToClipboard}
                    className='copy-button'
                    style={{width: 'auto'}}
                    data-clipboard-target={`#code-license`}>
                <Icon color={'#1d6030'}
                      width={'25px'}
                      height={'25px'}
                      src={close}/>
              </div>
            </div>
          </div>
          {message ? <div>{message}</div> : null}

          {/*<div className={classes.field}>*/}
          {/*  <p>Код лицензии</p>*/}
          {/*  <div>*/}
          {/*    <input className={classes.infoItemValue}*/}
          {/*           type="text"*/}
          {/*           value={codeLicense}*/}
          {/*           onChange={ e => {setCodeLicense(e.target.value)}} />*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className={classes.field}>
            <button className={`button_default save_style`}
                    style={{marginTop: '20px'}}
                    onClick={() => {
                      setWidget({
                        status: 'Ok',
                        text: `Ваша лицензия успешно активирована`,
                        fun: async () => { }
                      });

                    }}>Активировать</button>
          </div>

        </div>

      </div>

      <div className={classes.footer}>
        <button className={`button_default save_style`} onClick={() => { }}>Сохранить</button>
        <button className={`button_default cancel_style`} onClick={() => {}}>Отмена</button>
      </div>

    </div>
  );
}

export default SystemSettings;
