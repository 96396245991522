import React, {useState} from 'react';
import classes from "./tree.module.css";
import TreeItem from "./treeItem";
import TreeItemPack from "./treeItemPack";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";


export const ItemTypes = {
  STORAGE_TYPE: 'storage',
  PACK_TYPE: 'pack'
}

const Tree = ({innerRef, mode, storages, favorite, refreshTree, setWidget}) => {

  return (
    <DndProvider backend={HTML5Backend}>
      <div ref={innerRef} style={{fontSize:'12pt'}} className={classes.parent}>
        {  storages.map((item, index) =>
          item.type === 'pack' ?
            <TreeItemPack path={''}
                          mode={mode}
                          key={item.id}
                          value={item}
                          visibleTop={true}
                          last={true}
                          first={true}
                          index={index}
                          setWidget={setWidget}
                          refreshTree={refreshTree}
                          favorite={favorite}/>:
            <TreeItem path={''}
                      mode={mode}
                      key={item.id}
                      value={item}
                      visibleTop={true}
                      last={true}
                      first={true}
                      index={index}
                      setWidget={setWidget}
                      refreshTree={refreshTree}
                      favorite={favorite}/>
        )
        }
      </div>
    </DndProvider>
  );
};

export default Tree;
