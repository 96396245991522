import React, {useEffect, useState} from 'react';
import classes2 from "../WorkLeftManual/workLeftManual.module.css";
import WorkLeftManualItem from "../WorkLeftManual/workLeftManualItem";

const WorkLeftUsers = ({mode, setWidget, userGroup}) => {
    const [rowsButton, setRowsButton] = useState({
        print: false,
        copy: false,
        edit: true,
        delete: true
    })

    return (
        <div className={classes2.WorkLeftTableBlock}>
            <table className={classes2.WorkLeftTable}>
                <tbody>
                {userGroup.map((rowsItem) =>
                    <WorkLeftManualItem item={rowsItem} key={rowsItem.id} rowsButton={rowsButton} setWidget={setWidget} mode={mode}/>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default WorkLeftUsers;