import React, {useContext, useEffect, useState} from 'react';
import styles from './workRightStorageAddGroup.module.css'
import arrowSVG from '../../../../svg/arrow-01.svg'
import { getStorages } from "../../../../utils/API/api_storage";
import {
    getListBarcodes,
    getListIcon,
} from "../../../../utils/API/api_list";

import { SketchPicker } from 'react-color';
import classes from "../../WorkRightUsersDir/WorkRightUserAddMenu/workRightUserAddMenu.module.css";
import error from "../../../../svg/attention-01.svg";
import DropdownComponent from "../../../Widgets/Dropdown/DropdownComponent";
import MultiInputList from "../../../Widgets/MultiInputList";
import MultiplyDropdownList from "../../../Widgets/MultiplyDropdownList";
import { observer } from "mobx-react-lite";
import { StorageStore } from "../../../../store/StorageStore";
import ColorPicker from "../../../Widgets/ColorPicker";
import {getPackTypes} from "../../../../utils/API/api_pucks";
import {toJS} from "mobx";

const WorkRightStorageAddGroup = observer(({mode, sizeX, setWidget}) => {
    const storageStore = useContext(StorageStore)

    // const id = storageStore.selectedStorage?.id;
    // const type = storageStore.selectedStorage?.type;
    const name =  storageStore.selectedStorage?.name;
    const comment = storageStore.selectedStorage?.comment ?? "";
    const location = storageStore.selectedStorage?.location ?? {parent:storageStore.selectedStorageWithNodes?.id ?? 0, path:'/'};
    const selectColor = storageStore.selectedStorage?.color ?? "#000000";
    const selectIcon = storageStore.selectedStorage?.icon;

    useEffect(() => {
        storageStore.findSelectedType(listIcon);
    }, [storageStore.selectedType])

    const listBarcode = storageStore.selectedStorage?.barcodes ?? [{
        id: null,
        type: 0,
    }];
    const listPacks = storageStore.selectedStorage?.packs ?? [{
        id: null,
        type: 0,
    }];
    let [availablePacks, setAvailablePacks] = useState(storageStore.allPacks ?? []);

    const [nameError, setNameError] = useState(false);
    const [nameError2, setNameError2] = useState(false);
    const [visibleListColor, setVisibleListColor] = useState(false)
    const [locationError, setLocationError] = useState(false)
    const [visibleListIcon, setVisibleListIcon] = useState(false)
    const [visibleTreeLocation, setVisibleTreeLocation] = useState(false)
    const [treeLocation, setTreeLocation] = useState([])

    const [listIcon, setListIcon] = useState([])

    useEffect( ()=>{
        const requests = async () => {
            const listIcons = await getListIcon(mode.token);
            setListIcon(listIcons);
            setTreeLocation(await getStorages(mode.token, false, ''))
            await storageStore.setListOfTypesStorages(mode.token, listIcons);

        }
        requests()
    }, [storageStore]);

    useEffect(() => {
        const requests = async () => {
            let res = await getPackTypes(mode.token);
            setAvailablePacks(res);
        }
        requests()
    }, []);

    const startSave = async () => {
        if(name===''){
            setNameError(true)
        }
        else{
            if(!storageStore.selectedStorageId){
                const res =  await storageStore.createStorage(mode.token)
                if(res.success){
                    setWidget({status:'Ok',
                        text:'Хранилище успешно добавлено!',
                        fun:()=>{
                            storageStore.setSelectedStorage(mode.token, res.id)
                            mode.setType({mode:'view',item: storageStore.selectedStorage})
                            mode.setVisibleAdd(false);
                        }});
                }else{
                    if (res.error_code===2014){
                        setLocationError(true)
                    } else if (res.error_code===2015){
                        setNameError2(true)
                    } else {
                        setWidget({
                            status:'Error',
                            text:res.error_code,
                            fun:async ()=>{}
                        })
                    }
                }
            } else {
                setWidget({
                    status: 'Attention',
                    text: 'Данные хранилища будут изменены.',
                    fun: async () => {
                        const res = await storageStore.updateStorage(mode.token)

                        if (res.success) {
                            setWidget({status:'Ok',
                                text:'Хранилище успешно обновлено!',
                                fun:()=>{
                                    storageStore.clearSelectedStorage();
                                    storageStore.setSelectedStorage(mode.token, res.id)
                                    mode.setType({mode:'view',item: storageStore.selectedStorage})
                                    mode.setVisibleAdd(false);
                                }});
                        } else {
                            if (res.error_code === 2014) {
                                setLocationError(true)
                            } else {
                                setWidget({
                                    status: 'Error',
                                    text: res.error_code,
                                    fun: async () => {
                                    }
                                })
                            }
                        }
                    }
                })
            }
        }
    }

    const dataTree = {
        items: treeLocation,
        type: storageStore.selectedType ?? {id: 0, name:''},
        setLocation: (val) => {
            console.log(val);
            storageStore.changeStorageField('location', val)
        },
        setVisibleTreeLocation: setVisibleTreeLocation,
        initialItem: location.path
    }

    const dataIcons = {
        items: listIcon,
        selectIcon: selectIcon,
        setSelectIcon: (val) => storageStore.changeStorageField('icon', val),
        selectIconFile: selectIcon?.file
    }


    const renderPacks = <>
        <MultiplyDropdownList
            availableItems={ availablePacks }
            list={ listPacks }
            setWidget={setWidget}
            setList={(val) => storageStore.changeStorageField('packs', val)}
        />
    </>

    return (
        <div className={styles.wrapper}
             onClick={e=>{
                 setVisibleListColor(false);
                 setVisibleListIcon(false);
                 setVisibleTreeLocation(false)
             }}>
            <div className={styles.info}>
                <label className={styles.infoItem}>
                    <p className={styles.infoItemName}>Тип</p>
                    <input
                        className={styles.infoItemValue}
                        type="text"
                        placeholder=''
                        readOnly={true}
                        value={storageStore.selectedType?.name} />
                </label>
                <div className={styles.storageAddress}>
                    <p className={styles.infoItemName}>Расположение</p>

                    <DropdownComponent
                        items={dataTree?.items}
                        dataCustom={dataTree}
                        selectedKey={ location?.parent === 0 ?'/' : location?.parent}
                        keyItem={'parent'}
                        keyValue={'path'}
                        label={location?.parent === 0 ? '/' : location?.path}
                        disabled={false }
                        arrowColor={'#086C22'}
                        customComponent={'tree'}
                    />
                </div>
                <label className={styles.infoItem}>
                    <p className={styles.infoItemName}>Наименование</p>
                    <input
                        className={styles.infoItemValue +' ' +(nameError && styles.errorInput)}
                        type="text"
                        value={name}
                        onChange={ e => {
                            storageStore.changeStorageField('name', e.target.value);
                            setNameError(false);
                            setNameError2(false)
                        }}
                    />
                    {nameError &&
                        <div className={styles.dataErrorBlock}>
                            <img src={error} alt={'error'}/>
                            <div className={classes.dataError}>Поле обязательно для заполнения</div>
                        </div>}
                    {nameError2 &&
                        <div className={styles.dataErrorBlock}>
                            <img src={error} alt={'error'}/>
                            <div className={classes.dataError}>Поле должно быть уникальным</div>
                        </div>}
                </label>
                <div className={styles.barcode}>
                    <span className={styles.infoItemName}>Штрих-код</span>
                    <MultiInputList
                        list={listBarcode ?? ['']}
                        getItemsList={getListBarcodes}
                        setWidget={setWidget}
                        setList={(val)=>storageStore.changeStorageField('barcodes', val)}
                    />
                </div>
                {/*<div className={styles.barcode}>*/}
                {/*    <span className={styles.infoItemName}>Штативы</span>*/}
                {/*    { renderPacks }*/}

                {/*</div>*/}
                <label className={styles.infoItemIcon} style={{marginTop: '10px'}}>
                    <span className={styles.infoItemName}>Иконка</span>
                    <DropdownComponent
                        items={dataIcons?.items}
                        dataCustom={dataIcons}
                        label={''}
                        arrowColor={'#086C22'}
                        customComponent={'icons'}
                    />
                </label>
                <div className={styles.infoItem}>
                    <p className={styles.infoItemName} style={{marginRight:'14px'}}>Цвет</p>
                    <div style={{ width: '47px'}}>
                        <ColorPicker
                            selected={{color: selectColor}}
                            onChange={({color}) => {
                                storageStore.changeStorageField('color',color.slice(0,-2) )
                            }}
                            item={selectColor}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.description}>
                <p className={styles.infoItemNameAbout}>Описание</p>
                <textarea value={comment} onChange={e=>storageStore.changeStorageField('comment', e.target.value)} placeholder={'Описание хранилища'}></textarea>
            </div>
            {/*<button className={styles.changeshistory}>История изменений</button>*/}
            <div className={styles.functionalButtons}>
                <div className={styles.save} onClick={startSave}>Сохранить</div>

                <div className={styles.cancel} onClick={()=>{
                    storageStore.clearSelectedStorage();
                    mode.setType({mode:'view',item:mode.type.item})
                }}>
                    Отмена
                </div>
            </div>


        </div>
    );
});

export default WorkRightStorageAddGroup;
