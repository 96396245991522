import {makeAutoObservable, observable, runInAction, toJS} from "mobx";
import { createContext } from "react";
import { editSample, getSample } from "../utils/API/api_sample";
import {getStorage, getStoragePacks} from "../utils/API/api_storage";
import {getListPacks} from "../utils/API/api_list";
import dayjs from "dayjs";


class AppStore {

    constructor() {
        makeAutoObservable(this, {
            selectedSample: observable
        });
    }

    selectedSample = {};
    selectedSampleId = 0;
    samples = {};
    allPacks = [];
    availablePacks = [];
    selectedPack = {};

    getStore = () => {
        return {
            selectedSample: this.selectedSample,
            selectedSampleId: this.selectedSampleId,
            samples: this.samples,
            allPacks: this.allPacks,
            availablePacks: this.availablePacks,
            selectedPack: this.selectedPack,
        };
    }

    setStore = (data) => {
        this.selectedSample = data.selectedSample;
        this.selectedSampleId = data.selectedSampleId;
        this.samples = data.samples;
        this.allPacks = data.allPacks;
        this.availablePacks = data.availablePacks;
        this.selectedPack = data.selectedPack;
    }

    createSample = async (token) => {
        return await editSample(token, 0, {
            pack: this.selectedSample.pack,
            line: this.selectedSample.line,
            column: this.selectedSample.column,

            type: this.selectedSample.type,
            description: this.selectedSample.description,
            catalogs: this.selectedSample.catalogs,
            patient_description: this.selectedSample.patient_description,
            name: this.selectedSample.name ?? '',
            barcodes: JSON.stringify(this.selectedSample.barcodes),
            storage: this.selectedSample.storage,
            date: this.selectedSample.date,

            unit: this.selectedSample.unit,
            volume: this.selectedSample.volume,
        })
    }

    updateSample = async (token) => {
        // const dateCreate = `${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} `;

        return await editSample(token, this.selectedSampleId, {
            pack: this.selectedSample.pack,
            line: this.selectedSample.line,
            column: this.selectedSample.column,

            type: this.selectedSample.type,
            description: this.selectedSample.description,
            catalogs: JSON.stringify(this.selectedSample.catalogs),
            patient_description: JSON.stringify(this.selectedSample.patient_description),
            name: this.selectedSample.name ?? '',
            barcodes: JSON.stringify(this.selectedSample.barcodes),
            storage: this.selectedSample.storage,
            data_create: dayjs(this.selectedSample.date).format('YYYY-MM-DD HH:mm:ss'),

            unit: this.selectedSample.unit,
            volume: this.selectedSample.volume,
        })
    }

    setSelectedSample = async (token, id, view) => {

        this.selectedSampleId = id;
        const sample = await getSample(token, id, 'view')
        await this.setAvailablePacks(await getStoragePacks(token, sample.locations.id));
        let newSelectedSample = {};
        newSelectedSample = sample;
        const type = sample.type
        newSelectedSample.type = type.id
        newSelectedSample.selectedType = type

        newSelectedSample.unit = sample.volume_all.unit
        newSelectedSample.volume = sample.volume_all.amount

        newSelectedSample.storage = sample.locations.id
        newSelectedSample.location = sample.locations
        newSelectedSample.patient_description = sample.patient_description
        newSelectedSample.path = sample.locations?.path;
        newSelectedSample.pack = sample.locations?.pack.id;
        newSelectedSample.column = sample.locations?.pack.column;
        newSelectedSample.line = sample.locations?.pack.line;
        newSelectedSample.date = sample.date_create;
        newSelectedSample.barcodes = sample.barcodes?.reduce((acc, item) => {
            acc = [
                ...acc,
                {
                    id: item.type,
                    value: item.value,
                }
            ]
            return acc;
        }, []);
        this.setSelectedSampleAction(newSelectedSample)

        await this.changeSampleField('storage', sample.locations.id, token)
        await this.setSelectedPack(sample.locations?.pack.id)

    }

    setSelectedSampleAction = (sample) => {
        this.selectedSample = sample;
    }

    changeSampleField = async (name, val, token) => {
        if (name !== 'patient_description')
            this.selectedSample[name] = val

        if (name === 'location' && !!val) {

            this.selectedPack = {};
            this.selectedSample.line = '';
            this.selectedSample.column = '';

            await this.setAvailablePacks(await getStoragePacks(token, val.id))
            await new Promise(async (resolve) => {
                resolve(await getStorage(token, val.id))
            }).then((result) => {
                // this.selectedSample.location = result.location;
                this.selectedSample['storage'] = val.id
                this.selectedSample['path'] = `${result.location?.path} ${result.name}`;
            })
            await this.setAllPacks(token)
        }
        if (name === 'selectedType') {
            this.selectedSample.type = val.id
        }
        if (name === 'storage' && !!val) {
            this.selectedPack = {};
            const storage = await getStorage(token, val)
            this.selectedSample.location = storage.location;
            this.selectedSample.path = `${storage.location?.path} ${storage.name }`;
            await this.setAvailablePacks(await getStoragePacks(token, val))
            await this.setAllPacks(token)
        }
        if (name === 'catalogs') {
            this.selectedSample.catalogs = val;
        }

        if (name === 'patient_description') {

            if (!this.selectedSample.patient_description)
                this.selectedSample.patient_description = [];

            if (val === null) {
                this.selectedSample.patient_description = [];
                return;
            }

            let description = this.selectedSample.patient_description.find(i => i.id === val.id);

            if (description) {
                description.value = val.value;
            } else {
                this.selectedSample.patient_description.push(val);
            }
        }
        if (name === 'pack') {
            await this.setSelectedPack();
        }
        if (name === 'date') {
            this.selectedSample[name] = val[0].value;
        }
    }

    setEmptySample = () => {

        this.selectedSample = {
            pack: null,
            line: '',
            column: '',

            description: '',
            catalogs: [],
            patient_description: [],
            name: null,
            barcodes: [],
            storage: null,
            date: null,

            unit: null,
            volume: null,
        }
        this.selectedSampleId = 0;

    }

    setAllPacks = async (token) => {
        if (!this.allPacks.length) {
            this.allPacks = await getListPacks(token)
        }
    }

    setAvailablePacks = async (arr) => {

        this.availablePacks = arr;
    }

    setSelectedPack = async () => {
        let pack = this.availablePacks.find(pack => pack.id === this.selectedSample.pack);

        if (pack) {
            pack.columns = pack.structura.columns.reduce((acc, item) => {
                acc.push({
                    id: item,
                    name: item,
                });
                return acc
            }, []);

            pack.lines = pack.structura.lines.reduce((acc, item) => {
                acc.push({
                    id: item,
                    name: item,
                });
                return acc
            }, [])
        }

        this.selectedPack = pack;
    }

    clearSelectedSample = () => {
        this.selectedSample = {};
        this.selectedSampleId = 0;
        this.selectedPack = null;
    }

}

export const SamplesStore = createContext(new AppStore());
