import React, {useContext, useEffect, useState} from 'react';
import dropStyles from './workRightStorageAddMenuDropdownFirst.module.css'
import classes from "../../WorkRightUsersDir/WorkRightUserAddMenu/workRightUserAddMenu.module.css";
import closeSVG from '../../../../svg/close-01.svg'
import arrowSVG from '../../../../svg/arrow-01.svg'
import deleteSvG from '../../../../svg/delete-01.svg';
import {getStoragePacks, getStorages} from "../../../../utils/API/api_storage";
import {getListBarcodes, getListPacks, getListUnits, getPack, getTypeSamples} from "../../../../utils/API/api_list";
import Tree from "./Tree/tree";
import error from "../../../../svg/attention-01.svg";
import DropdownComponent from "../../../Widgets/Dropdown/DropdownComponent";
import {observer} from "mobx-react-lite";
import {SamplesStore} from "../../../../store/SamplesStore";
import MultiInputList from "../../../Widgets/MultiInputList";
import WidgetDateTimePicker from "../../../Widgets/WidgetDateTimePicker";
import {StorageStore} from "../../../../store/StorageStore";
import {address_server_short} from "../../../../config";
import {getCatalog, getCatalogs, getMappingCatalog} from "../../../../utils/API/api_catalog";
import MultiCatalogListItem from "../../../Widgets/MultiInputList/MultiCatalogListItem";
import {toJS} from "mobx";
import WorkRightAddMenuItem from "../../WorkRightManualDir/WorkRightAddMenu/workRightAddMenuItem";
import MultiSelectBoxes from "../../../Widgets/MultiSelectBox";
import {modeContext} from "../../../Contexts/contexts";
import {getFreePacks} from "../../../../utils/API/api_pucks";

const WorkRightStorageAddMenu = observer(({
  mode,
  selectItem,
  sizeX,
  visibleAdd,
  setVisibleAdd,
  setSelectItem,
  setWidget
}) => {


    const samplesStore = useContext(SamplesStore);
    const storageStore = useContext(StorageStore);

    const description = samplesStore.selectedSample?.description ?? '';
    const catalogs = samplesStore.selectedSample?.catalogs ?? '';
    const patient_description = samplesStore.selectedSample?.patient_description ?? '';
    const type = samplesStore.selectedSample?.type ?? '';
    const selectedType = samplesStore.selectedSample?.selectedType ?? false;
    const name = samplesStore.selectedSample?.name ?? '';
    const barcodes = samplesStore.selectedSample?.barcodes ?? [];
    const storage = samplesStore.selectedSample?.storage ?? 0;
    const location = samplesStore.selectedSample?.location ?? {};
    const unit = samplesStore.selectedSample?.unit ?? '';
    const volume = samplesStore.selectedSample?.volume ?? '';
    const date = samplesStore.selectedSample?.date ?? '';

    const column = samplesStore.selectedSample?.column ?? '';
    const line = samplesStore.selectedSample?.line ?? '';
    const pack = samplesStore.selectedSample?.pack ?? 0;


    const [treeLocation, setTreeLocation] = useState([])
    const [types, setTypes] = useState([])

    const [nameError, setNameError] = useState(false)
    const [typeError, setTypeError] = useState(false)
    const [volumeError, setVolumeError] = useState(false)
    const [locationError, setLocationError] = useState(false)
    const [descError, setDescError] = useState(false);
    const [dateError, setDateError] = useState(false)
    const [unitsList, setUnitsList] = useState([])

    const [isVisibleTypeDropdown, setIsVisibleTypeDropdown] = useState(false)
    const [isVisiblePositionDropdown, setIsVisiblePositionDropdown] = useState(false)

    const [catalogsDataSource, setCatalogsDataSource] = React.useState([]);
    const [patientDescriptions, setPatientDescriptions] = React.useState([]);
    const [packName, setPackName] = React.useState('');

    const loadCatalogs = async () => {
        let res = await getCatalogs(mode.token);
        setCatalogsDataSource(res);
    }

    useEffect(() => {
        const getSamplesT = async () => {
            setUnitsList(await getListUnits());
            const loc = await getStorages(mode.token, false, '');
            loc.push({
                id: undefined,
                type: 1,
                name: "Глобальное хранилище",
                icon: "icons/location-01.svg",
                color: "#ad1d1d",
                favorite: false,
                level: "0",
                parent: 0,
                nodes: []
            })

            setTreeLocation(loc);

            setTypes(await getTypeSamples(mode.token));
            await samplesStore.setAllPacks(mode.token)

            if (storage) {
                await samplesStore.setAvailablePacks(await getStoragePacks(mode.token, storage))
            }
            else {
                await samplesStore.setAvailablePacks(await getFreePacks(mode.token));
                samplesStore.changeSampleField('pack', samplesStore.selectedSample?.pack)
            }

            await loadCatalogs();
            await loadPack({id: samplesStore.selectedSample.storage});

            let patient = JSON.parse(JSON.stringify(patientDescriptions));
            if (samplesStore.selectedSample?.patient_description) {
                for(let i=0; i< samplesStore.selectedSample.patient_description.length; i++) {
                    let item = samplesStore.selectedSample.patient_description[i];


                    const newCatalog = await getMappingCatalog(mode.token, item.catalog);

                    let fields = item.fields.map(field => {return {id: field.field, value: field.value, setting: field.setting, files: field.files.map(i => {
                            let arr = i.split('/').reverse();
                            return arr[0];
                        }) ?? [] }});
                    patient.push({
                        id: item.catalog,
                        fields: fields,
                        catalog: newCatalog,
                    });
                    setPatientDescriptions(patient);
                }
            };
            await getPackByID();

            if (samplesStore.selectedSample.storage === undefined ||
              samplesStore.selectedSample.storage === null || samplesStore.selectedSample.storage === 0) {

                samplesStore.changeSampleField('path', 'Глобальное хранилище', mode.token)
            }
        }
        getSamplesT();
    }, []);

    async function startSave() {


        if (patientDescriptions.length > 0) {
            let catalogs = [];
            samplesStore.changeSampleField('patient_description',null);

            patientDescriptions.forEach(item => {
                 item.fields.forEach(i => {
                     catalogs.push({ id: item.id });
                     if (Object.keys(i.setting).length === 0)
                         samplesStore.changeSampleField('patient_description', {
                             id: i.id,
                             value: i.value
                         });
                     else
                        samplesStore.changeSampleField('patient_description', i);
                 });
            });
            samplesStore.changeSampleField('catalogs', catalogs);
        }


        let errors = 0

        if (!samplesStore.selectedSample.catalogs) {
            setNameError(true)
            errors++;
        } else {
            setNameError(false)
        }

        if (samplesStore.selectedSample?.volume === null || samplesStore.selectedSample?.volume <= 0) {
            setVolumeError(true)
            errors++;
        } else {
            setVolumeError(false)
        }
        if (!samplesStore.selectedSample.description) {
            setDescError(true)
            errors++;
        } else {
            setDescError(false)
        }

        if (!samplesStore.selectedSample.selectedType?.name) {
            setTypeError(true)
            errors++;
        } else {
            setTypeError(false)
        }

        if (samplesStore.selectedSample.storage !== undefined && samplesStore.selectedSample.storage !== null && samplesStore.selectedSample.storage !== 0) {
            if (!samplesStore.selectedSample.storage) {
                setLocationError(true)
                errors++;
            } else {
                setLocationError(false)
            }
        } else {
            samplesStore.selectedSample.storage = 0;
        }

        if (!samplesStore.selectedSample.date) {
            setDateError(true)
            errors++;
        } else {
            setDateError(false)
        }

        if (!errors) {
            if (!!samplesStore.selectedSampleId) {
                setWidget({
                    status: 'Attention',
                    text: 'Данные образца будут изменены',
                    fun: async () => {
                        const res = await samplesStore.updateSample(mode.token);
                        if (res.success) {
                            setWidget({
                                status: 'Ok',
                                text: 'Образец успешно сохранён!',
                                fun: async () => {
                                    if (samplesStore.selectedSample.storage === null
                                      || samplesStore.selectedSample.storage === undefined
                                      || samplesStore.selectedSample.storage === 0) {
                                        await storageStore.setSelectedStorageId(0);
                                        samplesStore.clearSelectedSample();
                                        await storageStore.refreshSamples(mode.token, 'list', pack);
                                    } else
                                    {
                                        samplesStore.clearSelectedSample();
                                        await storageStore.refreshSamples(mode.token);
                                    }
                                    mode.setVisibleAdd(false);
                                    mode.setType({mode: 'view', item: ''});
                                }
                            });
                        } else {
                            setWidget({
                                status: parseInt(res.error_code) === 2016 ? 'SimpleError' : 'Error',
                                text: parseInt(res.error_code) === 2016 ? "Данная ячейка уже занята. Поместите образец в другое место хранения" : res.error_code,
                                fun: async () => {
                                }
                            })
                        }
                    }
                });
            } else {
                const res = await samplesStore.updateSample(mode.token);

                if (res.success) {
                    setWidget({
                        status: 'Ok',
                        text: 'Образец успешно добавлен!',
                        fun: async () => {
                            if (samplesStore.selectedSample.storage === null
                              || samplesStore.selectedSample.storage === undefined
                              | samplesStore.selectedSample.storage === 0) {
                                samplesStore.clearSelectedSample();
                                await storageStore.refreshSamples(mode.token, 'list', pack);
                            } else
                            {
                                samplesStore.clearSelectedSample();
                                await storageStore.refreshSamples(mode.token);
                            }
                            mode.setVisibleAdd(false);
                            mode.setType({mode: 'view', item: ''});

                        }
                    });
                } else {
                    setWidget({
                        status: parseInt(res.error_code) === 2016 ? 'SimpleError' : 'Error',
                        text: parseInt(res.error_code) === 2016 ? "Данная ячейка уже занята. Поместите образец в другое место хранения" : res.error_code,
                        fun: async () => {
                        }
                    });
                }
                let sample = JSON.parse(JSON.stringify(samplesStore.selectedSample));

                if (samplesStore.selectedSampleId === 0 && mode.selectedCell.length !== 0) {
                    const cells = mode.selectedCell;
                    for(let i=0; i<cells.length; i++) {
                        let cell = cells[i];
                        if (cell.line === sample.line  && cell.storageId === sample.storage
                          && cell.packId === sample.pack  && cell.column === sample.column) continue;

                        samplesStore.changeSampleField('column',  cell.column, mode.token);
                        samplesStore.changeSampleField('line', cell.line, mode.token);
                        samplesStore.changeSampleField('storage', cell.storageId, mode.token);
                        samplesStore.changeSampleField('pack', cell.packId, mode.token);
                        samplesStore.changeSampleField('barcodes',[], mode.token);


                        const resGroup = await samplesStore.updateSample(mode.token);

                    }
                    mode.setSelectedCell([]);
                }
            }

        }
    }

    const [availablePacks, setAvailablePacks] = React.useState([]);


    const loadPack = async (val) => {
            if (val) {
                if (val?.id === undefined || val?.id === null) {

                    let res = await getFreePacks(mode.token);
                    await samplesStore.setAvailablePacks(res);
                    setAvailablePacks(res);
                    return;
                }

                let res = await getListPacks(mode.token, val.id);

                setAvailablePacks(res);
            } else {

                let res = await getListPacks(mode.token, samplesStore.selectedSample.storage);

                setAvailablePacks(res);
            }
        if (val?.id === undefined || val?.id === null) {
            let res = await getFreePacks(mode.token);
            await samplesStore.setAvailablePacks(res);
            setAvailablePacks(res);
        }

    }

    const dataTree = {
        items: treeLocation,
        // type: storageStore.selectedType ?? {id: 0, name:''},
        setLocation: (val) => samplesStore.changeSampleField('location', val.id, mode.token),
        // setVisibleTreeLocation: setVisibleTreeLocation,
        initialItem: location?.path ?? ''
    }

    const getPackByID = async () => {
     let res =  await getPack(mode.token, pack);

        setPackName(res.name)
    }
    return (
        <div className={mode.path === 'Хранилище' ? dropStyles.addMenu_notBack : dropStyles.addMenu} style={{
            width: 'calc(100% - 3px - ' + sizeX + 'px',
            opacity: visibleAdd ? 1 : 0,
            zIndex: visibleAdd ? 4 : -1
        }}>
            <div className={dropStyles.addMenu_block}>
                <div className={dropStyles.title}>
                    <div>Образец</div>
                    <button onClick={() => {
                        samplesStore.clearSelectedSample()
                        setVisibleAdd(false)
                    }} className={dropStyles.closeButton}><img src={closeSVG} alt=""/></button>
                </div>
                <div className={dropStyles.info}>
                    {
                        (samplesStore.selectedSampleId === 0 && mode.selectedCell.length !== 0) &&
                      <div className={dropStyles.infoItem} style={{marginBottom: '8px'}}>
                          <p className={dropStyles.infoItemName}>Групповое создание</p>
                          <div className={dropStyles.infoItemInput} style={{alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                                Количество: {mode.selectedCell.length}
                              <button className={dropStyles.infoItemArrow}
                                      onClick={() => mode.setSelectedCell([])}>
                                  <img src={deleteSvG} alt=""/>
                              </button>

                          </div>

                      </div>

                    }
                    <div className={dropStyles.infoItem} style={{marginBottom: '8px'}}>
                        <p className={dropStyles.infoItemName}>Тип</p>
                        <div className={dropStyles.infoItemInput}>
                            <input
                                className={dropStyles.infoItemValue}
                                value={samplesStore.selectedSample.selectedType?.name ?? ''}
                                readOnly={true}
                                onClick={() => {
                                    setIsVisibleTypeDropdown(!isVisibleTypeDropdown)
                                }}/>
                            <button className={dropStyles.infoItemArrow}
                                    onClick={() => setIsVisibleTypeDropdown(!isVisibleTypeDropdown)}><img src={arrowSVG}
                                                                                                          alt=""/>
                            </button>
                            {isVisibleTypeDropdown &&
                                <>
                                    <div className={dropStyles.DropdownBlockAll}
                                         onClick={() => setIsVisibleTypeDropdown(false)}></div>
                                    <div className={dropStyles.DropdownBlock}>
                                        {types.map(item =>
                                            <div className={dropStyles.DropdownItem} onClick={() => {
                                                setIsVisibleTypeDropdown(false);
                                                samplesStore.changeSampleField('selectedType', item)
                                                setTypeError(false)
                                            }}>
                                                {item.name}
                                            </div>
                                        )}
                                    </div>
                                </>}
                            {typeError &&
                                <div className={classes.dataErrorBlock}>
                                    <img src={error} alt={'error'}/>
                                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                                </div>}
                        </div>
                        {(selectedType && selectedType !== {}) ?
                            <div className={dropStyles.infoItemType} style={{
                                background: selectedType?.color ?? '#FFFFFF',
                                backgroundImage: `url(${address_server_short}/${selectedType?.icons ?? selectedType?.icon}) center no-repeat`
                            }}> {selectedType?.symbol ?? selectedType?.code} </div>
                            : <div className={dropStyles.infoItemType} style={{opacity: 0}}></div>
                        }

                    </div>
                    <div className={dropStyles.infoItem}>
                        <p className={dropStyles.infoItemName}>Наименование</p>
                        <div className={dropStyles.infoItemInput}>
                            <input
                                className={dropStyles.infoItemValue + ' ' + (nameError && dropStyles.errorInput)}
                                value={name}
                                onChange={e => {
                                    samplesStore.changeSampleField('name', e.target.value);
                                }}/>
                        </div>
                    </div>


                      <div className={dropStyles.infoItem}>
                          <p className={dropStyles.infoItemName}>Расположение</p>
                          <div className={dropStyles.infoItemInput}>
                              <input
                                className={dropStyles.infoItemValue}
                                value={samplesStore.selectedSample.path}
                                readOnly={true}
                                onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}
                              />
                              <button className={dropStyles.infoItemArrow}
                                      onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}><img
                                src={arrowSVG} alt=""/></button>
                              {isVisiblePositionDropdown &&
                                <>
                                    <div className={dropStyles.DropdownBlockAll}
                                         onClick={() => setIsVisiblePositionDropdown(false)}></div>
                                    <div className={dropStyles.TreeBlock}>
                                        <Tree
                                          storages={treeLocation}
                                          setVisibleTreeLocation={setIsVisiblePositionDropdown}
                                          setLocation={(val) => {
                                              console.log(val);
                                              if (val.id !== undefined) {
                                                  samplesStore.changeSampleField('location', val, mode.token);
                                                  loadPack(val);
                                                  setLocationError(false);
                                              } else {
                                                  samplesStore.changeSampleField('path', 'Глобальное хранилище', mode.token);
                                                  loadPack(val);
                                                  setLocationError(false);
                                              }


                                          }}/>
                                    </div>
                                </>}
                              {locationError &&
                                <div className={classes.dataErrorBlock}>
                                    <img src={error} alt={'error'}/>
                                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                                </div>}
                          </div>
                      </div>
                    {availablePacks.length ? <>
                        <div className={dropStyles.infoItem}>
                            <p className={dropStyles.infoItemName}>Штатив</p>

                            <DropdownComponent
                                key={'packs_1'}
                                items={availablePacks}
                                selectedKey={pack}
                                onChange={(val) => samplesStore.changeSampleField('pack', val)}
                            />
                        </div>
                        <div className={dropStyles.infoItem} style={{marginBottom: "30px"}}>
                            <p className={dropStyles.infoItemName}>Место хранения</p>
                            <div style={{width: '200px', display: 'flex', alignItems: 'center', gap: '10px'}}>
                                <DropdownComponent
                                    key={'line_1'}
                                    items={samplesStore.selectedPack?.lines ?? []}
                                    selectedKey={line ?? null}
                                    disabled={!pack}
                                    onChange={(val) => samplesStore.changeSampleField('line', val)}
                                />
                                <DropdownComponent
                                    key={'column_1'}
                                    items={samplesStore.selectedPack?.columns ?? []}
                                    selectedKey={column ?? null}
                                    disabled={!pack}
                                    onChange={(val) => samplesStore.changeSampleField('column', val)}
                                />
                            </div>
                        </div>
                    </> : <></>
                    }

                    <div className={dropStyles.infoItem}>
                        <p className={dropStyles.infoItemName}>Штрих-коды</p>
                        <MultiInputList
                            list={barcodes ?? ['']}
                            getItemsList={getListBarcodes}
                            setWidget={setWidget}
                            setList={(val) => samplesStore.changeSampleField('barcodes', val)}
                        />
                    </div>

                    <div className={dropStyles.infoItem}>
                        <p className={dropStyles.infoItemName}>Объем</p>
                        <div className={dropStyles.infoItemInput}>
                            <input
                                className={dropStyles.infoItemValue}
                                value={volume}
                                type={'number'}
                                onChange={(e) => {
                                    samplesStore.changeSampleField('volume', e.target.value)
                                    setVolumeError(false)
                                }}
                            />
                            {volumeError &&
                                <div className={classes.dataErrorBlock}>
                                    <img src={error} alt={'error'}/>
                                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                                </div>}
                        </div>

                    </div>
                    <div className={dropStyles.infoItem}>
                        <p className={dropStyles.infoItemName}>Единицы измерения</p>
                        <div className={dropStyles.infoItemInput}>
                            <DropdownComponent
                                items={unitsList}
                                selectedKey={unit}
                                valueItem="code"
                                label={"Выберите единицы измерения..."}
                                onChange={(val) => samplesStore.changeSampleField('unit', val)}
                            />

                        </div>
                    </div>
                    <div className={dropStyles.infoItem}>
                        <p className={dropStyles.infoItemName}>Дата забора материала</p>
                        <div className={dropStyles.infoItemInput}>

                            <WidgetDateTimePicker
                              selected={date}
                              onChange={(val) => {
                                  setDateError(false);
                                  samplesStore.changeSampleField('date', val)
                              }}
                              isDateTime={true}
                            />
                            {dateError &&
                              <div className={classes.dataErrorBlock}>
                                  <img src={error} alt={'error'}/>
                                  <div className={classes.dataError}>Поле обязательно для заполнения</div>
                              </div>}
                        </div>

                    </div>
                    <div className={dropStyles.description}>
                        <div>Описание</div>
                        <textarea className={dropStyles.textarea} placeholder={'Описание'} value={description}
                                  onChange={(e) => {
                                      setDescError(false);
                                      samplesStore.changeSampleField('description', e.target.value)
                                  }}></textarea>

                        {descError &&
                            <div className={classes.dataErrorBlock}>
                                <img src={error} alt={'error'}/>
                                <div className={classes.dataError}>Поле обязательно для заполнения</div>
                            </div>
                        }
                    </div>
                    <div>
                        <div className={dropStyles.infoItem}>
                            <div className={dropStyles.infoItemName}>Описание пациента</div>

                        </div>
                        <MultiSelectBoxes dataSource={catalogsDataSource}
                                          result={patientDescriptions}
                                          setWidget={setWidget}
                                          mode={mode}
                                          showDependentElements={true}
                                          setResult={setPatientDescriptions}/>
                    </div>
                    <div className={dropStyles.functionalButtons}>
                        <div className={dropStyles.save} onClick={startSave}>Сохранить</div>
                        <div className={dropStyles.cancel} onClick={() => {
                            samplesStore.clearSelectedSample();
                            setVisibleAdd(false)
                        }}>Отмена
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default WorkRightStorageAddMenu;

