import React, {useEffect, useState} from 'react';
import classes from "./authorization.module.css";
import logo from "../../svg/logo-01.svg";
import logo2 from "../../svg/logo_corner-01.svg";
import error from "../../svg/attention-01.svg";
import pass2 from "../../svg/eye_open-01.svg";
import pass from "../../svg/eye_closed-01.svg";
import ForgetPassword from "./fortgetPassword";
import {getListEmail, login, verificationRecovery} from "../../utils/API/api_auth";
import { ReactComponent as Arrow } from "../../svg/arrow-01.svg";
import NewPassword from "./newPassword";


const Authorization = ({setLoginMenu, setToken}) => {
    const [forgetMenuActive, setForgetMenuActive] = useState(false)
    const [errorPassword, setErrorPassword] = useState(false)
    const [errorToken, setErrorToken] = useState(false)
    const [newPassword, setNewPassword] = useState(false)
    const [tokenRecovery, setTokenRecovery] = useState('')
    const [passOpen, setPassOpen] = useState(false)
    const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false)
    const [loginValue, setLoginValue] = useState("")

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailList, setEmailList] = useState([])

    useEffect(()=>{
        const getEmailList = async() =>{
            const res = await getListEmail();
            let t = []
            for(let i=0; i<res.length; i++){
                let tt = res[i].users.sort(function (a, b) {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                });
                t.push({group:res[i].group,users:tt})
            }
            setEmailList(t);
        }
        getEmailList();
        },[]);

    async function startLogin(){
        const status = await login(email,password);
        if(!status){
            setErrorPassword(true);
            setPassword('');
        }else {
            setToken(status);
            setLoginMenu(false);
        }
    }

    useEffect(()=>{
        const recoveryToken = async () =>{
            let params = window.location.search.replace('?','').split('&')
                .reduce(
                    function(p,e){
                        let a = e.split('=');
                        p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                        return p;
                    },
                    {}
                );
            const res = await verificationRecovery(params['token']);
            if(res.success){
                setTokenRecovery(res.result)
                setNewPassword(true)
            }else{
                setErrorToken(true);
                setForgetMenuActive(true);
            }
        }
        if(window.location.pathname === '/recovery'){
            recoveryToken();
        }
    },[])

    const twoFunct = (event, login) => {
        setLoginValue(login.name)
        setEmail(login.login)
        setIsLoginDropdownOpen(!isLoginDropdownOpen)
    }

    return (
        <div className={classes.LoginBlock} onClick={()=>setIsLoginDropdownOpen(false)}>
            <div className={classes.Login + ' ' + (errorPassword && classes.LoginErrorWight)} >
                <img src={logo} alt={'Logo'} className={classes.LoginLogoTop}/>
                <div className={classes.LoginForm}>
                    <div className={classes.LoginInputBlock2}>
                        <div style={{margin:'0 0 0 12px'}}>
                            {emailList.length === 0?
                                <div className={classes.LoginInputBlock}>
                                        <div className={classes.LoginText}>Email</div>
                                        <input className={classes.LoginInput} type={"email"} value={email} onChange={(e)=>setEmail(e.target.value.replace(/[^[a-zA-Z0-9@.()/?<>!#$%^&*{}\d]/ig, ""))}/>
                                </div> :
                                <div className={classes.LoginInputBlock} onClick={(e)=>e.stopPropagation()}>
                                    <div className={classes.LoginText}>Логин</div>
                                    <form>
                                        <input className={classes.LoginInput } autoComplete={'off'}  name="loginInput" id="loginInput" type="text" value={loginValue} onClick={()=>{setIsLoginDropdownOpen(!isLoginDropdownOpen)}}/>
                                    </form>
                                    <Arrow className={classes.arrowImg} onClick={() => setIsLoginDropdownOpen(!isLoginDropdownOpen)}></Arrow>
                                    {
                                        isLoginDropdownOpen ? 
                                        <div className={classes.LoginDropdown} value={email} onChange={(e)=>{setEmail(e.target.value)}}>
                                        {emailList.map((item)=>
                                            <div className={classes.dropdownGroup} label={item.group} key={item.group}>
                                                <p className={classes.dropdownGroupName}>{item.group}</p>
                                                {item.users.map((item2)=>
                                                    <p value={item2.login} key={item2.login} className={classes.dropdownItemName} onClick={event => twoFunct(event, item2)}>
                                                        {item2.name}
                                                    </p>)
                                                }
                                            </div>)}
                                        </div> 
                                        : null
                                    }
                                    
                                </div>
                            }
                            <div className={classes.LoginInputBlock}>
                                <div className={classes.LoginText}>Пароль</div>
                                <input className={classes.LoginInput + ' ' + (errorPassword && classes.LoginInputError)} autoComplete={passOpen ? 'off' : 'new-password'}  name="passwordInput" id="passwordInput"  type={passOpen ? "text" : "password"} value={password} onChange={(e)=>setPassword(e.target.value)} onKeyDown={(e)=>{if(e.key==='Enter')startLogin()}}/>
                            </div>
                        </div>
                        {passOpen ?
                            <img onClick={()=>setPassOpen(false)} src={pass2} alt={'pass'} className={classes.LoginButtonPass}/> :
                            <img onClick={()=>setPassOpen(true)}  src={pass} alt={'pass'} className={classes.LoginButtonPass}/>
                        }
                    </div>
                    <div className={classes.LoginButtonForget} onClick={()=>setForgetMenuActive(true)}>Забыли пароль?</div>
                    {errorPassword &&
                        <div className={classes.LoginError}>
                            <img src={error} alt={'error'}/>
                            <div className={classes.LoginErrorText}>Пароль неверный, попробуйте еще раз или обратитесь к администратору.</div>
                        </div>}
                    <button className={classes.LoginButton} onClick={()=>startLogin()}>Вход</button>
                </div>
                <img src={logo2} alt={'logo'} className={classes.LoginLogo}/>
            </div>
            {forgetMenuActive && <ForgetPassword setForgetMenuActive={setForgetMenuActive} errorToken={errorToken} />}
            {newPassword && <NewPassword setNewPassword={setNewPassword} tokenRecovery={tokenRecovery} />}
        </div>
    );
};

export default Authorization;
