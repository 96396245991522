import React, {useRef} from 'react';
import classes from './ModalPackSelect.module.css';
import Icon from "../../../helpers/components/Icon";
import styles from "../../Widgets/Modal/modal.module.css";
import close from "../../../svg/close-01.svg";
import magic from '../../../svg/magic.svg';
import DropdownComponent from "../../Widgets/Dropdown/DropdownComponent";
import dropStyles
  from "../../WorkRight/WorkRightStorageDir/WorkRightStorageAddMenu/workRightStorageAddMenuDropdownFirst.module.css";
import MultiInputList from "../../Widgets/MultiInputList";
import {getListBarcodes} from "../../../utils/API/api_list";
import {deletePackType, getFreePacks, getPackTypes} from "../../../utils/API/api_pucks";

const ModalPackSelect = ({
   openModal,
   mode,
   action,
   onClose,
   setWidget,
   storageId = 0
}) => {
  const selfRef = useRef(null);
  const modalBGRef = useRef(null);
  const [packs, setPacks] = React.useState([]);
  const [selectedKey, setSelectedKey] = React.useState();
  const [name, setName] = React.useState('');
  const [barcodes, setBarcodes] = React.useState([]);

  React.useEffect(() => {
    loadFreePack();
  }, []);

  React.useEffect(() => {
    loadFreePack();
  }, [mode.puckAddModal]);

  React.useEffect(() => {
    if (name.length === 0) {
      let pack = packs.find(i => i.id === selectedKey);
      if (pack) {
        setName(pack.name);
      }
    }

  }, [selectedKey]);
  const loadFreePack = async () => {
    let res = await getPackTypes(mode.token);
    if (res.length === 0) {
      setWidget({
        status: 'Error',
        text: 'Нет свободных штативов!',
        fun: async () => { }
      });
      return;
    }

    setPacks(res);

  }

  const closeModalTappedOnBg = (ev) => {
    if (!selfRef.current.contains(ev.target) && selfRef.current !== ev.target) {
      onClose();
    }
  }

  const deleteType = async (id) => {
      const res = await deletePackType(mode.token, id);

      if (res.success) {
        setSelectedKey(null);
        setName('');
        await loadFreePack();
        setWidget({
          status: 'Info',
          text: 'Тип штатива удален.',
          fun: async () => { }
        });
      } else {
        setWidget({
          status: 'Error',
          text: 'Тип штатива уже используется!',
          fun: async () => { }
        });
      }
  }

  const modal = <>
    <div ref={modalBGRef}
         onClick={(ev) => closeModalTappedOnBg(ev)}
         className={styles.modalBG} style={{zIndex: 300}}>
      <div className={styles.model_copy}
           ref={selfRef} >
        <div className={styles.header}>

          <div className={styles.closeButton}
               onClick={() => onClose()}>
            <Icon color={'#1d6030'}
                  width={'15px'}
                  height={'15px'}
                  src={close}/>
          </div>
        </div>
        <div className={styles.body}  style={{margin: '10px 20px'}}>
          <div className={styles.title}>
            Добавить штатив
          </div>

          <div className={classes.infoItem}>
            <p className={classes.infoItemName} style={{width: '160px'}}>Наименование</p>
            <input className={classes.infoItemValue}
                   type="text"
                   value={name}
                   onChange={ e => {setName(e.target.value)}}/>
          </div>

          <div className={classes.infoItem}>
            <p className={classes.infoItemName} style={{width: '160px'}}>Тип штатива</p>
            <DropdownComponent key={'packs_1'}
                               items={packs}
                               keyItem={'id'}
                               valueItem={'name'}
                               selectedKey={selectedKey}
                               isDelete={true}
                               onDeleteClick={deleteType}
                               deleteMessageConfirm={'Вы уверены что хотите удалить тип штатива?'}
                               onChange={(val) => setSelectedKey(val)} />
            <div className={classes.addNewPuckButton} onClick={() => mode.setPuckAddModal(true)}>
              <Icon color={'#1d6030'}
                    width={'25px'}
                    height={'25px'}
                    src={magic}/>
            </div>
          </div>


          <div className={dropStyles.infoItem}>
            <p className={dropStyles.infoItemName}>Штрих-коды</p>
            <MultiInputList list={barcodes}
                            getItemsList={getListBarcodes}
                            setWidget={setWidget}
                            setList={(val) => setBarcodes(val)} />
          </div>

        </div>
        <div className={styles.footer}>
          <button className={`button_default save_style`} onClick={() =>
            action({ storageId: storageId, barcodes: barcodes, type: selectedKey, name: name})
          }>Сохранить</button>
          <button className={`button_default cancel_style`} onClick={() => onClose()}>Отмена</button>
        </div>
      </div>
    </div>
  </>

  return openModal ? modal : <></>;
};

export default ModalPackSelect;
