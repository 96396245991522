import React from 'react';
import classes from "./tree.module.css";
import TreeItem from "./treeItem";

const Tree = ({storages, setLocation, setVisibleTreeLocation}) => {
    return (
        <div>
            <div style={{fontSize:'12pt'}}>
                {storages.map(item=>
                    <TreeItem path={''} key={item.id} value={item} visibleTop={true} last={true} first={true} setLocation={setLocation} setVisibleTreeLocation={setVisibleTreeLocation} />
                )}
            </div>
        </div>
    );
};

export default Tree;