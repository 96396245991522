import React, { useEffect, useState } from 'react';
import classes from "./workRightReportsAddMenu.module.css";
import str from "../../../../svg/arrow-01.svg"
import close from "../../../../svg/close-01.svg";
import skrepka from "../../../../svg/screpka.svg";
import Magic from "../../../../png/magic_stick.png";
import { editReport, getReport, getReportsGroups } from "../../../../utils/API/api_report";
import { getSections } from "../../../../utils/API/api_list";
import WorkRightReportsAddMenuSectionItem from "./workRightReportsAddMenuSectionItem";
import error from "../../../../svg/attention-01.svg";
import classes2 from "../../WorkRightManualDir/WorkRightAddMenu/workRightAddMenu.module.css";
import WidgetFileLoader from '../../../Widgets/WidgetFileLoader';
import DropdownComponent from "../../../Widgets/Dropdown/DropdownComponent";

const statusList = [
    {id: 0, name: 'Неактивен'},
    {id: 1, name: 'Активен'},
]

const WorkRightReportsAddMenu = ({ sizeX, mode, selectItem, setSelectItem, setWidget }) => {
    const [name, setName] = useState('')
    const [id, setId] = useState(selectItem.id)
    const [errorName, setErrorName] = useState(false)
    const [errorName2, setErrorName2] = useState(false)
    const [errorFile, setErrorFile] = useState(false)
    const [listGroup, setListGroup] = useState([])
    const [group, setGroup] = useState({ id: 0, name: '' })
    const [listSections, setListSections] = useState([])
    const [comment, setComment] = useState('')
    const [status, setStatus] = useState(selectItem.status ?? 0)
    const [file, setFile] = useState('')

    useEffect(() => {

        const getReport_t = async () => {
            const groups = await getReportsGroups(mode.token)
            setListGroup(groups);
            const sections = await getSections(mode.token)
            let temp = [];

            if (selectItem.id !== 0) {
                const res = await getReport(mode.token, selectItem.id);

                setName(res.name);
                if (mode.copyMode) {
                    setId(0)
                    setName('')
                    mode.setCopyMode(false)
                }
                setComment(res.comments);
                setStatus(res.status);

                if (res.file.includes('/')) {
                    let arr = res.file.split('/').reverse();
                    setFile(arr[0]);
                } else {
                    setFile(res.file);
                }

                groups.forEach(item => {
                    if (item.id === res.group) setGroup(item);
                })
                sections.forEach(item => {
                    let flag = true;
                    for (let i = 0; i < res.sections.length; i++) {
                        if (res.sections[i].id === item.id) {
                            temp.push({ id: item.id, name: item.name, value: true });
                            flag = false;
                            break;
                        }
                    }
                    if (flag)
                        temp.push({ id: item.id, name: item.name, value: false });
                })
            } else {
                sections.forEach(item => {
                    temp.push({ id: item.id, name: item.name, value: false });
                })
                setGroup(groups[0]);
                groups.forEach(item => {
                    if (item.id === mode.type.item.id) setGroup(item);
                })
            }
            setListSections(temp);

        }
        getReport_t();
    }, [])

    function getSectionsValue() {
        let t = [];
        listSections.forEach(item => {
            if (item.value)
                t.push(item.id)
        })
        return t;
    }

    async function startSaveReports() {
        if (file === '' || file === undefined || file === 'undefined') {
            setWidget({
                status: 'Alarm',
                text: 'Добавьте файл',
                fun: async () => { }
            })
            return;
        }
        if (name === '' || file === '') {
            if (name === '')
                setErrorName(true)
            if (file === '')
                setErrorFile(true)
        } else {
            if (id === 0) {
                const res = await editReport(mode.token, 0, {
                    group: group.id,
                    name: name,
                    status: status,
                    comments: comment,
                    file: file,
                    sections: getSectionsValue()
                });
                if (res.success) {
                    setWidget({
                        status: 'Ok',
                        text: 'Отчет успешно добавлен!',
                        fun: async () => {
                            setSelectItem({ id: 0, fields: [], group: mode.type.item.id });
                            mode.setType({ mode: 'view', item: mode.type.item })
                            mode.setVisibleAdd(false);
                        }
                    });
                } else {
                    if (res.error_code === 2015) {
                        setErrorName2(true)
                    } else {
                        setWidget({
                            status: 'Error',
                            text: res.error_code,
                            fun: async () => { }
                        })
                    }
                }
            }
            else
                setWidget({
                    status: 'Attention',
                    text: 'Данные отчета будут изменены.',
                    fun: async () => {
                        const res = await editReport(mode.token, selectItem.id, {
                            group: group.id,
                            name: name,
                            status: status,
                            comments: comment,
                            file: file,
                            sections: getSectionsValue()
                        });
                        if (res.success) {
                            setSelectItem({ id: 0, fields: [] });
                            mode.setType({ mode: 'view', item: mode.type.item })
                            mode.setVisibleAdd(false);
                        } else {
                            if (res.error_code === 2015) {
                                setErrorName2(true)
                            } else {
                                setWidget({
                                    status: 'Error',
                                    text: res.error_code,
                                    fun: async () => { }
                                })
                            }
                        }

                    }
                })
        }

    }

    return (
        <div className={classes.workRightReportsAddMenu} style={{ width: 'calc(100% - 3px - ' + sizeX + 'px', position: 'absolute' }}>
            <div className={classes.block1}>
                <div className={classes.block1_text}>Наименование</div>
                <input className={classes.block1_input + ' ' + ((errorName || errorName2) && classes2.InputError)} value={name} onChange={(e) => { setName(e.target.value); setErrorName(false); setErrorName2(false) }} />
                {errorName && <div className={classes.errorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={classes.LoginErrorText2}>Поле обязательно для заполнения</div>
                </div>}
                {errorName2 && <div className={classes.errorBlock}>
                    <img src={error} alt={'error'} />
                    <div className={classes.LoginErrorText2}>Поле должно быть уникальным</div>
                </div>}
            </div>
            <div className={classes.selectReports}>
                <span className={classes.block1_text}>Группа</span>
                <DropdownComponent
                    items={ listGroup }
                    label={'Группа...'}
                    selectedKey={ group ? group.id : null }
                    arrowColor={'#086C22'}
                    onChange={() => listGroup.map(item =>
                        setGroup({ id: item.id, name: item.name })
                    )}
                />
            </div>
            <div className={classes.selectSections}>
                <span className={classes.block1_text}>Разделы</span>
                <DropdownComponent
                    items={ listSections }
                    label={''}
                    arrowColor={'#086C22'}
                    customComponent={'checkbox'}
                />
            </div>
            <div className={classes.block3}>
                <div>Комментарии</div>
                <textarea className={classes.textArea} placeholder={'Комментарий'} value={comment} onChange={e => setComment(e.target.value)} ></textarea>
            </div>

            {/* TODO DELETE this comment */}
            {/* <div className={classes.block4} onDrop={e => { setFile(e.dataTransfer.files[0].name); document.getElementById('inputFile').files = e.dataTransfer.files }}>
                <div className={classes.block4_text1}>
                    <img src={skrepka} />
                    Перетащите файл отчета сюда или выберете файл
                </div>
                <input hidden={true} id={'inputFile'} type="file" name="file" accept="file/*" onChange={(e) => { setFile(e.target.files[0].name); setErrorFile(false) }} />
                <label htmlFor="inputFile" className={classes.block4_button}> Выбрать файл </label>
                {errorFile && <div className={classes.errorBlock2}>
                    <img src={error} alt={'error'} />
                    <div className={classes.LoginErrorText2}>Добавьте файл отчета</div>
                </div>}
            </div>
            {file !== '' && <div className={classes.block5}>
                <div className={classes.block5_block}>
                    <div className={classes.block5_fileBlock}>
                        <div className={classes.file}>{file.split('/').length > 1 ? file.split('/')[file.split('/').length - 1] : file}</div>
                        <img className={classes.delete_file} src={close} alt={'close'} onClick={() => setFile('')} />
                    </div>
                    <div className={classes.block5_button}>
                        <img src={Magic} />
                        Открыть в редакторе
                    </div>
                </div>
            </div>} */}

            <div className={classes.container}>
                <WidgetFileLoader
                    onLoadFile={(files) => {
                        setFile(files?.[0]) }}
                    listFile={[file]}
                    title="Перетащите файл отчета сюда или выберите файл"
                    isError={errorFile}
                />
            </div>
            <div className={classes.statusReports}>
                <span>Статус</span>
                <DropdownComponent
                    items={ statusList }
                    label={'Статус...'}
                    selectedKey={ status }
                    arrowColor={'#086C22'}
                    onChange={setStatus}
                />
            </div>
            <div className={classes.block7}>
                <div className={classes.button_save} onClick={startSaveReports}
                >Сохранить</div>
                <div className={classes.button_cancel} onClick={() => { mode.setVisibleAdd(false); setSelectItem({ id: 0 }) }}>Отмена</div>
            </div>
        </div>
    );
};

export default WorkRightReportsAddMenu;
