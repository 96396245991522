import React, {useContext, useRef} from 'react';
import Tree, {ItemTypes} from "../../Tree/tree";
import {observer} from "mobx-react-lite";
import {StorageStore} from "../../../store/StorageStore";
import {useDrop} from "react-dnd";
import classes from "../../Tree/tree.module.css";
import {packMove, storageMove} from "../../../utils/API/api_storage";

const WorkLeftStorage = observer(({setWidget, storages, mode, favorite, refreshTree}) => {
    const storageStore = useContext(StorageStore)
    const treeRef = useRef(null)
    const clearFocus = (ev) => {
        if (!treeRef.current?.contains(ev.target) && mode.type.mode !== "edit" ) {
            mode.setType({mode: '', item: ''});
            storageStore.clearSelectedStorage();
        }
    }

    const [collectedProps, drop] = useDrop(() => ({
    accept: [ItemTypes.STORAGE_TYPE, ItemTypes.PACK_TYPE],
      drop: (item, monitor) => {
        if (item.type === ItemTypes.STORAGE_TYPE) {
          storageMove(mode.token, item.id, 0).then(res => {
            if (res.success) {
              refreshTree();
            }
          });
        }

        if (item.type === ItemTypes.PACK_TYPE) {
          packMove(mode.token, item.id, 0).then(res => {
            if (res.success) {
                refreshTree();
            }
          });
        }
      }
    }));

    return (
        <div
            onClick={clearFocus}
            style={{
                height:'100%',
                overflow:'auto',
                padding: '10px 0 0 30px'
            }}
        >
          {

           mode.isDragging &&  <div ref={drop} className={classes.dropZone}> </div>
          }



          {favorite ?
            <Tree refreshTree={refreshTree} innerRef={treeRef} mode={mode} storages={storages} favorite={favorite} setWidget={setWidget}/>
            :
            <Tree refreshTree={refreshTree} innerRef={treeRef} mode={mode} storages={storages} favorite={favorite} setWidget={setWidget}/>
          }
        </div>
    );
});

/* <div ref={provided.innerRef} {...provided.droppableProps}>
                    {
                      favorite ?
                        <Tree innerRef={treeRef} mode={mode} storages={storages} favorite={favorite}/>
                        :
                        <Tree innerRef={treeRef} mode={mode} storages={storages} favorite={favorite}/>
                    }
                  </div>*/

export default WorkLeftStorage;
