import React, {useContext, useEffect, useState} from 'react';
import classes from "./tree.module.css";
import strelka from '../../svg/arrow-01.svg'
import classes2 from "../WorkRight/WorkRightCommon/WorkRight/workRight.module.css";
import {deleteStorage, packMove, setFavoriteStorage, storageMove} from "../../utils/API/api_storage";
import {address_server_short, COLORS} from "../../config";
import {modeContext} from "../Contexts/contexts";
import {StorageStore} from "../../store/StorageStore";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import TreeItemPack from "./treeItemPack";
import {getListPacks} from "../../utils/API/api_list";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {useDrag, useDrop} from "react-dnd";
import {ItemTypes} from "./tree";

const rowsButton = {
    print: false,
    copy: false,
    edit: true,
    delete: true,
    favorite: true
}
const styleVisible = [
    {fontSize: '12pt'},
    {fontSize: '0pt'}
]
const styleName = [
    {padding: '0 0 0 0'},
    {}
]
const styleVisibleSVG = [
    {height: '16px'},
    {height: '0'}
]
const styleBlock = [
    {},
    {height: '0', width: '0', opacity: '0'}
]
const styleStrelka = [
    [{height: '8px', transform: 'rotate(-90deg)'}, {height: '8px'}],
    [{height: '0', margin: '0 0 0 0'}, {height: '0', margin: '0 0 0 0'}]
]

const TreeItem = observer(({path, mode, value, visibleTop, last, first, favorite, refreshTree, setWidget}) => {

    const {widget} = useContext(modeContext);
    const storageStore = useContext(StorageStore);
    const [visible, setVisible] = useState(false);
    const [packs, setPacks] = useState([]);
    const [isHover, setIsHover] = useState(false);

    const [{isDragging},drag] = useDrag(() => ({
        type: ItemTypes.STORAGE_TYPE,
        item: {id: value.id, name: value.name, type: ItemTypes.STORAGE_TYPE },
        collect: monitor => ({isDragging: !!monitor.isDragging()}),
    }));

    const [collectedProps, drop] = useDrop(() => ({
        accept: [ItemTypes.STORAGE_TYPE, ItemTypes.PACK_TYPE],
        drop: (item, monitor) => {
             if (item.type === ItemTypes.STORAGE_TYPE) {
                storageMove(mode.token, item.id, value.id).then(res => {
                    if (res.success) {
                        refreshTree();
                    }
                });
             }

             if (item.type === ItemTypes.PACK_TYPE) {
                 packMove(mode.token, item.id, value.id).then(res => {
                     if (res.success) {
                         refreshTree();
                     }
                 });
             }

            new Promise(async (resolve) => {
                storageStore.clearPuck();

                if (value.id === undefined) value.id = 0;
                storageStore.setSelectedStorageId(value.id);
                await storageStore.refreshSamples(mode.token)
                storageStore.setListOfTypesStorages(mode.token);
                storageStore.setSelectedStorageWithNodes(mode.token, value);
                await storageStore.setSelectedType(mode.token, value.id);
                value.pack = storageStore.storagePacks[value.id];
                resolve();
            }).then(res => {
                mode.setType({mode: 'view', item: {id: value.id, name: (path + ' / ' + value.name).slice(3)}});
                mode.setVisibleAdd(false);
            })
        }
    }));

    useEffect(() => {
        mode.setIsDragging(isDragging);
    }, [isDragging])

    useEffect(() => {

        if (!value.id) return;
        const g = async () => {
            let r =  await getListPacks(mode.token, value.id);
            setPacks(r);
        }

        g();
    }, [value])

    useEffect(() => {
        if (!visibleTop) {
            if (!favorite) {
                setVisible(false)
            } else {
                setVisible(checkFavoriteChildren(value))
            }
        }
    }, [visibleTop])

    const styleHoverBlock = {
        color: isHover ? COLORS.color_brand : value.color,
    }

    const editOpen = async () => {
        await new Promise(async (resolve) => {
            await storageStore.setSelectedStorage(mode.token, value.id)
            storageStore.setListOfTypesStorages(mode.token)
            resolve(storageStore.selectedStorage)
        }).then((item) => {
            mode.setType({mode:'edit',item});
        })
    }

    const checkFavoriteChildren = (storage) => {
        if (storage.favorite) {
            return false;
        } else {
            return storage.nodes.reduce((acc, item) => {
                if (item.favorite) {
                    return true
                } else if (!acc) {
                    return checkFavoriteChildren(item.nodes)
                }
            }, false)
        }
    }


    const active = storageStore.selectedStorageId === value.id && storageStore.packClickId === 0;

    const [favoriteTemp, setFavoriteTemp] = useState(value.favorite)

    return (
          <div className={classes.Item}>
              {!first && <div className={classes.Line} style={styleBlock[visibleTop ? 0 : 1]}></div>}
              {!last && <div className={classes.Line2} style={styleBlock[visibleTop ? 0 : 1]}></div>}

              <div ref={drag} onClick={async () => {
                  new Promise(async (resolve) => {
                      storageStore.setSelectedStorageId(value.id);
                      storageStore.setListOfTypesStorages(mode.token);
                      storageStore.setSelectedStorageWithNodes(mode.token, value);
                      await storageStore.setSelectedType(mode.token, value.id);
                      await storageStore.refreshSamples(mode.token)
                      value.pack = storageStore.storagePacks[value.id];
                      resolve();
                  }).then(res => {
                      storageStore.clearPuck();
                      mode.setType({mode: 'view', item: {id: value.id, name: (path + ' / ' + value.name).slice(3)}});
                      mode.setVisibleAdd(false);
                  })

                  // setCookie('storageId', value.id);
                  // setCookie('storageType', value.type);
                  // setCookie('storagePath', path.slice(3) + ' / ' + value.name)
              }} className={classes.ItemName}>
                  {(value.nodes && value.nodes.length === 0 && packs && packs.length === 0) ? <div style={{width: '18px'}}></div> :
                    <img className={classes.Strelka} style={styleStrelka[visibleTop ? 0 : 1][visible ? 1 : 0]}
                         src={strelka} alt={''} onClick={() => {

                        setVisible(!visible)
                    }}/>}
                  <div
                    className={classes.ItemNameBlock + ' ' + (active && classes.ItemNameSelect)}
                    style={styleName[visibleTop ? 1 : 0]}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                  >

                      {value.icon &&
                        <img alt={''} className={classes.SVG} src={address_server_short + value.icon
                        }
                             style={styleVisibleSVG[visibleTop ? 0 : 1]}/>}
                      <div style={styleHoverBlock} onClick={() => { setVisible(!visible) }}
                           className={classes.ItemNameText}>
                          <div>
                              {value.name}
                          </div>


                      </div>
                      {visibleTop && <div className={classes.InvBlock}></div>}
                      {visibleTop && <div className={classes.Block} onClick={(e) => {
                          e.stopPropagation()
                      }}>
                          {(rowsButton.favorite && !favoriteTemp) && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_favorite + ' ' + (active && classes.ButtonActive)}
                            onClick={() => {
                                setFavoriteStorage(mode.token, value.id, 'true');
                                setFavoriteTemp(true)
                            }}></div>}
                          {(rowsButton.favorite && favoriteTemp) && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_favorite_active + ' ' + (active && classes.ButtonActive)}
                            onClick={() => {
                                setFavoriteStorage(mode.token, value.id, false);
                                setFavoriteTemp(false)
                            }}></div>}
                          {rowsButton.print && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_print + ' ' + (active && classes.ButtonActive)}></div>}
                          {rowsButton.copy && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_copy + ' ' + (active && classes.ButtonActive)}></div>}
                          {rowsButton.edit && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_edit + ' ' + (active && classes.ButtonActive)}
                            onClick={editOpen}></div>}
                          {rowsButton.delete && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_delete + ' ' + (active && classes.ButtonActive)}
                            onClick={() => {
                                widget.setWidget({
                                    status: 'Sure',
                                    text: 'Вы действительно хотите удалить хранилище?',
                                    fun: async () => {
                                        const res = await deleteStorage(mode.token, value.id);
                                        if (res.success) {
                                            mode.setType({mode: 'view', item: ''});
                                        } else {
                                            if (res.error_code === 2008) {
                                                widget.setWidget({
                                                    status: 'Info',
                                                    text: 'Хранилище не пустое',
                                                    fun: async () => {
                                                    }
                                                })
                                            }
                                        }
                                    }
                                })
                            }}
                          ></div>}

                      </div>}
                  </div>
              </div>
              {
                visibleTop && mode.isDragging &&  <div ref={drop} className={classes.dropZone}> </div>
              }

              {
                  <div style={!visible ? {display: 'none'} : {display: 'block'}}>
                      {
                          packs.map((item, i, arr) =>
                            <TreeItemPack path={path + ' / ' + value.name}
                                          key={item.id}
                                          mode={mode}
                                          value={item}
                                          setWidget={setWidget}
                                          visibleTop={visible}
                                          last={i === arr.length - 1}
                                          first={false}

                                          storageId={value.id}
                                          storage={value}/>)
                      }
                  </div>

              }
              {(value.nodes && value.nodes.length > 0) &&

                <div style={styleVisible[visible ? 0 : 1]}>
                    {value.nodes.map((item, i, arr) =>
                      <TreeItem path={path + ' / ' + value.name}
                                key={item.id}
                                mode={mode}
                                setWidget={setWidget}
                                value={item}
                                refreshTree={refreshTree}
                                visibleTop={visible}
                                last={i === arr.length - 1}
                                first={false}/>)
                    }

                </div>
              }
          </div>
    );
});

export default TreeItem;
