import React from 'react';
import styles from './Import.module.css';
import DropdownComponent from "../../../Widgets/Dropdown/DropdownComponent";
import WidgetFileLoader from "../../../Widgets/WidgetFileLoader";
import {parseFile, saveData, saveDataAfterCasted} from "../../../../utils/API/api_report";
import classes from "../../../WorkRight/WorkRightReportsDir/WorkRightReportsAddMenu/workRightReportsAddMenu.module.css";
import confirmSVG from "../../../../svg/button_on-01.svg";
import DropdownComponentSecondaryStyle from "../../../Widgets/Dropdown/DropdownComponentSecondaryStyle";
import {COLORS} from "../../../../config";
import Preloader from "../../../Widgets/Preloader/Preloader";
import PreloaderBox from "../../../Widgets/PreloaderBox/PreloaderBox";


const importDataTypes = [{id: 1, name: 'Образцы'}, {id: 2, name: 'Справочники'}, {id: 3, name: 'Хранилища'}]
const modelType = [{
  typeId: 1,
  fields: [
    {id: 'empty', name: '', isRequired: false},
    {id: 'name', name: 'Наименование', isRequired: false},
    {id: 'type', name: 'Тип*', isRequired: true},
    {id: 'description', name: 'Описание'},
    {id: 'barcodes', name: 'Штрих-код', isRequired: false},
    {id: 'volume', name: 'Объем*', isRequired: true},
    {id: 'unit', name: 'Единицы измерения*', isRequired: true},
    {id: 'date_create', name: 'Дата забора материала*', isRequired: true},
  ]},
  {
    typeId: 2,
    fields: [
      {id: 'empty', name: ''},
      {id: 'fields', name: 'Поле'},
    ]},
  {
    typeId: 3,
    fields: [
      {id: 'empty', name: ''},
      {id: 'name', name: 'Наименование'},
      {id: 'location', name: 'Расположение'},
      {id: 'barcodes', name: 'Штрих-коды'},
      {id: 'packs', name: 'Штативы'},
      {id: 'icon', name: 'Иконка'},
      {id: 'color', name: 'Цвет'},
      {id: 'description', name: 'Описание'},
    ]
  }
]

const ImportModule = ({
  setWidget,
  mode
}) => {

  const [files, setFiles] = React.useState([]);
  const [typeId, setTypeId] = React.useState(1);

  const [loadData, setLoadData] = React.useState([]);
  const [uploadData, steUplodaData] = React.useState([]);

  const [countSelectedRequiredFields, setCountSelectedRequriderFields] = React.useState(0);

  const ref = React.useRef(null);
  const [preloader, setPreloader] = React.useState(false);
  const [parsePreloader, setParsePreloader] = React.useState(false);

  const [columns, setColumns] = React.useState([]);
  let controller = new AbortController();

  React.useEffect(() => {
    const fields = [];

    columns.forEach((column) => {
      const value = column[Object.keys(column)[0]]
      if (value && value.length > 0)
        fields.push(value);
    });

    const modelFields = modelType[0].fields.filter(i => i.isRequired).map(i => i.id);

    let count = 0;
    fields.forEach( i=> {
      if (modelFields.includes(i)) {
        count++
      }
    });
    setCountSelectedRequriderFields(count);
  }, [columns]);

  const preloadData = () => {
    setParsePreloader(true);
    parseFile(mode.token, {files: files}, controller.signal).then(res => {
      const data = [];
      res.result.forEach(i => {
        i.data.forEach(j => {
          data.push(...j);
        });
      });

      setParsePreloader(false);
      setLoadData(data);

      const load = data[0];
      if (load && Object.keys(load).length > 0) {
        const col = [];
        Object.keys(load).forEach((item, index) => {
          if (item !== 'selected')
            col.push({[item]: ''})
        });
        setColumns(col);
      }
    });
  }

  const saveData = () => {
    if (countSelectedRequiredFields !== 4) {
      setWidget({
        status: 'Alarm',
        text: 'Заполните все обязательные поля',
        fun: async () => { }
      });
      return;
    }

    const getSelectedData = loadData.filter(i => i['selected']);
    const cols = columns.filter(i => i[Object.keys(i)[0]] !== '');
    const castedData = [];
    let isError = false;

    getSelectedData.forEach((item, index) => {
        const newObj = {};
        cols.forEach(col => {
          const fieldName = Object.keys(col)[0];
          if (fieldName) {
            newObj[col[fieldName]] = item[fieldName];
          }
        });
      const splitArr = item['Position'].split('/');

      if (splitArr.length > 1) {
        newObj['line'] = splitArr[0].trim();
        newObj['column'] = splitArr[1].trim();
      } else {
        isError = true;
        setWidget({
          status: 'Alarm',
          text: 'Отредактируйте файл (Position)',
          fun: async () => { }
        });
      }

      let level = 0;
      newObj['storage'] = [];

      if (item['Freezer']) {
        newObj['storage'].push({parent: level, name: item['Freezer']});
        level++;
      }

      if (item['Level1']) {
        newObj['storage'].push({parent: level, name: item['Level1']});
        level++;
      }

      if (item['Level2']) {
        newObj['storage'].push({parent: level, name: item['Level2']});
        level++;
      }
      if (item['Level3']) {
        newObj['storage'].push({parent: level, name: item['Level3']});
        level++;
      }

      if (item['Level4']) {
        newObj['storage'].push({parent: level, name: item['Level4']});
        level++;
      }

      if (item['Level5']) {
        newObj['storage'].push({parent: level, name: item['Level5']})
        level++;
      }

      if (item['Box']) {
        newObj['storage'].push({parent: level, name: item['Box']})
      }
      castedData.push(newObj);
    });

    if (isError) return;

    if (castedData.length > 0) {
      setPreloader(true);
      saveDataAfterCasted(mode.token, castedData).then(res => {
        if (res.success)
        {

          setColumns([]);
          setLoadData([]);
          setWidget({
            status: 'Info',
            text: 'Данные загружены',
            fun: async () => { }
          });
        } else {
          setWidget({
            status: 'Error',
            text: res.error_code === 1001 ? 'Неправильная модель данных' : 'Произошла ошибка при загрузке',
            fun: async () => { }
          });
        }
        setFiles([]);
        setPreloader(false);
      })
        .catch(err => {
          setWidget({
            status: 'Error',
            text: 'Произошла ошибка при загрузке',
            fun: async () => { }
          });
          setPreloader(false);
        });
    }

  }

  const isUsedField = (value) => {
    let isUsed = false;

    columns.forEach((item) => {
      if (item[Object.keys(item)[0]] === value) {
        isUsed = true;
      }
    });

    return isUsed;
  }

  const headerRef = React.useRef(null);
  const onScrollEvent = (e) => {
    const x = e.currentTarget.scrollLeft;
    if (headerRef) {
      headerRef.current.scrollLeft = x;
    }
  }

  return (
    <div className={styles.container}>
        <div className={styles.body}>
          <p className={styles.title}>Импорт</p>

          <div className={styles.control_body}>

            <div className={styles.infoItem_column}>
              <WidgetFileLoader onLoadFile={(files) => {
                                  if (files.length === 0) {
                                    setFiles([]);
                                    setLoadData([]);
                                    setColumns([]);
                                  }
                                  setFiles(files);
                                }}
                                listFile={files}
                                title="Перетащите файл отчета сюда или выберите файл"
                                isError={false} />
            </div>

            {/*<div className={styles.infoItem}>*/}
            {/*  <p className={styles.infoItemName + ' ' + styles.w200px }>Данные для загрузки</p>*/}

            {/*  <DropdownComponent key={'exportDataTypes'}*/}
            {/*                     items={importDataTypes}*/}
            {/*                     selectedKey={typeId}*/}
            {/*                     onChange={(val) => {setTypeId(val)}} />*/}
            {/*</div>*/}


            <div className={styles.footer}>
              <button className={`button_default save_style`}
                      onClick={preloadData}
                      style={{width: 'auto', fontSize: '17px', padding: '20px 25px'}}>
                Прочитать данные</button>

              <button className={`button_default cancel_style`}
                      onClick={() => {
                        setFiles([]);
                        setLoadData([]);
                        setColumns([]);
                        setParsePreloader(false);
                        controller.abort();
                      }}
                      style={{width: 'auto', fontSize: '17px', padding: '20px 25px'}}> Отмена</button>
            </div>

          </div>
        </div>

      {
        parsePreloader && <div style={{height: '200px'}}>
          <PreloaderBox/>
        </div>
      }

      {
        loadData.length > 0 &&
        <div className={styles.loadDataContainer}>
          <span className={styles.span}>Всего найдено: {loadData.length}</span>
          <span className={styles.span}>Заполнено обязательных полей {countSelectedRequiredFields}/4</span>

          <div className={styles.header} ref={headerRef} style={{ margin: '10px',
            gridTemplateColumns: `repeat(${columns.length + 1}, 200px)`}}>
            <div className={styles.rowItem} style={{marginLeft: '20px'}}>
              <div className={classes.checkbox}
                   onClick={(e) => {
                     const newData = [...loadData];
                     newData.forEach(i => {
                       i['selected'] = !ref.current.checked;
                     })
                     setLoadData(newData);
                   }}>
                <img src={confirmSVG}
                     alt="arrow-icon"
                     className={classes.checkboxArrow}
                     style={{ top: '29%',
                       width: '28px',
                       height: '15px',
                       left: '0%', }}/>
                <input key={0}
                       type="checkbox"
                       ref={ref}
                       className={classes.checkboxSquare}
                       checked={loadData.filter(i => i['selected'] === true).length > 0}/>
              </div>
              Выбрать все
              {/*<DropdownComponentSecondaryStyle key={'exportDataTypes'}*/}
              {/*                                 items={importDataTypes}*/}
              {/*                                 selectedKey={typeId}*/}
              {/*                                 arrowColor={COLORS.color_brand}*/}
              {/*                                 onChange={(val) => {setTypeId(val)}} />*/}

            </div>
            {
              columns.map((column, index) =>
                <div className={styles.rowItem}>
                  <DropdownComponentSecondaryStyle key={'exportDataTypes'}
                                                   items={modelType.find(i => i.typeId === typeId).fields ?? []}
                                                   selectedKey={column[Object.keys(column)[0]]}
                                                   label={'Укажите поле для загрузки'}
                                                   arrowColor={COLORS.color_brand}
                                                   onChange={(val) => {
                                                     if (isUsedField(val)) return;

                                                     let upColimns = [...columns];
                                                     if (val === 'empty')
                                                       upColimns[index][Object.keys(upColimns[index])[0]] = undefined;
                                                     else
                                                       upColimns[index][Object.keys(upColimns[index])[0]] = val;

                                                     setColumns(upColimns);
                                                   }} />
                </div>
              )
            }
          </div>
          <div className={styles.table} onScroll={onScrollEvent}>
              <div className={styles.bodyTable + ' ' + styles.row} style={{
                margin: '10px',
                width: `${(columns.length + 1) * 220}px`,
                gridTemplateColumns: `repeat(${columns.length + 1}, 200px)`}}>
                {
                  loadData.map((data, i) =>
                    <div className={styles.row + ' ' + (data['selected'] ? styles.selected : ' ')}
                         style={{width: `${(columns.length + 1) * 219.5}px`,
                           gridTemplateColumns: `repeat(${columns.length + 1},200px)`}}>
                      <div className={styles.rowItem}>
                        <div className={classes.checkbox }
                             onClick={(e) => {
                               const newData = [...loadData];
                               newData[i]['selected'] = !newData[i]['selected'];
                               setLoadData(newData);
                             }}>
                          <img src={confirmSVG}
                               alt="arrow-icon"
                               className={classes.checkboxArrow}
                               style={{ top: '29%',
                                 width: '28px',
                                 height: '15px',
                                 left: '0%', }}/>
                          <input key={0}
                                 type="checkbox"
                                 className={classes.checkboxSquare}
                                 checked={data['selected']}/>
                        </div>
                      </div>
                      {
                        columns.map((column, j) =>
                          <div className={styles.rowItem}>
                            {data[Object.keys(column)[0]]}
                          </div>
                        )
                      }
                    </div>)
                }
              </div>

          </div>

          <button className={`button_default save_style`}
                  onClick={saveData}
                  style={{width: '250px', marginTop: '20px', marginLeft: '100px',fontSize: '17px', padding: '20px 25px'}}>
            Начать загрузку данных</button>
        </div>
      }
      {
        preloader && <Preloader/>
      }
    </div>
  );
}

export default ImportModule;
