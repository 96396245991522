import {address_server} from "../../config";
import {error} from "../api";
import moment from "moment";
import {transliterate} from "../../helpers/utils";

export async function getGroups(token){
    const res = await (await fetch(address_server+'/user/groups?token='+token)).json()
    if(res.success){
        return res.result;
    }
    error('getGroups',res);
    return []
}

export async function getUsers(token, group, page, limit, search, sorting){
    let res;
    let url = address_server+'/user/users?token='+token+'&mode=table&group='+group+'&offset='+(page-1)*limit;
    if(search)
        url += '&search='+search
    if(sorting.field)
        url += '&sorting='+JSON.stringify([{'field':sorting.field,'type':sorting.type}])
    if(group)
        res = await (await fetch(url)).json()
    else
        res = await (await fetch(address_server+'/user/users?token='+token+'&mode=table')).json()
    if(res.success){
        return res.result
    }
    error('getUsers',res);
    return []
}
export async function getAllUsers(token){
    const url = address_server+'/user/users?token='+token+'&mode=list';
    const res = await (await fetch(url)).json()
    if(res.success){
        return res.result.groups.reduce((acc, item) => {
            acc = [
                ...acc,
                ...item?.users
            ]
            return acc;
        }, [])
    }
    error('getUsers',res);
    return []
}

export async function newGroup(token, group, newName){
    let res;
    if(group !== 0)
        res = await (await fetch(address_server+'/user/group',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:token,id:group,name:newName})})).json()
    else
        res = await (await fetch(address_server+'/user/group',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:token,name:newName})})).json()
    return res
    //error('newGroup',res);
}

export async function deleteGroup(token, group){
    let res = await (await fetch(address_server+'/user/group/delete',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token:token,id:group})})).json()
    return res
    //if(res.success){
    //    return res
    //}
    //error('deleteGroup',res);
    //return []
}

export async function getProfile(token, id){
    let res = ''
    if(id!==0)
        res = await (await fetch(address_server+'/user/profile?token='+token+'&id='+id)).json()
    else
        res = await (await fetch(address_server+'/user/profile?token='+token)).json()
    if(res.success){
        return res.result;
    }
    error('getProfile',res);
    return []
}

export async function editProfile(token, id, params){

    let formData = new FormData();
    formData.append('token', token);
    formData.append('group', params.group);
    formData.append('fio', params.fio);
    formData.append('email', params.email);
    formData.append('status', params.status);
    formData.append('position', params.post);
    formData.append('birth', moment(params.birth).format('DD.MM.YYYY'));
    formData.append('comments', params.comments?params.comments:'');
    formData.append('rules', JSON.stringify(params.rules));
    formData.append('reports', JSON.stringify(params.reports));

    if(params.password !== '')
        formData.append('password', params.password);
    if(document.getElementById('inputAvatar').files.length===0){
        formData.append('avatar', params.avatar)
    }
    else
    {
        formData.append('avatar', document.getElementById('inputAvatar').files[0])
    }

    let res;
    if(id!==0){
        formData.append('id', id);
        res = await (await fetch(address_server + '/user/profile', {
            method: 'POST',
            body: formData
        })).json()
    }
    else{
        res = await (await fetch(address_server+'/user/profile',{
            method:'POST',
            body: formData
        })).json()
    }
    return res
    //error('editProfile',res);
    //return []
}

export async function editProfileShort(token, params){
    let birth = ''
    if(params.birth !== '')
        birth = params.birth.split('-')[2] + '.' + params.birth.split('-')[1] + '.' + params.birth.split('-')[0]
    let formData = new FormData();
    formData.append('token', token);
    formData.append('fio', params.fio);
    //formData.append('position', params.position);
    formData.append('birth', birth);
    //formData.append('email', params.email);
    if(document.getElementById('inputAvatar').files.length===0){
        if(params.avatar === '') {
            formData.append('avatar', '')
        }
    }
    else
        formData.append('avatar', document.getElementById('inputAvatar').files[0])
    //formData.append('id', id);
    let res = await (await fetch(address_server + '/user/profile', {
        method: 'POST',
        body: formData
    })).json()
    return res
}

export async function rePassword(token, password, newPassword){
    let res = await (await fetch(address_server+'/user/re-password',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token:token,password:password,new_password:newPassword})})).json()

    if(res.success){
        return res
    }
    error('rePassword',res);
    return false
}

export async function deleteUser(token, id){
    let res = await (await fetch(address_server+'/user/delete',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token:token,id:id})})).json()

    if(res.success){
        return res
    }
    error('deleteUser',res);
    return []
}

export async function getUserReports(token,section){
    let res = await (await fetch(address_server+'/user/reports?token='+token+'&section='+section)).json()
    if(res.success){
        return res.result;
    }
    error('getUserReports',res);
    return []
}


export async function sendSupportMessage(token, params){
    console.log(params);
    let formData = new FormData();
    formData.append('token', token);
    formData.append('name', params.name);
    formData.append('email', params.email);
    formData.append('theme', params.theme);

    if (params.files.length > 0) {
        params.files?.map((file, index) => {
            formData.append('files', file, transliterate(file.name));
        });
    }


    formData.append('message', params.message);

    let res = await (await fetch(address_server + '/support', {
        method: 'POST',
        body: formData
    })).json();

    return res;
}
