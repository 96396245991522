import {address_server} from "../../config";
import {error} from "../api";
import {transliterate} from "../../helpers/utils";

export async function getSamples(token, storage,mode, filter, limit, page, sorting, pack = 0){

    let url = address_server+'/sample/samples?token='+token+'&mode='+mode //+'&limit='+limit
    if(storage !== undefined)
        url += '&storage='+storage
    if(pack !== 0)
        url += '&pack='+pack
    if(filter !== '')
        url += '&search='+filter
    if(limit)
        url += '&offset='+(page-1)*limit
    if(sorting?.field)
        url += '&sorting='+JSON.stringify([{'field':sorting.field,'type':sorting.type}])
    // if (storageId !== 0) {
    //     url += '&storageId='+storageId
    // }

    let res = await (await fetch(url)).json()
    if(res.success){
        return res.result;
    }
    error('getSamples',res);
    return []
}

export async function getSamples2(token, storage = 0,mode, filter, limit, page, sorting, pack = 0){


    let url = address_server+'/sample/samples?token='+token+'&mode='+mode //+'&limit='+limit
    if(storage !== undefined && storage != 0)
        url += '&storage='+storage
    if(pack !== 0)
        url += '&pack='+pack
    if(filter !== '')
        url += '&search='+filter
    if(limit)
        url += '&offset='+(page-1)*limit
    if(sorting?.field)
        url += '&sorting='+JSON.stringify([{'field':sorting.field,'type':sorting.type}])
    // if (storageId !== 0) {
    //     url += '&storageId='+storageId
    // }

    let res = await (await fetch(url)).json()
    if(res.success){
        return res.result;
    }
    error('getSamples',res);
    return []
}

export async function getSample(token,id,mode){
    let res = await (await fetch(address_server+'/sample?token='+token+'&id='+id+'&mode='+mode)).json()
    if(res.success){
        return res.result;
    }
    error('getSample',res);
    return []
}

export async function editSample(token, id, params){
    let res = '';
    const formData = new FormData();
    formData.append('token', token);
    if (params.name.length !== 0 )
        formData.append('name', params.name);
    formData.append('type', params.type);
    formData.append('storage', params.storage);
    formData.append('line', params.line);
    formData.append('column', params.column);
    formData.append('pack', params.pack);
    formData.append('description', params.description);
    formData.append('patient_description', params.patient_description);
    formData.append('barcodes', params.barcodes);
    formData.append('volume', params.volume===''?0:params.volume);
    formData.append('unit', params.unit);
    formData.append('catalogs', params.catalogs);

    formData.append('date_create', params.data_create);
    if (id) {
        formData.append('id', id);
    }
    res = await (await fetch(address_server+'/sample', {
        method: 'POST',
        body: formData
    })).json();

    if(res.success){
        return res
    }
    error('editSample',res);
    return res
}

export async function deleteSample(token, id){
    let res = await (await fetch(address_server+'/sample/delete',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token:token,id:id})})).json()

    if(res.success){
        return res
    }
    error('deleteSample',res);
    return []
}
export async function moveSample(token, id, settings = {}){

    const formData = new FormData();
    formData.append("token", token);
    formData.append("id", settings.id);
    formData.append("volume", settings.amount);
    if (settings.locations.id)
        formData.append("new_storage", settings.locations.id);
    else
        formData.append("new_storage", 0);
    formData.append("new_pack", settings.pack);
    formData.append("column", settings.column);
    formData.append("line", settings.line);

    return (await fetch(address_server+'/sample/move', {
        method: 'POST',
        body: formData,
    })).json();
}
