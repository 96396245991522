import {SketchPicker} from "react-color";
import styles from "./css/ColorPicker.module.css";
import {useEffect, useRef, useState} from "react";
import "./css/style.css";
import Icon from "../../../helpers/components/Icon";
import {ArrowDownIcon} from "../../../helpers/icons";
import classes from "../PeriodTimePicker/css/PeriodTimePicker.module.css";
import chroma from "chroma-js";
import useOutsideClick from "../../../helpers/utils";
import {COLORS} from "../../../config";

const ColorPicker = ({selected = {color: '#000000'}, onChange, item}) => {

    const [value, setValue] = useState({color: {}});
    const [displayPicker, setDisplayPicker] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, setDisplayPicker);

    useEffect(() => {
        if ((selected?.color?.length > 6 && selected?.color?.length < 10) && Object.keys(value?.color).length === 0) {
            const alphaFromRGBA = selected?.color?.length > 7
                ? selected?.color.substring(selected?.color.length - 2)
                : 'ff';
            const alpha = hexToAlpha(alphaFromRGBA.toUpperCase());

            const RGBColor = selected?.color?.length > 7
                ? selected?.color.substring(0, selected?.color.length - 2)
                : selected?.color.substring(0, selected?.color.length);

            const newValue = hexToRgbA(RGBColor, alpha);
            setValue({color: newValue})
        }
    }, [selected]);

    useEffect(() => {
        const colorStr = `${value?.color.r},${value?.color.g},${value?.color.b},${value?.color.a}`;
        const HexColor = RGBAToHexA(colorStr);

        onChange({color: HexColor});
    }, [value]);

    useEffect(() => {
        const picker = document.querySelector('.hue-horizontal div:last-child > div');
        const pickerRGBA = document.querySelector
        ('.flexbox-fix:nth-child(2) > div:first-child > div:last-child > div > div > div div');
        if (picker) {
            const pickerContainer = document.querySelector('.hue-horizontal div:last-child');
            if (pickerContainer) {
                let stylesContainer = pickerContainer.style.cssText;
                let splitStyles = stylesContainer.split(';');
                let positionContainer = splitStyles.find(item => {
                    if (item.includes('left')) {
                        return item;
                    }
                });
                let valuePosition = parseInt(positionContainer.match(/\d+/));
                picker.style.background = getColor(valuePosition / 100).css();
            }
        }
        if (pickerRGBA) {
            const colorStr = `${value?.color.r},${value?.color.g},${value?.color.b},${value?.color.a}`;
            pickerRGBA.style.background = `rgb(${colorStr})`;
        }
    },[value, displayPicker]);

    const getColor = chroma.scale(['#f00', '#ff0', '#0f0', '#0ff', '#00f', '#f0f', '#f00']);

    const normalize = (val, max, min) => { return (val - min) / (max - min); };

    const hexToAlpha = (alphaHexString) => {
        return Math.round(normalize(parseInt(alphaHexString, 16), 255, 0) * 100);
    };

    function hexToRgbA(hex, alpha = 100){
        let c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length == 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');

            return  {r: (c>>16)&255, g: (c>>8)&255, b: c&255, a: alpha/100}
        }
        throw new Error('Bad Hex');
    }

    function RGBAToHexA(rgba, forceRemoveAlpha = false) {
        return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
            .split(',') // splits them at ","
            .filter((string, index) => !forceRemoveAlpha || index !== 3)
            .map(string => parseFloat(string)) // Converts them to numbers
            .map((number, index) => index === 3 ? Math.round(number * 255) : number) // Converts alpha to 255 number
            .map(number => number.toString(16)) // Converts numbers to hex
            .map(string => string.length === 1 ? "0" + string : string) // Adds 0 when length of one number is 1
            .join("") // Puts the array to togehter to a string
    }

    const handleClick = () => {
        setDisplayPicker(!displayPicker)
    };

    const handleChange = (color) => {
        setValue({ color: color.rgb })
    };
    return (
        <div className={styles.container} ref={wrapperRef}>
            <div className={ styles.swatch } onClick={ handleClick }>
                <div
                    className={ styles.color }
                    style={{background:`rgba(${value?.color.r},${value?.color.g},${value?.color.b},${value?.color.a})`}}
                />
            </div>
            <Icon
                src={ArrowDownIcon}
                color={COLORS.color_brand}
                width={12}
                height={7}
                onClick={() => setDisplayPicker(!displayPicker)}
                className={styles.arrowIcon  + ` ${displayPicker ? classes.opened : ''}`}
            />
            {displayPicker &&
                <div style={{
                    zIndex:100,
                    position:'absolute',
                    right: 0,
                    top:0,
                }}>
                    <SketchPicker
                        color={value?.color}
                        onChangeComplete={handleChange}

                    />
                </div>
            }

        </div>
    )
};

export default ColorPicker
