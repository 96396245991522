import React, {useContext} from 'react';
import classes from "./workRight.module.css";
import WorkRightManual from "../../WorkRightManualDir/WorkRightManual/workRightManual";
import WorkRightEditManual from "../../WorkRightManualDir/WorkRightEditManual/workRightEditManual";
import WorkRightUsers from "../../WorkRightUsersDir/WorkRightUsers/workRightUsers";
import WorkRightUsersAddGroup from "../../WorkRightUsersDir/WorkRightUsersAddGroup/workRightUsersAddGroup";
import WorkRightReports from "../../WorkRightReportsDir/WorkRightReports/workRightReports";
import WorkRightReportsAddGroup from "../../WorkRightReportsDir/WorkRightReportsAddGroup/workRightReportsAddGroup";
import ChangePassword from "../../../ChangePassword/ChangePassword"
import WorkRightStorageAddGroup from "../../WorkRightStorageDir/WorkRightStorageAddGroup/workRightStorageAddGroup";
import WorkRightStorage from "../../WorkRightStorageDir/WorkRightStorage/workRightStorage";
import WorkRightSamples from "../../WorkRightSamplesDir/WorkRightSamples/workRightSamples";
import {modeContext} from "../../../Contexts/contexts";
import DataTransferPage from "../../DataTransfer";
import UsersSettings from "../../UsersSettings/UsersSettings";
import SystemSettings from "../../SystemSettings/SystemSettings";

const WorkRight = ({sizeX, mode}) => {
    const {widget} = useContext(modeContext);

    return (
        <div className={classes.workRight} style={{width: 'calc(100% - 2px - ' + sizeX+'px'}}>
            {(mode.path==='Справочники' && mode.type.mode==='view') && <WorkRightManual sizeX={sizeX} setWidget={widget.setWidget} mode={mode}/>}
            {(mode.path==='Справочники' && mode.type.mode==='edit') && <WorkRightEditManual item={mode.type.item}/>}
            {(mode.path==='Пользователи' && (mode.type.mode==='view'||mode.type.mode==='edit')) && <WorkRightUsers sizeX={sizeX} setWidget={widget.setWidget} mode={mode}/>}
            {(mode.path==='Пользователи' && mode.type.mode==='edit') && <WorkRightUsersAddGroup sizeX={sizeX} mode={mode} setWidget={widget.setWidget}/>}
            {(mode.path==='Отчеты' && (mode.type.mode==='view'||mode.type.mode==='edit')) && <WorkRightReports sizeX={sizeX} setWidget={widget.setWidget} mode={mode}/>}
            {(mode.path==='Отчеты' && mode.type.mode==='edit') && <WorkRightReportsAddGroup sizeX={sizeX} mode={mode} setWidget={widget.setWidget}/>}
            {(mode.path==='Хранилище' && mode.type.mode==='view') && <WorkRightStorage sizeX={sizeX} mode={mode} setWidget={widget.setWidget}/>}
            {(mode.path==='Хранилище' && mode.type.mode==='edit') && <WorkRightStorageAddGroup sizeX={sizeX} mode={mode} setWidget={widget.setWidget}/>}
            {(mode.path==='Образцы' && mode.type.mode==='view') && <WorkRightSamples sizeX={sizeX} mode={mode} setWidget={widget.setWidget}/>}
            {(mode.path==='Профиль пользователя') && <ChangePassword mode={mode} setWidget={widget.setWidget}/>}
            {(mode.path==='Импорт/Экспорт') && <DataTransferPage sizeX={sizeX} mode={mode} setWidget={widget.setWidget}/> }
            {(mode.path==='Настройки') && <UsersSettings mode={mode} setWidget={widget.setWidget}/> }
            {(mode.path==='Настройки системы') && <SystemSettings mode={mode} setWidget={widget.setWidget}/> }

        </div>
    );
};

export default WorkRight;
