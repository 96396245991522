import React, {createRef, useEffect, useState} from 'react';
import classes from "../../WorkRightManualDir/WorkRightManual/workRightManual.module.css";
import WorkRightHeader from "../../WorkRightCommon/WorkRightHeader/workRightHeader";
import WorkRightSelectPage from "../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage";
import WorkRightTable from "../../WorkRightCommon/WorkRightTable/workRightTable";
import WorkRightReportsAddMenu from "../WorkRightReportsAddMenu/workRightReportsAddMenu";
import {getReports} from "../../../../utils/API/api_report";
import {getBoolFilter} from "../../../../utils/struct";

const WorkRightReports = ({sizeX, setWidget, mode}) => {
    const tableRef = createRef();
    const [countInPage, setCountInPage] = useState(20)
    const [page,setPage] = useState(1)
    const [pageAll, setPageAll] = useState(10)
    const [countAll, setCountAll] = useState(0);
    const [filter, setFilter] = useState('')
    const [sortField, setSortField] = useState({type:'',field:''})
    const [structHeader, setStructHeader] = useState({
        name: true,
        search: true,
        add: true,
        filter: false,
        print: false,
        wrap:true
    })
    const [rows, setRows] = useState([])
    const [struct, setStruct] = useState([])
    const [selectItem, setSelectItem] = useState({id:0, fields:[]})

    const [rowsButton, setRowsButton] = useState({
        print: false,
        copy: true,
        edit: true,
        delete: true
    })

    useEffect(()=>{

        getListRep();
    },[mode, filter, sortField, page]);

    const getListRep = async () => {
        const res = await getReports(mode.token, mode.type.item.id, page, countInPage, filter, sortField);

        setStruct(res.table);

        if (res.pagination?.pages)
            setPageAll(res.pagination?.pages);
        if (res.pagination?.total)
        setCountAll(res.pagination.total);
/*
        if(res.pagination.pages > 1 && res.pagination.active === 1){
            setCountInPage(res.groups[0].reports.length)
        }*/

        if(res.groups?.length > 0){
            let res_users = (res.groups[0]).reports;

            res_users.forEach(item => {
                if (item.value.name?.length > 20) {
                    item.value.name = item.value.name.slice(0, 20) + '...';
                }

                if (item.value.comments?.length > 100) {
                    item.value.comments = item.value.comments.slice(0, 100) + '...';
                }
            });
            setRows(res_users);
        }
        else
            setRows([])
    }
    //useEffect(()=>{
    //    setCountInPage(Math.trunc((tableRef.current.getBoundingClientRect().height-45)/40))
    //},[tableRef])

    return (
        <div className={classes.workRightManual}>
            <WorkRightHeader setVisibleAdd={mode.setVisibleAdd} activeData={mode.type.item} struct={structHeader} filter={filter} setFilter={setFilter} />
            <div className={classes.workRightManual_countRows}>Всего найдено: {countAll}</div>
            <WorkRightSelectPage allCount={pageAll} selectItem={page} setSelectItem={setPage} />
            <WorkRightTable
                tableRef={tableRef}
                mode={mode}
                struct={struct}
                rows={rows}
                rowsButton={rowsButton}
                setVisibleAdd={mode.setVisibleAdd}
                setSelectItem={setSelectItem}
                setWidget={setWidget}
                sortField={sortField}
                setSortField={setSortField}
                fromOther={true}
            />
            {mode.visibleAdd && <WorkRightReportsAddMenu mode={mode} selectItem={selectItem} sizeX={sizeX} setSelectItem={setSelectItem} setWidget={setWidget}/>}
        </div>
    );
};

export default WorkRightReports;
