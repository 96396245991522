import React, {useContext, useEffect, useState} from 'react';
import classes from "./workRightTable.module.css";
import WorkRightTableRow from "./workRightTableRow";
import WorkRightTableHeader from "./workRightTableHeader";
import {sortStruct} from "../../../../utils/struct";
import {modeContext, wrapContext} from "../../../Contexts/contexts";
import ImagePopupComponent from "../../../Widgets/ImagePopup/ImagePopupComponent";
import DropdownComponent from "../../../Widgets/Dropdown/DropdownComponent";
import WidgetDateTimePicker from "../../../Widgets/WidgetDateTimePicker";
import dayjs from "dayjs";
import {getTypeSamples} from "../../../../utils/API/api_list";
import Tree from "../../WorkRightStorageDir/WorkRightStorageAddMenu/Tree/tree";
import dropStyles
  from "../../WorkRightStorageDir/WorkRightStorageAddMenu/workRightStorageAddMenuDropdownFirst.module.css";
import arrowSVG from "../../../../svg/arrow-01.svg";
import error from "../../../../svg/attention-01.svg";
import {getStorages} from "../../../../utils/API/api_storage";
import {COLORS} from "../../../../config";
import Icon from "../../../../helpers/components/Icon";

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const filterFields = [
  {id: 'name',        name: 'Наименование'},
  {id: 'description', name: 'Описание'},
  {id: 'volume',      name: 'Объем'},
  {id: 'date',        name: 'Дата забора'},
  {id: 'type',        name: 'Тип образца'},
  {id: 'storage',     name: 'Расположение'},
]

const TreeDropDown = ({treeLocation, setValue, value}) => {

  const [isVisiblePositionDropdown, setIsVisiblePositionDropdown] = useState(false);
  return ( <div className={classes.filterSelector}>
    <input
      className={dropStyles.infoItemValue}
      value={value?.path ?? ''}
      readOnly={true}
      onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}
    />
    <button className={dropStyles.infoItemArrow}
            onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}><img
      src={arrowSVG} alt=""/></button>
    {isVisiblePositionDropdown &&
      <>
        <div className={dropStyles.DropdownBlockAll}
             onClick={() => setIsVisiblePositionDropdown(false)}></div>
        <div className={dropStyles.TreeBlock}>
          <Tree
            storages={treeLocation}
            setVisibleTreeLocation={setIsVisiblePositionDropdown}
            setLocation={setValue}/>
        </div>
      </>}
  </div>);
}

const WorkRightTable = ({
    tableRef,
    mode,
    struct,
    rows,
    rowsButton,
    setVisibleAdd,
    setSelectItem,
    setWidget,
    sortField,
    setSortField,
    fromOther = false,
    showColumnSelector = false
}) => {
    //let structSort = sortStruct(struct)
  const [imageModalView, setImageModalView] = React.useState(false);
  const [images, setImages] = React.useState();

  const [structHeader, setStructHeader] = useState(struct);
  const [rowsSource, setRowsSource] = useState(rows);
  const [sharedFiltersData, setSharedFiltersData] = useState([{
    field: 'name',
    option: 'contains',
    value: ''
  }]);

  const [types, setTypes] = useState([]);
  const [treeLocation, setTreeLocation] = useState([])

  const handleSetImages = (imgs) => {
    setImages(imgs);
    setImageModalView(true);
  }

  const [height, setHeight] = useState({})
  let count = struct?.length;

  struct?.forEach(item=>{
      if(item.type === 'image') count--
  });
  const {wrap} = useContext(wrapContext)

  useEffect(() => {
    getTypeSamples(mode.token).then(res => setTypes(res));
    getStorages(mode.token, false, '').then(res => {

      res.push({
        id: undefined,
        type: 1,
        name: "Глобальное хранилище",
        icon: "icons/location-01.svg",
        color: "#ad1d1d",
        favorite: false,
        level: "0",
        parent: 0,
        nodes: []
      })

      setTreeLocation(res);
    });
  }, [])

  useEffect(()=>{
        if(wrap)
            setHeight({height:'calc(100% - 160px)'})
    },[wrap]);

  useEffect(() => {
      let i = 0;
      struct?.forEach(item => {
        item.showColumn = (i < 8 );
        i++;
      });
      setStructHeader(struct);
    }, [struct]);

  useEffect(() => {

      const fieldsShow = structHeader?.filter(i => i.showColumn).map(i => i.field);
      const oldRows = JSON.parse(JSON.stringify(rows ?? []));

      const newRows = oldRows.map(i => {

        let row = JSON.parse(JSON.stringify(i));
        row.value = {};
        fieldsShow?.forEach(j => {
          row.value[j] = i.value[j];
        });
        return row;
      });
      setRowsSource(newRows);

    }, [structHeader, rows]);

  const addFilterField = () => {
    const filters = JSON.parse(JSON.stringify(sharedFiltersData));

    const filterField = {
      field: 'name',
      option: 'contains',
      value: ''
    };
    filters.push(filterField);

    setSharedFiltersData(filters);
  }

  const getOptions = (type) => {
    if (type === 'date' || type === 'volume') {
      return [
        {id: '=', name: 'Равно'},
        {id: '>', name: 'Больше'},
        {id: '<', name: 'Меньше'},
        {id: '>=', name: 'Больше либо равно'},
        {id: '<=', name: 'Меньше либо равно'},
      ]
    }

    if (type === 'name' || type === 'description') {
      return [
        {id: '=', name: 'Равно'},
        {id: 'contains', name: 'Содержит'}
      ]
    }

    return [];
  }

  const onExportData = () => {
    const rowsData = JSON.parse(JSON.stringify(rowsSource)).map(i => ({id: i.id, ...i.value}));
    const ws = XLSX.utils.json_to_sheet(rowsData);
    const wb = {Sheets: { 'data': ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer],{ type: fileType});
    FileSaver.saveAs(data, 'export sample data'+ fileExtension);
  }


    return (
      <>
        {
          (mode.sharedFilter && mode.path === 'Образцы' && false) &&
          <div className={classes.sharedContainer}>
            <button className={`button_default save_style`} onClick={addFilterField}>Добавить поле</button>

            <div className={classes.sharedBody}>
              {
                sharedFiltersData.map((field, index) =>
                  <div className={classes.filterRow} key={index}>

                    <div className={classes.filterSelector}>
                      <DropdownComponent keyItem={'id'}
                                         valueItem={'name'}

                                         selectedKey={field.field}
                                         onChange={(e) => {
                                           let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                                           let field = fields[index];
                                           field.field = e;
                                           switch (e) {
                                             case 'volume':
                                             case 'date':
                                             case 'type':
                                             case 'storage': {
                                               field.option = '=';
                                               field.value = 0;
                                               break;
                                             }
                                             case 'description':
                                             default: { //name
                                               field.option = 'contains';
                                               field.value = '';
                                               break;
                                             }
                                           }
                                           setSharedFiltersData(fields);
                                         }}
                                         items={filterFields}/>
                    </div>


                    {
                      field.field === 'type' &&
                      <div className={classes.filterSelector}>
                        <DropdownComponent keyItem={'id'}
                                           valueItem={'name'}
                                           selectedKey={field.value}
                                           onChange={(e) => {
                                             let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                                             let field = fields[index];
                                             field.value = e;
                                             setSharedFiltersData(fields);
                                           }}
                                           items={types}/>
                      </div>
                    }
                    {
                      field.field === 'storage' &&
                     <TreeDropDown value={field.value}
                                   setValue={(val) => {
                                     let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                                     let field = fields[index];
                                     field.value = val;
                                     setSharedFiltersData(fields);
                                   }}
                                   treeLocation={treeLocation}/>
                    }
                    <div className={classes.filterSelector}>
                      {
                        (field.field === 'date' ||
                          field.field === 'name' ||
                          field.field === 'description' ||
                          field.field === 'volume') &&
                        <DropdownComponent keyItem={'id'}
                                           valueItem={'name'}
                                           selectedKey={field.option}
                                           onChange={(e) => {
                                             let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                                             let field = fields[index];
                                             field.option = e;
                                             setSharedFiltersData(fields);
                                           }}
                                           items={getOptions(field.field)}/>
                      }
                    </div>

                    <div>
                      {
                        (field.field === 'name' || field.field === 'description') &&
                        <input className={classes.infoItemValue}
                               type="text"
                               style={{ width: '100%'}}
                               value={field.value}
                               onChange={ e => {
                                 let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                                 let field = fields[index];
                                 field.value = e.target.value;
                                 setSharedFiltersData(fields);
                               }} />

                      }
                      {
                        field.field === 'date' &&
                        <WidgetDateTimePicker selected={field.value}
                                               onChange={(val) =>{
                                                 let date = dayjs(val).format( 'YYYY-MM-DD');
                                                 let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                                                 let field = fields[index];
                                                 field.value = date;
                                                 setSharedFiltersData(fields);
                                               }}
                                               isDate={true}/>
                      }
                      {
                        field.field === 'volume' &&
                        <input className={classes.infoItemValue}
                               type="number"
                               style={{ width: '100%'}}
                               value={field.value}
                               onChange={ e => {
                                 let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                                 let field = fields[index];
                                 field.value = e.target.value;
                                 setSharedFiltersData(fields);
                               }} />
                      }
                    </div>


                    <Icon src={'/svg/delete-01.svg'}
                          color={COLORS.color_hover}
                          width={'20px'}
                          className={classes.deleteIco}
                          onClick={() => {
                            let fields = JSON.parse(JSON.stringify(sharedFiltersData));
                            fields.splice(index, 1);
                            setSharedFiltersData(fields)
                          }}
                          height={'20px'}/>

                  </div>
                )
              }
            </div>

            <div className={classes.footer}>

              <button className={`button_default save_style`} onClick={() => {console.log(sharedFiltersData)}}>Поиск</button>

              <button className={`button_default save_style`} onClick={onExportData}>Экспорт</button>
            </div>
          </div>
        }
        { showColumnSelector &&
          <div style={{marginLeft: '20px',
                      marginBottom: '40px',
                      width: '300px'}}>
            <DropdownComponent keyItem={'field'}
                               customComponent={'checkbox2'}
                               onChange={(e) => {
                                  let newStr = JSON.parse(JSON.stringify(structHeader));
                                  let item = newStr.find(i => i.field === e.key);
                                  if (item) {
                                    item.showColumn = e.value;
                                  }
                                  setStructHeader(newStr);
                               }}
                               label={'Столбцы для отображения'}
                               valueItem={'showColumn'}
                               items={structHeader}/>
          </div>
        }
        <table ref={tableRef} width={100} className={classes.workRightTable} style={height}>
          <tbody>
          <tr>
            { !showColumnSelector && structHeader?.map((structItem) =>
              <WorkRightTableHeader
                data={structItem}
                key={structItem.name}
                sortField={sortField}
                setSortField={setSortField}
                count={count} />
            )}
            {
              showColumnSelector && structHeader?.map((structItem) =>
                structItem.showColumn &&
                <WorkRightTableHeader
                  data={structItem}
                  key={structItem.name}
                  sortField={sortField}
                  setSortField={setSortField}
                  count={count} />
              )
            }
            <td></td>
          </tr>
          {rowsSource?.map((rowsItem) =>
            <WorkRightTableRow
              mode={mode}
              struct={structHeader}
              rowsItem={rowsItem}
              key={rowsItem.id}
              rowsButton={rowsButton}
              setVisibleAdd={setVisibleAdd}
              setSelectItem={setSelectItem}
              setWidget={setWidget}
              fromOther={fromOther}
              showColumnSelector={showColumnSelector}
              handleSetImages={handleSetImages}
            />
          )}
          </tbody>
        </table>
        {
          imageModalView && <ImagePopupComponent images={images} onClosePopup={setImageModalView} isPopupOpen={imageModalView}/>
        }
      </>
    );
};

export default WorkRightTable;
