import LeftMenu from "./components/LeftMenu/leftMenu";
import Header from "./components/Header/header";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import Path from "./components/Path/Path";
import Work from "./components/Work/work";
import Authorization from "./components/Authorization/authorization";
import Widget from "./components/Widgets/widget";
import { getCookie, setCookie } from "./utils/fun_cookie";
import WrapContext from "./components/Contexts/WrapContext";
import { modeContext } from "./components/Contexts/contexts";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import "./app.css";
import "./bootstrap.css"
import TabsPages from "./components/TabPages/TabPages";
import {StructureStore} from "./store/StructureStore";
import {StorageStore} from "./store/StorageStore";
import {SamplesStore} from "./store/SamplesStore";
import {FieldsStore} from "./store/FieldsStore";
import {ModeMove} from "./components/Widgets/Modal/ModalMoveComponent";

export const OutsideContext = createContext(null);

const menu = [
    // { id: 1, text: 'Проекты', src: 'projects-01.svg' },
    { id: 2, text: 'Хранилище', src: 'storage-01.svg' },
    { id: 3, text: 'Образцы', src: 'samples-01.svg' },
    //{ id: 4, text: 'Отчеты', src: 'reports-01.svg' },
    { id: 5, text: 'Справочники', src: 'manual-01.svg' },
    //{ id: 6, text: 'Импорт/Экспорт', src: 'import_export-01.svg'},
    //{ id: 7, text: 'Настройки', src: 'settings_user-01.svg' },
    { id: 8, text: 'Настройки системы', src: 'settings_system-01.svg' },
]

function App() {
    const [isVisibleDropdown, setVisibleDropdown] = useState(false)
    const [token, setToken] = useState('')
    const [fullMenu, setFullMenu] = useState(true)
    const [loginMenu, setLoginMenu] = useState(false)
    const [widget, setWidget] = useState({ status: '', text: '', fun: null })
    const [path, setPath] = useState(menu[0].text)
    const [type, setType] = useState({ mode: 'view', item: '' })
    const [visibleAdd, setVisibleAdd] = useState(false)
    const [viewMode, setViewMode] = useState(0)
    const [copyMode, setCopyMode] = useState(false);
    const [copyType, setCopyType] = useState(ModeMove);
    const [puckAddModal, setPuckAddModal] = useState(false);
    const [puckSelectModal, setPuckSelectModal] = useState(false);
    const rootClasses = ['Right_block'];
    const [move, setMove] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const advancedSettings = true;
    const [selectedCell, setSelectedCell] = useState([]);
    const [sharedFilter, setSharedFilter] = useState(false);

    const [tabs, setTabs] = useState([]);
    const [activeTabId, setActiveTabId] = useState(0);

    const editSelectedCell = (cell) => {
      const array = JSON.parse(JSON.stringify(selectedCell));
      let item = array.find(
        i => i.packId === cell.packId
          && i.column === cell.column
          && i.line === cell.line
          && i.storageId === cell.storageId);

      if (item) {
        let index = array.indexOf(item);
        array.splice(index, 1);
      } else {
        array.push(cell);
      }

      setSelectedCell(array);
    }
    const structureStore = useContext(StructureStore);
    const storageStore = useContext(StorageStore);
    const sampleStore = useContext(SamplesStore);
    const fieldsStore = useContext(FieldsStore);

    const setModeByTab = (index) => {
      const tab = tabs[index];
      if (tab) {
          //localStorage.setItem('active_tabs', tab.id);
          const tabMode = tab.mode.mode;

          if (tab.path === 'Справочники' && tabMode.type.mode === 'view') {
            structureStore.setSelectedStructure(tabMode.type.item.id);
          }

        storageStore.setStore(tab.storageStore);
        structureStore.setStore(tab.structureStore);
        sampleStore.setStore(tab.sampleStore);
        fieldsStore.setStore(tab.fieldsStore);


          setPath(tab.path);
          setType(tabMode.type);
          localStorage.setItem("save_path", JSON.stringify(tab.path));
          localStorage.setItem("save_type", JSON.stringify(tabMode.type));
          setVisibleAdd(tabMode.visibleAdd);
          setCopyMode(tabMode.copyMode);
          setViewMode(tabMode.viewMode);
          setPuckAddModal(tabMode.puckAddModal);
          setPuckSelectModal(tabMode.puckSelectModal);
          setSelectedCell(tabMode.selectedCell);
      }
    }

    useEffect(() => {
      updateMode();
      //localStorage.setItem('tabs', JSON.stringify(tabs));
    }, [path, type, visibleAdd, copyMode])

    const updateMode = () => {
      const updatedTabs = [...tabs];

      const tab = updatedTabs.find(item => item.id === activeTabId);

      if (tab) {
        tab.path = path;
        tab.name = path;

        tab.mode = JSON.parse(JSON.stringify({ mode:
            {   path,
              type,
              token,
              visibleAdd,
              copyMode,
              viewMode,
              puckAddModal,
              puckSelectModal,
              selectedCell,
            },
          widget:
            {
              widget,
            },
          rules: {
            advancedSettings: advancedSettings
          }}));

        tab.storageStore = JSON.parse(JSON.stringify(storageStore.getStore()));
        tab.structureStore = JSON.parse(JSON.stringify(structureStore.getStore()));
        tab.sampleStore = JSON.parse(JSON.stringify(sampleStore.getStore()));
        tab.fieldsStore = JSON.parse(JSON.stringify(fieldsStore.getStore()));
      }
      setTabs(updatedTabs);
    }

    const setPathMiddleware = (data) => {
        localStorage.setItem("save_path", JSON.stringify(data));
        setPath(data);

    }

    const loadPathMiddleware = () => {
        const str_data = localStorage.getItem("save_path");

        const data = JSON.parse(str_data);
        setPath(data);
        const tabsStr = localStorage.getItem('tabs');

        if (tabsStr == null) {
          const newTab = {
            id: new Date().getTime(),
            name: data,
            path: data,
            mode: JSON.parse(JSON.stringify({ mode:
                {   path,
                  type,
                  token,
                  visibleAdd,
                  copyMode,
                  viewMode,
                  puckAddModal,
                  puckSelectModal,
                  selectedCell,
                },
              widget:
                {
                  widget
                },
              rules: {
                advancedSettings: advancedSettings
              }})),
            storageStore: JSON.parse(JSON.stringify(storageStore.getStore())),
            structureStore: JSON.parse(JSON.stringify(structureStore.getStore())),
            sampleStore: JSON.parse(JSON.stringify(sampleStore.getStore())),
            fieldsStore: JSON.parse(JSON.stringify(fieldsStore.getStore())),
          };
          setTabs([newTab]);
          setActiveTabId(newTab.id);
        } else {
          const parseTabs = JSON.parse(tabsStr);
          setTabs(parseTabs);
          setActiveTabId(parseTabs[0].id);

        }
    }

    const setTypeMiddleware = (data) => {
        localStorage.setItem("save_type", JSON.stringify(data));
        setType(data)
    }

    const loadTypeMiddleware = () => {
        const str_data = localStorage.getItem("save_type");
        try{

          const data = JSON.parse(str_data);

          setType(data);
        } catch {}
    }


    if (!fullMenu) {
        rootClasses.push('Right_block_small');
    }

    useEffect(() => {

        const token_c = getCookie('token')
        if (token_c)
            setToken(token_c)
        if (window.location.pathname === '/recovery') {
            setToken('')
            setCookie('token', '')
        }

     // const str_tabs = localStorage.getItem('tabs');
     // const active_tabs = localStorage.getItem('active_tabs');

      loadPathMiddleware();
      loadTypeMiddleware();


        /*if (str_tabs) {
          try {
            setActiveTabId(Number(active_tabs));
            const parse_tabs = JSON.parse(str_tabs);
            setTabs(parse_tabs);

            let tab = parse_tabs.find( i => i.id === activeTabId);
            if (tab) {
              if (tab.storageStore)
                storageStore.setStore(tab.storageStore);
              if (tab.structureStore)
                structureStore.setStore(tab.structureStore);
              if (tab.sampleStore)
                sampleStore.setStore(tab.sampleStore);
              if (tab.fieldsStore)
                fieldsStore.setStore(tab.fieldsStore);
            }

          } catch {}
        } else {
          setTabs([]);
        }*/

    }, [])

    useEffect(() => {
      localStorage.setItem('tabs', JSON.stringify(tabs));
    }, [tabs]);

  const containerRef = useRef(null);
    return (

        <div className="App"
             ref={containerRef}
             onClick={() => {
            setVisibleDropdown(false);
            setViewMode(0)
        }}>
          <OutsideContext.Provider value={ containerRef }>
            <DndProvider backend={HTML5Backend}>
                <modeContext.Provider value={{ mode:
                      {   path,
                          setPath: setPathMiddleware,
                          type,
                          setType: setTypeMiddleware,
                          token,
                          setToken,
                          visibleAdd,
                          setVisibleAdd,
                          copyMode,
                          setCopyMode,
                          viewMode,
                          copyType, setCopyType,
                          setViewMode,
                          puckAddModal,
                          setPuckAddModal,
                          puckSelectModal,
                          sharedFilter,
                          setSharedFilter,
                          setPuckSelectModal,
                          setMove,
                          isDragging,
                          setIsDragging,
                          selectedCell,
                          activeTabId,
                          setActiveTabId,
                          editSelectedCell,
                          setSelectedCell,
                          tabs,
                          setTabs,
                          setModeByTab
                      },
                      widget:
                        {
                            widget,
                            setWidget
                        },
                    rules: {
                      advancedSettings: advancedSettings
                    }}}>
                    {token !== '' && <LeftMenu fullMenu={fullMenu} menu={menu} token={token}/>}
                    {token !== '' && <div className={rootClasses.join(' ')}>
                        <Header fullMenu={fullMenu} setFullMenu={setFullMenu} setLoginMenu={setLoginMenu} isVisibleDropdown={isVisibleDropdown} setVisibleDropdown={setVisibleDropdown} />
                        <Path path={path} />
                        <TabsPages />
                        <WrapContext setFullMenu={setFullMenu}>
                            <Work fullMenu={fullMenu} />
                        </WrapContext>
                    </div>}
                    {(token === '' || loginMenu) && <Authorization setLoginMenu={setLoginMenu} setToken={setToken} />}
                    {widget.status !== "" && <Widget widget={widget} setWidget={setWidget} />}
                </modeContext.Provider>
            </DndProvider>
          </OutsideContext.Provider>
        </div>
    );
}

export default App;
