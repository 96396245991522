import React, {useContext, useState} from 'react';
import classes from "./leftMenu.module.css";
import LeftMenuItem from "./leftMenuItem";
import LeftMenuItem2 from "./leftMenuItem2";
import ModalSupport from "../ModalSupport/ModalSupport";
import AboutProgrammModal from "../AboutProgrammModal/AboutProgrammModal";
import {sendSupportMessage} from "../../utils/API/api_user";
import {modeContext} from "../Contexts/contexts";

const LeftMenu = ({fullMenu, menu, token}) => {
    const mode = React.useContext(modeContext);
    const [supportModal, setSupportModal] = React.useState(false);
    const [showVersion, setShowVersion] = React.useState(false);

    const onItemClick = () => {
      setSupportModal(true);
    }

    const onCloseSupportModal = () => {
      setSupportModal(false);
    }

  const onSaveSupportModal = (data) => {
    sendSupportMessage(token, data).then(() => {

      mode.widget.setWidget({
        status: 'Ok',
        text: `Наш оператор свяжется с вами в ближайшее время по указанному адресу Email`,
        title: 'Ваше сообщение успешно отправлено!',
        fun: async () => { }
      });
    });
    setSupportModal(false);
  }

    const version = '0.0.1'
    const [menu2] = useState([
        {id:1, text:'Справка', src:'help-01.svg', onClick: () => setShowVersion(true)},
        {id:2, text:'Техническая поддержка', src:'support-01.svg', onClick: onItemClick},
    ])

    const rootClasses = [classes.LeftMenu];

    if(!fullMenu){
        rootClasses.push(classes.LeftMenu_small);
    }

    return (
      <>
        <div className={rootClasses.join(' ')}>
          <div className={classes.LeftMenu_block1}>
            {fullMenu ?
              <img className={classes.LeftMenu_logo} src={"/svg/logo-01.svg"} alt={'logo'}/> :
              <img className={classes.LeftMenu_logo} src={"/svg/logo_icon-01.svg"} alt={'logo'}/>
            }
            <div className={classes.LeftMenu_Menu}>
              {menu.map((menuItem) =>
                (menuItem.id !== 8) ?
                  <LeftMenuItem fullMenu={fullMenu} menuItem={menuItem} key={menuItem.id} /> :
                  mode.rules.advancedSettings && <LeftMenuItem fullMenu={fullMenu} menuItem={menuItem} key={menuItem.id}/>
              )}
            </div>
          </div>
          <div className={classes.LeftMenu_Menu2}>
            <div>
              {menu2.map((menuItem) =>
                <LeftMenuItem2 fullMenu={fullMenu} menuItem={menuItem} key={menuItem.id} onClick={menuItem.onClick}/>
              )}
            </div>
            {fullMenu ?
              <div className={classes.LeftMenu_footer} onClick={() => setShowVersion(true)}>
                <a href={'#'} className={classes.LeftMenu_a}>SmartBio.pro</a>
                <div className={classes.LeftMenu_version}>Версия {version}</div>
              </div> :
              <div className={classes.LeftMenu_footer} onClick={() => setShowVersion(true)}>>
                <div className={classes.LeftMenu_version}>v. {version}</div>
              </div>
            }
          </div>
        </div>
        {
          supportModal && <ModalSupport openModal={supportModal} onClose={onCloseSupportModal} mode={token} onSave={onSaveSupportModal}/>
        }
        {
          showVersion && <AboutProgrammModal openModal={showVersion} version={version} onClose={() => setShowVersion(false)}/>
        }
      </>
    );
};

export default LeftMenu;
