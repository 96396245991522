import React, {useContext, useEffect, useRef, useState} from 'react';
import dropStyles from "../WorkRightStorageAddMenu/workRightStorageAddMenuDropdownFirst.module.css";
import closeSVG from "../../../../svg/close-01.svg";
import classes from "./workRightStorageViewMenu.module.css";
import Barcode from "react-barcode";
import {observer} from "mobx-react-lite";
import {StorageStore} from "../../../../store/StorageStore";
import {SamplesStore} from "../../../../store/SamplesStore";
import {getStoragePacks, getStorages} from "../../../../utils/API/api_storage";
import ModalMoveComponent, {ModeMove, ModeCopy} from "../../../Widgets/Modal/ModalMoveComponent";
import {deleteSample, moveSample} from "../../../../utils/API/api_sample";
import {modeContext} from "../../../Contexts/contexts";
import {toJS} from "mobx";
import {getPack} from "../../../../utils/API/api_list";
import {getCatalogs, getMappingCatalog} from "../../../../utils/API/api_catalog";
import moment from "moment";
import {OutsideContext} from "../../../../App";
import useOutside from "../../../../helpers/useOutside";

const WorkRightStorageViewMenu = observer(({sizeX, setVisibleAdd, mode, updateTable, setWidget}) => {
    const storageStore = useContext(StorageStore);
    const samplesStore = useContext(SamplesStore);
    const {widget} = useContext(modeContext);


    const outerRef = React.useContext(OutsideContext);
    const contextMenuRef = React.useRef(null);

    useOutside(outerRef, contextMenuRef, () => {
        console.log('asdas')
        storageStore.clearSelectedSample()
    });

    const [treeLocation, setTreeLocation] = useState([])

    const [modalMove, setModalMove] = useState(false)
    const [modalMoveMode, setModalMoveMode] = useState(ModeMove)

    const id = storageStore.selectedSample?.id;
    const barcodes = storageStore.selectedSample?.barcodes;
    const location = storageStore.selectedSample?.locations?.name ?? 'Глобальное хранилище';

    const name = storageStore.selectedSample?.name;
    const type = storageStore.selectedSample?.type;
    const amount_name = storageStore.selectedSample?.amount_name;
    const amount = storageStore.selectedSample?.amount;
    const column = storageStore.selectedSample?.column;
    const line = storageStore.selectedSample?.line;
    const description = storageStore.selectedSample?.patient_description;

    const [packInfo, setPackInfo] = useState();

    const date = {
        date_create: storageStore.selectedSample?.date_create,
        date_update: storageStore.selectedSample?.date_update
    }

    const [catalogsDataSource, setCatalogsDataSource] = React.useState([]);
    const [patientDescriptions, setPatientDescriptions] = React.useState([]);

    const loadCatalogs = async () => {
        let res = await getCatalogs(mode.token);
        setCatalogsDataSource(res);
    }

    const cord = `${line}/${column}`;

    useEffect(() => {
        const init = async () => {
            const loc = await getStorages(mode.token, false, '');
            loc.push({
                id: undefined,
                type: 1,
                name: "Глобальное хранилище",
                icon: "icons/location-01.svg",
                color: "#ad1d1d",
                favorite: false,
                level: "0",
                parent: 0,
                nodes: []
            });
            setTreeLocation(loc)
            setPackInfo(await getPack(mode.token, storageStore.selectedSample?.locations?.pack.id));
        }
        init()
    }, []);

    const [showAllDescription, setShowAllDescription] = useState(false);

    useEffect(() => {
        const load = async () => {

            await loadCatalogs();

            let patient = [];//JSON.parse(JSON.stringify(patientDescriptions));
            if (description) {
                for(let i=0; i< description.length; i++) {
                    let item = description[i];

                    const newCatalog = await getMappingCatalog(mode.token, item.catalog);

                    let fields = item.fields.map(field => {return {id: field.field, value: field.value, setting: field.setting, files: field.files.map(i => {
                            let arr = i.split('/').reverse();
                            return arr[0];
                        }) ?? [] }});
                    patient.push({
                        id: item.catalog,
                        fields: fields,
                        catalog: newCatalog,
                    });
                    setPatientDescriptions(patient);
                }
            }
        }
        load();
    }, [description])

    const getField = (field) => {

        if (field) {
            const elements = [];

            field.catalog.fields.map(i => {
                let item = field.fields.find(j => j.id === i.id);
                if (item) {
                    if (Object.keys(item.setting).length > 0) {
                        elements.push(
                          <div className={classes.info_pacient_block2}>
                              <div className={classes.info_pacient_text}>{i.name}</div>
                              <div>{item.setting.items
                                ?? item.setting.value
                                ?? item.setting.result
                                ?? moment(item.setting.date).format('YYYY-MM-DD')
                                ?? item.setting.file
                                ?? item.setting.address
                                ?? moment(item.setting.from).format('YYYY-MM-DD')}
                                  {item.setting.to && ' - '+moment(item.setting.to).format('YYYY-MM-DD')}
                              </div>
                          </div>
                        );
                    } else {
                        elements.push(
                          <div className={classes.info_pacient_block2}>
                              <div className={classes.info_pacient_text}>{i.name}</div>
                              <div>{item.value}</div>
                          </div>
                        );
                    }

                } else {
                    elements.push(
                      <div className={classes.info_pacient_block2}>
                        <div className={classes.info_pacient_text}>{i.name}</div>
                    </div>
                    );
                }
            })

            return elements;
        }
        return <></>
    }


    return <>
            <div className={mode.path === 'Хранилище' ? dropStyles.addMenu_notBack : dropStyles.addMenu}
                 style={{ width: 'calc(100% - 3px - ' + sizeX + 'px' }}>
                <div className={classes.addMenu_block} ref={contextMenuRef}>
                    <div className={classes.title}>
                        <button onClick={()=> storageStore.clearSelectedSample()} className={dropStyles.closeButton}><img src={closeSVG} alt=""/></button>
                    </div>
                    <div className={classes.block1}>
                        <div className={classes.block1_text}></div>
                        <div className={classes.block1_items}>
                            <div className={'hover_hint_parent'}>
                                <div className={classes.block1_button + ' ' +classes.block1_button_print}></div>
                                <div className={'hover_hint'}>Печать</div>
                            </div>
                            <div className={'hover_hint_parent'}>
                                <div className={classes.block1_button + ' ' +classes.block1_button_move}
                                     onClick={() => {
                                         if (mode.path === 'Образцы') {
                                             mode.setCopyMode(true);
                                             mode.setCopyType(ModeMove);
                                         } else {
                                             setModalMove(true);
                                             setModalMoveMode(ModeMove);
                                         }
                                     }} >
                                </div>

                                <div className={'hover_hint'}>Переместить образец</div>
                            </div>

                            <div className={'hover_hint_parent'}>
                            <div className={classes.block1_button + ' ' +classes.block1_button_edit} onClick={async ()=>{

                                await new Promise(async (resolve) => {
                                    await samplesStore.setEmptySample();
                                    await samplesStore.setSelectedSample(mode.token, id);
                                    resolve(true)
                                }).then((result) => {
                                    storageStore.clearSelectedSample()

                                    mode.setVisibleAdd(result)
                                })

                            }}></div>
                                <div className={'hover_hint'}>Изменить</div>
                            </div>

                            <div className={'hover_hint_parent'}>
                            <div className={classes.block1_button + ' ' +classes.block1_button_copy}
                                 onClick={() => {
                                     if (mode.path === 'Образцы') {
                                         mode.setCopyMode(true);
                                         mode.setCopyType(ModeCopy);
                                     } else {
                                     setModalMove(true);
                                     setModalMoveMode(ModeCopy);
                                     }
                                 }}
                            ></div><div className={'hover_hint'}>Копировать образец</div>
                            </div>

                            <div className={'hover_hint_parent'}>
                            <div className={classes.block1_button + ' ' +classes.block1_button_delete}
                                onClick={() => {
                                    widget.setWidget({
                                        status: 'Sure',
                                        text: 'Вы действительно хотите удалить образец?',
                                        fun: async () => {
                                            await deleteSample(mode.token, id);
                                            setVisibleAdd(false);
                                            storageStore.clearSelectedSample();
                                            storageStore.refreshSamples(mode.token, 'list')
                                            storageStore.refreshPacks(mode.token);
                                        }
                                    })
                                }}
                            ></div>
                                <div className={'hover_hint'}>Удалить образец</div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.block1}>
                        <div className={classes.block1_text}>Описание образца</div>
                        <div className={classes.block1_items}>

                        </div>
                    </div>
                    <div className={classes.block2}>
                        <div className={classes.icon} style={{background:type.color?type.color:'#FFFFFF'}}>{type.symbol}</div>
                        <div className={classes.number}>{name}</div>
                    </div>
                    <div>
                        <div className={classes.info_text}>Расположение: {location}</div>
                        <div className={classes.info_text}>Штатив: {packInfo?.name}</div>
                        <div className={classes.info_text}>Позиция: {cord}</div>
                        <div className={classes.info_text}>К()</div>
                        <div className={classes.block_barcode}>
                            {
                                toJS(barcodes).map(barcode =>
                                  <div key={barcode.id}>
                                    <div className={classes.block_barcode_text}>{barcode.value}</div>
                                    <Barcode displayValue={false} value={barcode.value} height={30} background={'#DCE6E9'} />
                                  </div>)
                            }
                        </div>
                        <div className={classes.info2_text}>Объем: { amount } { amount_name }</div>
                        <div className={classes.info3}>
                            <div className={classes.info3_text}>Дата забора: {date.date_create}</div>
                            <div className={classes.info3_text}>Изменен: {date.date_update}</div>
                        </div>
                        {/*<button className={classes.history}>История изменений</button>*/}
                        {
                            patientDescriptions.length > 0 && <>
                              <div className={classes.info_pacient}>Описание пациента</div>
                              <div className={classes.info_pacient_block}>
                                  {
                                      !showAllDescription && getField(patientDescriptions[0])
                                  }
                                  {
                                      showAllDescription && patientDescriptions.map( i => getField(i))
                                  }
                              </div>
                              {
                                 !showAllDescription && <button className={classes.viewAll} onClick={() => setShowAllDescription(true)}>Показать все</button>
                              }

                              {
                                showAllDescription && <button className={classes.viewAll} onClick={() => setShowAllDescription(false)}>Скрыть</button>
                              }
                          </>
                        }

                    </div>
                </div>
            </div>
            <ModalMoveComponent
                openModal={modalMove}
                mode={modalMoveMode}
                sampleData={storageStore.selectedSample}
                treeLocation={treeLocation}
                onClose={() => {
                    setModalMove(false)
                }}
                token={mode.token}
                action={ async (data) => {
                    let res = await moveSample(mode.token, storageStore.selectedStorageId, data);
                    if (!res.success) {
                        setWidget({
                            status: parseInt(res.error_code) === 2016 ? 'SimpleError' : 'Error',
                            text: parseInt(res.error_code) === 2016 ? "Данная ячейка уже занята. Поместите образец в другое место хранения" : res.error_code,
                            fun: async () => {
                            }
                        })
                    } else {
                        setModalMove(false)
                        setVisibleAdd(false);
                        await updateTable();
                        storageStore.clearSelectedSample();
                    }
                }}
            />
    </>;
});

export default WorkRightStorageViewMenu;
