import React, {useContext, useEffect, useState} from 'react';
import WorkRightHeader from "../../WorkRightCommon/WorkRightHeader/workRightHeader";
import classes from "../../WorkRightManualDir/WorkRightManual/workRightManual.module.css";
import WorkRightSelectPage from "../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage";
import WorkRightTable from "../../WorkRightCommon/WorkRightTable/workRightTable";
import {getSamples2, moveSample} from "../../../../utils/API/api_sample";
import WorkRightStorageAddMenu from "../../WorkRightStorageDir/WorkRightStorageAddMenu/workRightStorageAddMenu";
import WorkRightStorageViewMenu from "../../WorkRightStorageDir/WorkRightStorageViewMenu/workRightStorageViewMenu";
import {observer} from "mobx-react-lite";
import {StorageStore} from "../../../../store/StorageStore";
import ModalMoveComponent from "../../../Widgets/Modal/ModalMoveComponent";
import {getStorages} from "../../../../utils/API/api_storage";

const WorkRightSamples = observer(({setWidget, sizeX, mode}) => {
    const storageStore = useContext(StorageStore);

    const [countInPage, setCountInPage] = useState(1);
    const [page,setPage] = useState(1);
    const [pageAll, setPageAll] = useState(10);
    const [countAll, setCountAll] = useState(0);
    const [filter, setFilter] = useState('');
    const [sortField, setSortField] = useState({type:'',field:''});
    const [structHeader, setStructHeader] = useState({
        name: true,
        search: false,
        add: true,
        filter: true,
        print: false,
        wrap:true
    });
    const [rows, setRows] = useState([]);

    const [struct, setStruct] = useState([]);

    const [selectItem, setSelectItem] = useState({id:0, fields:[], group:mode.type.item.id});
    const [rowsButton, setRowsButton] = useState({
        print: false,
        copy: true,
        edit: true,
        delete: true
    });

    const [treeLocation, setTreeLocation] = useState([]);

    useEffect(() => {
        const init = async () => {
          const loc = await getStorages(mode.token, false, '');
          loc.push({
            id: undefined,
            type: 1,
            name: "Глобальное хранилище",
            icon: "icons/location-01.svg",
            color: "#ad1d1d",
            favorite: false,
            level: "0",
            parent: 0,
            nodes: []
          });
          setTreeLocation(loc)
        }
        init()
    }, [])

    useEffect(()=>{
        getSamplesT();
    },[mode, filter, page, sortField])


    const getSamplesT = async () => {
        const res = await getSamples2(mode.token,0, 'table', filter, countInPage, page, sortField);

        setStruct(res.table);
        setCountAll(res.pagination.total);
        setRows(res.samples);
        setPageAll(res.pagination.pages);

      if (res.pagination.pages > 1 && res.pagination.active === 1) {
            setCountInPage(res.samples.length);
      }
    }

    return (
        <div style={{height: '100%'}}>
            <WorkRightHeader setVisibleAdd={mode.setVisibleAdd}
                             activeData={mode.type.item}
                             struct={structHeader}
                             filter={filter}
                             setFilter={setFilter} />

            <div className={classes.workRightManual_countRows}>Всего найдено: {countAll}</div>

            <WorkRightSelectPage allCount={pageAll}
                                 selectItem={page}
                                 setSelectItem={setPage} />
            <WorkRightTable mode={mode}
                            struct={struct}
                            rows={rows}
                            rowsButton={rowsButton}
                            setVisibleAdd={mode.setVisibleAdd}
                            setSelectItem={setSelectItem}
                            setWidget={setWidget}
                            sortField={sortField}
                            showColumnSelector={true}
                            setSortField={setSortField}
                            fromOther={true} />

            {Object.keys(storageStore.selectedSample).length && mode.copyMode === false ?
                <WorkRightStorageViewMenu mode={mode}
                                          updateTable={getSamplesT}
                                          sizeX={sizeX}
                                          item={storageStore.selectedSample}
                                          setVisibleAdd={mode.setViewMode}
                                          setWidget={setWidget} /> : ''}

            { mode.visibleAdd && <WorkRightStorageAddMenu mode={mode}
                                                          selectItem={selectItem}
                                                          sizeX={sizeX}
                                                          visibleAdd={mode.visibleAdd}
                                                          setVisibleAdd={mode.setVisibleAdd}
                                                          setSelectItem={setSelectItem}
                                                          setWidget={setWidget} /> }
            <ModalMoveComponent openModal={mode.copyMode}
                                mode={mode.copyType}
                                sampleData={storageStore.selectedSample}
                                treeLocation={treeLocation}
                                onClose={() =>  {
                                    storageStore.clearSelectedSample();
                                    mode.setCopyMode(false);
                                }}
                                token={mode.token}
                                action={ async (data) => {
                                    let res = await moveSample(mode.token, storageStore.selectedStorageId, data);

                                    if (!res.success) {
                                      setWidget({
                                        status: parseInt(res.error_code) === 2016 ? 'SimpleError' : 'Error',
                                        text: parseInt(res.error_code) === 2016 ? "Данная ячейка уже занята. Поместите образец в другое место хранения" : res.error_code,
                                        fun: async () => {
                                        }
                                      })
                                    }else {
                                      storageStore.clearSelectedSample();
                                      mode.setCopyMode(false);
                                      await getSamplesT();
                                    }

                                }} />

        </div>
    );
});

export default WorkRightSamples;
