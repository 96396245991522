import React, {useContext, useEffect, useMemo, useState} from 'react';
import WorkRightHeader from "../../WorkRightCommon/WorkRightHeader/workRightHeader";
import WorkRightStorageTable from "../WorkRightStorageTable/workRightStorageTable";
import WorkRightStorageAddMenu from "../WorkRightStorageAddMenu/workRightStorageAddMenu";
import styles from "./workRightStorage.module.css";
import { moveSample } from "../../../../utils/API/api_sample";
import {getSample} from "../../../../utils/API/api_sample";
import WorkRightStorageViewMenu from "../WorkRightStorageViewMenu/workRightStorageViewMenu";
import { StorageStore } from "../../../../store/StorageStore";
import { observer } from "mobx-react-lite";
import { DragDropContext } from "react-beautiful-dnd";
import {toJS} from "mobx";
import {SamplesStore} from "../../../../store/SamplesStore";
import ModalPackAdd from "../../../Packs/ModalPackAdd/ModalPackAdd";
import ModalPackSelect from "../../../Packs/ModalPackSelect/ModalPackSelect";
import {setPackToStorage} from "../../../../utils/API/api_pucks";
import {getListPacks, getListPacksAsync, getTypeSamples} from "../../../../utils/API/api_list";

const structHeader = {
    name: false,
    search: false,
    add: false,
    filter: false,
    print: false,
    wrap: false
}

export const ExtendedSampleContext = React.createContext('extended-sample-context')

const WorkRightStorage = observer(({setWidget, sizeX, mode}) => {
    const storageStore = useContext(StorageStore);
    const sampleStorage = useContext(SamplesStore);
    const [selectItem, setSelectItem] = useState({id: 0, fields: [], group: mode.type.item.id})
    const [selectedSample, setSelectedSample] = useState(null);
    const [showEmptyDetail, setShowEmptyDetail] = useState(false);
    const [types, setTypes] = useState([]);

    const selectedItem = useMemo(async () => {
        // const result = await getSample(mode.token,selectItem, 'view')

        return selectedSample
    }, [selectedSample]);

    useEffect(() => {

        prevent()
    },[mode, storageStore, mode.activeTabId]);

    const prevent = async () => {

        if ( mode.type.item ) {
            if (mode.type.isFreePack)
                await storageStore.setSelectedStorage(mode.token, 0);
            else
                await storageStore.setSelectedStorage(mode.token, storageStore.selectedStorageWithNodes.id);
            // setSamples(storageStore.samples.location)
        }

            setTypes(await getTypeSamples(mode.token));
            storageStore.refreshSamples(mode.token, 'list')
            storageStore.refreshPacks(mode.token);

    }


    const packs = (storageStore.packClickId !== 0 && storageStore.selectedStorageId === 0) ?
      <WorkRightStorageTable
        key={0}
        setSelectItem={setSelectItem}
        setSelectedSample={setSelectedSample}
        mode={mode}
        storage={{}}
        setViewMode={mode.setViewMode}
      />
      : storageStore.samples?.locations?.map((storage, index) => {
        if (storageStore.packClickId !== 0  ) {
            let pack = storage.packs.map(i => i.structura.id);
            if (pack.includes(storageStore.packClickId) ) {
                return <WorkRightStorageTable
                  key={index}
                  setSelectItem={setSelectItem}
                  setSelectedSample={setSelectedSample}
                  mode={mode}
                  storage={{...storage}}
                  setViewMode={mode.setViewMode}
                />
            }
        }
        if (storageStore.packClickId === 0  ) {
            return <WorkRightStorageTable
              key={index}
              setSelectItem={setSelectItem}
              setSelectedSample={setSelectedSample}
              mode={mode}
              storage={{...storage}}
              setViewMode={mode.setViewMode}
            />
        }
    })

    const savePackToStorage = async (data) => {
        let res = await setPackToStorage(mode.token, data);

        if (res.success) {
            mode.setPuckSelectModal(false);
            prevent();
        } else {
            setWidget({
                status: 'Error',
                text: res.error_code,
                fun: async () => { }
            });
            mode.setPuckSelectModal(false);
        }
    }

    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        const destination = result.destination.droppableId.split(':');
        const column = destination[0].split('')[0];
        const line = destination[0].split('')[1];
        const pack = parseInt(destination[1]);
        const newStorage = parseInt(destination[2]);
        const item = result.draggableId.split(':');
        const oldPack = item[1];
        const sampleId = parseInt(item[2]);
        const oldStorage = item[3];

        let oldInfoSample = await storageStore.returnSampleById(mode.token, sampleId, 'view');


        let res = await moveSample(mode.token, sampleId, {
            id: sampleId,
            locations: {id: newStorage},
            pack: pack,
            line,
            column,
            amount: oldInfoSample['amount']
        });

        if (!res.success) {
            setWidget({
                status: parseInt(res.error_code) === 2016 ? 'SimpleError' : 'Error',
                text: parseInt(res.error_code) === 2016 ? "Данная ячейка уже занята. Поместите образец в другое место хранения" : res.error_code,
                fun: async () => {
                }
            })
        } else {
            mode.setMove(!mode.move);
            if (!newStorage) {
                new Promise(async (resolve) => {
                    storageStore.setSelectedStorageId(0);
                    await storageStore.refreshSamples(mode.token, 'list', pack);

                    resolve();
                }).then(res => {
                    storageStore.clearPuck();
                    storageStore.setPuckClick(pack);
                })

            } else {


                storageStore.changeCoordsOfItem({id:sampleId, pack: oldPack, storage: oldStorage}, pack, line, column, newStorage);
                await prevent();
            }


        }


    }

    return (
      <ExtendedSampleContext.Provider value={{
          showEmptyDetail,
          setShowEmptyDetail
      }}>
          <div style={{overflow: 'auto', height: '100%'}}>
              <WorkRightHeader
                setVisibleAdd={mode.setVisibleAdd}
                activeData={mode.type.item}
                struct={structHeader}
                filter={storageStore.filter}
                setFilter={storageStore.setFilter}
              />
              <div className={styles.workSpace}
                   style={{
                       gridTemplateColumns: 'auto',
                   }}
              >
                  <div className={styles.samples}>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                          {packs}
                      </DragDropContext>
                  </div>
              </div>

              {showEmptyDetail &&
                <div className={styles.sampleDeatilBox}>
                    <div >Позиция: {sampleStorage.selectedSample.line} / {sampleStorage.selectedSample.column}</div>
                    <div className={styles.row}>
                        <span>Добавить новый образец: </span>
                        <div className={styles.sampleTypescontainer}>
                            {
                                types.map((i, index) =>
                                  <div onClick={() => {
                                      setShowEmptyDetail(false);
                                      sampleStorage.changeSampleField('selectedType', i)
                                      mode.setVisibleAdd(true);
                                  }}>{i.name}</div>)
                            }
                        </div>
                    </div>

                </div>
              }

              {Object.keys(storageStore.selectedSample).length ?
                <WorkRightStorageViewMenu
                  mode={mode}
                  sizeX={sizeX}
                  updateTable={async () => {
                      if (storageStore.packClickId !== 0) {
                          await storageStore.setSelectedStorage(mode.token, 0);
                          await storageStore.refreshSamples(mode.token, 'list', storageStore.packClickId);
                      }
                  else
                      await storageStore.setSelectedStorage(mode.token, storageStore.selectedStorageWithNodes.id);

                      storageStore.refreshPacks(mode.token);
                  }}
                  setWidget={setWidget}
                  visibleAdd={mode.viewMode!==0}
                  item={selectedSample}
                  setVisibleAdd={mode.setViewMode}
                />:''}

              { mode.visibleAdd && <WorkRightStorageAddMenu
                mode={mode}
                selectItem={selectItem}
                sizeX={sizeX}
                visibleAdd={mode.visibleAdd}
                setVisibleAdd={mode.setVisibleAdd}
                setSelectItem={selectedItem}
                setWidget={setWidget}
              />}
              {
                mode.puckAddModal &&
                <ModalPackAdd token={mode.token}
                              setWidget={setWidget}
                              onClose={() => mode.setPuckAddModal(false)}
                              openModal={mode.puckAddModal}
                              packData={{id: 0, name: '', column: 0, lines: 0, column_name: '', lines_name: ''}}/>
              }
              {
                mode.puckSelectModal &&
                <ModalPackSelect mode={mode}
                                 setWidget={setWidget}
                                 action={savePackToStorage}
                                 onClose={() => mode.setPuckSelectModal(false)}
                                 openModal={mode.puckSelectModal}
                                 storageId={storageStore.selectedStorageId ?? 0}/>
              }
          </div>
      </ExtendedSampleContext.Provider>
    );
});

export default WorkRightStorage;
