import React, { useContext } from 'react';
import classes from "./workRightStorageTable.module.css";
import WorkRightStorageTablePack from "./workRightStorageTablePack";
import {DragDropContext} from "react-beautiful-dnd";
import {getSamples, moveSample} from "../../../../utils/API/api_sample";
import {StorageStore} from "../../../../store/StorageStore";
import {observer} from "mobx-react-lite";
import {getListPacks, getListPacksAsync, getPack} from "../../../../utils/API/api_list";
import {toJS} from "mobx";

const findItemByCoords = (table, coords) => {
    return table.reduce((acc1, row) => {
        const item = row.reduce((acc2, col) => {
            if (col.type === 'item') {
                if (col.coords === coords) {
                    acc2 = col;
                    return acc2
                }
                return acc2;
            }
            return acc2;
        }, undefined)

        if (item) {
            return item
        }
        return acc1
    }, undefined)
}

const WorkRightStorageTable = observer(({mode, storage, samples, setViewMode, setSelectItem, setSelectedSample}) => {

    const [packs, setPacks] = React.useState([]);
    const [locations, setLocations] = React.useState([]);

    const storageStore = useContext(StorageStore);

    const loadPackByStorageId = async (id) => {
        if (storageStore.packClickId !== 0 && (id === 0 || id === undefined)) {
            let res = await getPack(mode.token, storageStore.packClickId);
            let loc = await getSamples(mode.token, id, 'list', '', undefined, undefined, undefined, storageStore.packClickId );

            setLocations(loc);

            setPacks([res]);
        } else {
            let res = await getListPacks(mode.token, id);
            let loc = await getSamples(mode.token, id, 'list', '' );
            setLocations(loc);
            setPacks(res);
        }

    }

    React.useEffect(() => {
        loadPackByStorageId(storage.id);
    }, [storage]);


    if (packs.length === 0) return <></>;

    const getSamplesByPack = (pack) => {

        let stor = locations?.locations?.find(item => item.id === storage.id);

        if (storage.id === undefined)
            return storageStore.samples;

        if (stor) {
            let p = stor.packs;
            let sm = p.find(i => i?.structura?.id === pack.id);
            return sm?.samples ?? [];
        }
        return [];
    }


    return (
           <div className={classes.table}>
              <div className={classes.header_text} dangerouslySetInnerHTML={{__html: storage.path}}></div>
              {packs.map((pack, index)=>
                <>
                    {
                      storageStore.packClickId === pack.id && <WorkRightStorageTablePack
                        key={index}
                        mode={mode}
                        pack={pack}
                        storage={storage}
                        setViewMode={setViewMode}
                        path={storage.path}
                        samples={getSamplesByPack(pack)}
                      />
                    }
                    {
                      storageStore.packClickId === 0 && <WorkRightStorageTablePack
                        key={index}
                        mode={mode}
                        pack={pack}
                        storage={storage}
                        setViewMode={setViewMode}
                        path={storage.path}
                        samples={getSamplesByPack(pack)}
                      />
                     }

                </>
              )}
          </div>
    );
});

export default WorkRightStorageTable;
