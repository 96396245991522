import React, {useEffect} from 'react';
import WorkLeftReportsItem from './WorkLeftReportsItem'
import classes2 from "./workLeftReports.module.css"

const rowsButton = {
    print: false,
    copy: false,
    edit: true,
    delete: true
}

const WorkLeftReports = ({setWidget, reports, mode}) => {


    return (
        <div className={classes2.WorkLeftTableBlock}>
            <table className={classes2.WorkLeftTable}>
                <tbody>
                {reports.map((rowsItem) => <WorkLeftReportsItem
                    item={rowsItem}
                    key={rowsItem.id}
                    rowsButton={rowsButton}
                    setWidget={setWidget}
                    mode={mode}
                />)}
                </tbody>
            </table>
        </div>
    )
};

export default WorkLeftReports;
