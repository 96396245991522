import React from "react";
import classes from './usersSettings.module.css';
import DropdownComponent from "../../Widgets/Dropdown/DropdownComponent";
import {COLORS} from "../../../config";

const languageSource = [{id: 1, name: 'Русский'}, {id: 2, name: 'English'}];
const countInPageSource = [{id: 1, name: '10'}, {id: 2, name: '20'}];

const UsersSettings = ({mode, setWidget}) => {
  const [language, setLanguage] = React.useState(languageSource[0].id);
  const [countInPage, setCountInPage] = React.useState(countInPageSource[1].id);


  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <p className={classes.title}>Общие настройки</p>

          <div className={classes.control_body}>
            <p>Язык интерфейса</p>
            <div>
              <DropdownComponent key={'errors_1'}
                                 keyItem={'id'}
                                 valueItem={'name'}
                                 arrowColor={COLORS.color_hover}
                                 items={languageSource}
                                 selectedKey={language}
                                 onChange={(val) => {setLanguage(val)} }/>
            </div>
          </div>

        </div>

      <div className={classes.body}>
        <p className={classes.title}>Отображение</p>

        <div className={classes.control_body}>
          <p>Показывать записей на странице</p>
          <div>
            <DropdownComponent key={'errors_1'}
                               keyItem={'id'}
                               valueItem={'name'}
                               arrowColor={COLORS.color_hover}
                               items={countInPageSource}
                               selectedKey={countInPage}
                               onChange={(val) => {setCountInPage(val)} }/>
          </div>
        </div>

      </div>

      <div className={classes.footer}>
        <button className={`button_default save_style`} onClick={() => { }}>Отправить</button>
        <button className={`button_default cancel_style`} onClick={() => {}}>Отмена</button>
      </div>

    </div>
  );
}

export default UsersSettings;
