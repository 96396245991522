import {address_server, address_server_short} from "../../config";
import {error} from "../api";
import {transliterate} from "../../helpers/utils";

export async function getReportsGroups(token){
    const res = await (await fetch(address_server+'/report/groups?token='+token)).json()
    if(res.success){
        return res.result;
    }
    error('getReportsGroups',res);
    return []
}

export async function newGroupReports(token, group, newName){
    let res;
    if(group !== 0)
        res = await (await fetch(address_server+'/report/group',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:token,id:group,name:newName})})).json()
    else
        res = await (await fetch(address_server+'/report/group',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token:token,name:newName})})).json()
    return res
}

export async function deleteReportGroup(token, group){
    let res = await (await fetch(address_server+'/report/group/delete',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token:token,id:group})})).json()

    // if(res.success){
    return res
    // }
    // error('deleteReportGroup',res);
    // return []
}

export async function getReports(token, group, page, limit, search, sorting){
    let res;
    let url = address_server+'/report/reports?token='+token+'&mode=table';
    if (group)
        url += '&group='+group;
    if(limit)
        url += '&offset='+(page-1)*limit;
    if (limit)
        url += '&limit='+limit;
    if(search)
        url += '&search='+search
    if(sorting.field)
        url += '&sorting='+JSON.stringify([{'field':sorting.field,'type':sorting.type}])
    if(group)
        res = await (await fetch(url)).json()
    else
        res = await (await fetch(address_server+'/report/reports?token='+token+'&mode=table')).json()
    if(res.success){
        return res.result
    }
    error('getReports',res);
    return []
}

export async function getReport(token, id){
    let res = await (await fetch(address_server+'/report?token='+token+'&id='+id)).json()
    if(res.success){
        return res.result;
    }
    error('getReport',res);
    return []
}

export async function editReport(token, id, params){
    let formData = new FormData();
    formData.append('token', token);
    formData.append('name', params.name);
    formData.append('group', params.group);
    formData.append('status', params.status);
    formData.append('comments', params.comments?params.comments:'');
    formData.append('sections', JSON.stringify(params.sections));
    formData.append('file', params.file);

    let res;
    if(id!==0){
        formData.append('id', id);
        res = await (await fetch(address_server + '/report', {
            method: 'POST',
            body: formData
        })).json()
    }
    else{
        res = await (await fetch(address_server+'/report',{
            method:'POST',
            body: formData
        })).json()
    }
    return res
}

export async function deleteReport(token, id){
    let res = await (await fetch(address_server+'/report/delete',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token:token,id:id})})).json()

    if(res.success){
        return res
    }
    error('deleteReport',res);
    return []
}


export async function parseFile(token, params, signal){

    let formData = new FormData();
    formData.append('token', token);
    //formData.append('name', params.name);

    if (params.files.length > 0) {
        params.files?.map((file, index) => {
            formData.append('files', file, transliterate(file.name));
        });
    }

    let res = await (await fetch(address_server + '/import/load', {
        signal: signal,
        method: 'POST',
        body: formData
    })).json();

    if (res.success)
        return res;

    error('loadFile',res);
    return []
}

export async function saveDataAfterCasted(token, data){

    let formData = new FormData();
    formData.append('token', token);
    formData.append('data', JSON.stringify(data));

    let res = await (await fetch(address_server + '/import/save', {
        method: 'POST',
        body: formData
    })).json();

    return res;
}

export async function getReportConstructor() {
    let res = await (await fetch(address_server_short + 'sampledata/design', {
        method: 'GET',
        headers: {'Accept': 'text/html'}
    })).text();

    return res;
}
